import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { roles, security } from "./UsersClassificationTable"
import { Clearance } from "../../components/tools/Clearance"
import Select from "react-select"
//custom hooks
import { useCollection } from "../../hooks/useCollection"
import { useFirestore } from "../../hooks/useFirestore"
//custom components
import SuccessModal from "../../components/SuccessModal"
import { colorStyles } from "../../components/selectStyles"
//MUI
import { Box, Button, Grid, Avatar } from "@mui/material"
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined"
import Error404 from "../error404/Error404"
import EditUser from "./EditUser"

const UsersDashboard = ({ isMobile, userData }) => {
  const { deleteDocument } = useFirestore("users")
  const { error, documents } = useCollection("users")
  const { error: companyError, documents: companyDocs } =
    useCollection("empresas")
  const { updateDocument, response } = useFirestore("users")
  const [searchTerm, setSearchTerm] = useState("")

  //modal
  const [showModal, setShowModal] = useState(false)
  //user to Edit
  const [userEdit, setUserEdit] = useState(null)

  const [users, setUsers] = useState(null)
  const [companies, setCompanies] = useState(null)
  const [deleteUser, setDeleteUser] = useState(null)

  useEffect(() => {
    if (documents) {
      const u = documents.map((user) => ({
        uid: user.id,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        role: user.role,
        security: user.security,
        companiesList: user.companiesList,
      }))
      if (!error) {
        setUsers(u)
      }
    }
  }, [documents, error])

  const filteredUsers = users
    ? users.filter((user) => {
        const userDisplayName = user.displayName.toLowerCase() // Convert user's displayName to lowercase for case-insensitive search
        const userEmail = user.email.toLowerCase() // Convert user's email to lowercase for case-insensitive search
        const searchTermLower = searchTerm.toLowerCase() // Convert searchTerm to lowercase for case-insensitive search
        return (
          userDisplayName.includes(searchTermLower) ||
          userEmail.includes(searchTermLower)
        )
      })
    : []

  useEffect(() => {
    if (companyDocs) {
      console.log("Companies docs: ", companyDocs)
      const c = companyDocs.map((c) => ({
        id: c.id,
        label: c.label,
        value: c.value,
        key: c.key,
        modules: c.modules,
      }))
      if (!companyError) {
        setCompanies(c)
      }
      console.log("Set companies at users dashboard: ", companies)
    }
  }, [companyDocs, companyError])

  const handleSearch = (e) => {
    setSearchTerm(e.target.value) // Update the search term state
  }

  const handleRoleChange = async (selectedRole, user) => {
    //user DB
    await updateDocument(user.uid, { role: selectedRole })
    if (!response.error) {
      console.log("role changed")
    }
  }

  const handleCompaniesChange = async (selectedCompanies, user) => {
    //userDB
    await updateDocument(user.uid, { companiesList: selectedCompanies })
    if (!response.error) {
      console.log("companies changed")
    }
  }
  const handleSecurityChange = async (selectedSecurity, user) => {
    await updateDocument(user.uid, { security: selectedSecurity })
    if (!response.error) {
      console.log("security changed")
    }
  }

  const handleDelete = async (user) => {
    setDeleteUser(user)
    setShowModal(true)
  }
  const handleUserEdit = (user) => {
    setUserEdit(user)
  }
  const handleUserUpdate = (updatedUser) => {
    // Find the index of the updated user in the users array
    const userIndex = users.findIndex((user) => user.uid === updatedUser.uid)

    if (userIndex !== -1) {
      // Create a new array with the updated user
      const updatedUsers = [...users]
      updatedUsers[userIndex] = updatedUser

      // Update the users state with the updated array
      setUsers(updatedUsers)
    }
  }
  const updateUserEdit = (updatedUser) => {
    setUserEdit(updatedUser)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleOk = () => {
    deleteDocument(deleteUser.uid)
    setShowModal(false)
  }

  return (
    <>
      {!Clearance(1, 1) && <Error404 />}
      {Clearance(1, 1) && (
        <>
          {!userEdit && (
            <Box sx={{ marginTop: !isMobile ? 15 : 5 }}>
              <Button
                component={Link}
                to="/"
                variant="outlined"
                startIcon={<ArrowBackIosNewOutlinedIcon />}
              >
                Regresar
              </Button>
            </Box>
          )}
          {!userEdit && (
            <Box>
              <label>
                Búsqueda
                <input
                  type="text"
                  onChange={handleSearch}
                  value={searchTerm}
                ></input>
              </label>
            </Box>
          )}
          {!userEdit && (
            <Grid
              container
              spacing={5}
              sx={{
                marginTop: !isMobile ? 5 : 0,
                marginBottom: 10,
                padding: 2,
              }}
            >
              <Grid item xs={12} md={12}>
                {filteredUsers.length > 0 ? (
                  filteredUsers.map((user) => (
                    <Grid
                      key={user.uid}
                      container
                      spacing={2}
                      sx={{ marginBottom: 2, border: "1px solid #dddddd" }}
                    >
                      <Grid item xs={4} md={1}>
                        <Avatar src={user.photoURL} />
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <h2>{user.displayName}</h2>
                        <p>{user.email}</p>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Button
                          onClick={() => handleUserEdit(user)}
                          variant="contained"
                        >
                          Edit User
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        {user.role && user.role.value !== "master" && (
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleDelete(user)}
                          >
                            Eliminar
                          </Button>
                        )}
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <label>
                          <span>Role:</span>
                          <Select
                            options={roles}
                            styles={colorStyles}
                            value={user.role}
                            onChange={(selectedRole) =>
                              handleRoleChange(selectedRole, user)
                            }
                          />
                        </label>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <label>
                          <span>Security:</span>
                          <Select
                            options={security}
                            styles={colorStyles}
                            value={user.security}
                            onChange={(selectedSecurity) =>
                              handleSecurityChange(selectedSecurity, user)
                            }
                          />
                        </label>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <label>
                          <span>Companies:</span>
                          <Select
                            isMulti
                            options={companies}
                            value={user.companiesList}
                            onChange={(selectedCompanies) =>
                              handleCompaniesChange(selectedCompanies, user)
                            }
                          />
                        </label>
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <p>No users match the search criteria.</p>
                )}
              </Grid>
            </Grid>
          )}
          {showModal && (
            <SuccessModal
              button
              title={`Eliminar Usuario: ${deleteUser.displayName}`}
              message="¿Estás seguro de querer borrar al usuario?"
              onClose={handleCloseModal}
              onOK={handleOk}
              open={showModal}
            />
          )}
          {userEdit && (
            <EditUser
              isMobile={isMobile}
              user={userEdit}
              companies={companies}
              currentCompanies={userEdit.companiesList}
              onUpdateUser={updateUserEdit} // Pass the callback function
            />
          )}
        </>
      )}
    </>
  )
}

export default UsersDashboard
