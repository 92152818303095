import Avatar from "../../components/Avatar"
import { useFirestore } from "../../hooks/useFirestore"
import { useHistory } from "react-router-dom"
import { useUserRoleAndSecurity } from "../../hooks/useUserRoleAndSecurity"
import Select from "react-select"
import { useState, useEffect } from "react"
//styles
import { colorStyles } from "../../components/selectStyles"
import { stagesData, priorityData } from "../dashboard/StagesData"

export default function ProjectSummary({ project, userData }) {
  //custom hooks
  const { deleteDocument } = useFirestore("projects")
  const { currentRole } = useUserRoleAndSecurity()

  const history = useHistory()
  const { updateDocument, response } = useFirestore("projects")
  const [stage, setStage] = useState(project.stage)
  const [priority, setPriority] = useState(project.priority)

  const stages = stagesData
  const priorities = priorityData

  const clearance = currentRole && currentRole.value === "master"

  const handleStageChange = (option) => {
    setStage(option)
    if (option.value === "backlog") {
      setPriority(priorities[0])
    }
  }
  const handlePriorityChange = (option) => {
    setPriority(option)
  }
  useEffect(() => {
    handleUpdate(stage, priority)
  }, [stage, priority])

  const handleUpdate = async (stage) => {
    await updateDocument(project.id, {
      stage: stage,
      priority: priority,
    })
    if (!response.error) {
    }
  }

  const handleClick = () => {
    console.log(deleteDocument(project.id))
    history.push("/")
  }

  return (
    <div>
      <div className="project-summary">
        <h2 className="page-title">{project.name}</h2>
        <p className="due-date">
          Fecha límite: {project.dueDate.toDate().toLocaleDateString("es-mx")}
        </p>
        <label>
          <span>Etapa:</span>
          <div style={{ width: "200px" }}>
            <Select
              onChange={(option) => handleStageChange(option)}
              options={stages}
              value={stage}
              styles={colorStyles}
            />
          </div>
        </label>
        <label>
          <span>Prioridad:</span>
          <div style={{ width: "200px" }}>
            <Select
              onChange={(option) => handlePriorityChange(option)}
              options={priorities}
              value={priority}
              styles={colorStyles}
            />
          </div>
        </label>
        <p className="details">{project.details}</p>
        <h4>Proyecto asignado a:</h4>
        <div className="assigned-users">
          {project.assignedUsersList.map((user) => (
            <div key={user.id}>
              <Avatar src={user.photoURL} />
            </div>
          ))}
        </div>
      </div>
      {(userData?.id === project.createdBy.id || clearance) && (
        <button className="btn" onClick={handleClick}>
          Borrar Proyecto
        </button>
      )}
    </div>
  )
}
