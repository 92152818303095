import React, { useRef } from "react"
import { useParams } from "react-router-dom"
import { useDocument } from "../../hooks/useDocument"
import { Clearance } from "../../components/tools/Clearance"
import { useFirestore } from "../../hooks/useFirestore"
import { useHistory } from "react-router-dom"

//printing
import { useReactToPrint } from "react-to-print"

// styles
import { Box, Grid, Button } from "@mui/material"
import { Link } from "react-router-dom"
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined"
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined"
import PaidIcon from "@mui/icons-material/Paid"

import PrintableQuote from "./PrintableQuote"

const Quote = ({ isMobile }) => {
  const { id } = useParams()
  const history = useHistory()
  const { document, error } = useDocument("cotizaciones", id)
  const masterClearance = Clearance(1, 1)
  const { deleteDocument } = useFirestore("cotizaciones")
  const handleDelete = () => {
    deleteDocument(document.id)
    history.push("/cotizaciones-dashboard")
  }
  const componentRef = useRef()
  const handlePrintTest = useReactToPrint({
    content: () => componentRef.current,
  })

  const handlePrints = () => {
    handlePrintTest()
  }

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading...</div>
  }

  return (
    <>
      <Box sx={{ marginTop: !isMobile ? 15 : 5 }} className="no-print">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button
              className="no-print"
              component={Link}
              exact
              to="/cotizaciones-dashboard"
              variant="outlined"
              startIcon={<ArrowBackIosNewOutlinedIcon />}
            >
              Regresar
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              display={"flex"}
              justifyContent={!isMobile ? "flex-end" : "flex-start"}
            >
              <Button
                className="no-print"
                variant="outlined"
                startIcon={<PrintOutlinedIcon />}
                onClick={handlePrints}
              >
                Imprimir
              </Button>
              <Button
                color="success"
                variant="contained"
                sx={{ color: "white", marginLeft: 5 }}
                endIcon={<PaidIcon />}
              >
                Vendida
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <PrintableQuote
              cotizacion={document}
              isMobile={isMobile}
              ref={componentRef}
            />
          </Grid>
        </Grid>
        {masterClearance && (
          <Button
            className="btn no-print"
            onClick={handleDelete}
            variant="outlined"
            color="error"
            sx={{ margin: !isMobile ? 5 : 2 }}
          >
            Borrar Documento
          </Button>
        )}
      </Box>
    </>
  )
}

export default Quote
