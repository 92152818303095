import { React, forwardRef, useState } from "react"
import { NavLink as NavLinkBase } from "react-router-dom"
import { Clearance } from "../tools/Clearance"
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"

//MUI ICONS
import { ExpandLess } from "@material-ui/icons"
import { ExpandMore } from "@material-ui/icons"
import AddIcon from "@mui/icons-material/Add"
import SearchIcon from "@mui/icons-material/Search"
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined"

const PDProjectsModule = ({ handleDrawerClose }) => {
  const NavLink = forwardRef((props, ref) => (
    <NavLinkBase ref={ref} {...props} className={props.activeClassName} />
  ))

  const [open, setOpen] = useState(false)
  const clearance = Clearance(5, 4)

  const handleOpen = () => {
    setOpen(!open)
  }
  return (
    <ListItem
      key={"proyectos"}
      disablePadding
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <ListItemButton onClick={handleOpen} sx={{ width: "100%" }}>
        <ListItemIcon>
          <SpaceDashboardOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Proyectos" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">
          <ListItem
            key={"ver-proyectos"}
            component={NavLink}
            exact
            to="/"
            onClick={() => {
              handleDrawerClose()
              handleOpen()
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText secondary="Ver Proyectos" />
            </ListItemButton>
          </ListItem>
          {clearance && (
            <ListItem
              key={"crear-proyectos"}
              component={NavLink}
              to="/create"
              onClick={() => {
                handleDrawerClose()
                handleOpen()
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText secondary="Crear Proyecto" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Collapse>
    </ListItem>
  )
}

export default PDProjectsModule
