import { Box, Chip } from "@mui/material"
import { Link } from "react-router-dom"
import { Clearance } from "../tools/Clearance"
import React from "react"
import Avatar from "../Avatar"

const PDProfile = ({ user, handleDrawerClose }) => {

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ flexDirection: "column", padding: "10px" }}
    >
      <Avatar src={user?.photoURL} />
      <p>Hey {user?.displayName}</p>
      {Clearance(5, 4) && (
        <Chip
          label="Ver Perfil"
          clickable
          component={Link}
          to="/profile"
          onClick={handleDrawerClose}
        />
      )}
    </Box>
  )
}

export default PDProfile
