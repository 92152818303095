import { useEffect, React } from "react"
import { Link } from "react-router-dom"

//components
import ProductColumnSelection from "../pages/product-dashboard/ProductColumnSelection"
//styles
import "./ProductDynamicTable.css"
import { Box } from "@mui/material"

export default function ProductTable({ products, filters, newColumns, isMobile }) {
  //sort data from collection
  const sorting = [...products].sort((a, b) =>
    a.productCode.toLowerCase() < b.productCode.toLowerCase() ? -1 : 1
  )

  //array to hold the filter's value only
  const newArray = []
  filters.forEach((f) => {
    newArray.push(f.value)
  })

  //cleanup function when component unmounts
  useEffect(() => {
    // Cleanup function to clear newArray state
    const unsub = (newArray) => {
      newArray = []
    }
    // unsubscribe on unmount
    return () => unsub()
  }, [])

  //header and data definition for CSV button
  const tableHeader = [
    { label: "Código", key: "productCode", width:'150px' },
    { label: "Nombre", key: "name", width:'300px' },
    ...filters.map((filter) => ({ label: filter.label, key: filter.value ,  width:'150px'})),
  ]

  const tableData = sorting.map((product) => ({
    id: product.id,
    productCode: product.productCode,
    name: product.name,
    ...newArray.reduce((acc, filter) => {
      acc[filter] = product[filter]
      return acc
    }, {}),
  }))

  return (
    <>
      <ProductColumnSelection
        newColumns={newColumns}
        tableData={tableData}
        tableHeader={tableHeader}
      />
      <Box sx={{ maxWidth: "100%", overflowX: "scroll" }}>
      <table className="styled-table" style={{ width: "100%" }}>
          <thead>
          <tr style={{ padding: "20px" }}>
              {tableHeader.map((column) => (
                <th key={column.label} style={{width: column.width, padding:'5px'}}>{column.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row) => (
              <tr className="table-row" key={row.id} >
                <Link to={`/products/${row.id}`} key={row.id}>
                  <td style={{ fontWeight: "500", padding:'5px' }}>{row.productCode}</td>
                </Link>
                <td style={{padding: '5px'}}>{row.name}</td>
                {newArray.map((filter) => (
                  <td key={row.id + filter} style={{padding: '5px'}}>
                    {filter.includes("precio") ? "$" : ""}
                    {row[filter]}
                    {filter.includes("espesorMM") ? " mm" : ""}
                    {filter.includes("espesorIn") ? " in" : ""}
                    {filter.includes("ancho") || filter.includes("largo")
                      ? "  m"
                      : ""}
                    {filter === "peso" ? "  kg" : ""}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </>
  )
}
