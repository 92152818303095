import { useUserRoleAndSecurity } from "../../hooks/useUserRoleAndSecurity"

export function Clearance(requiredRole, requiredSecurity) {
  const { currentRole, currentSecurity } = useUserRoleAndSecurity()
  let role,
    security = false

  let clear = false

  if (currentRole && currentSecurity) {
    role = currentRole.number <= requiredRole ? true : false

    security = currentSecurity.number <= requiredSecurity ? true : false

    clear = role && security

    return clear
  } else {
    return false
  }
}
