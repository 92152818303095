import { React, useState, useEffect } from "react"
import { stagesData } from "../shipments-dashboard/StagesData"
import Select from "react-select"

import { useCollection } from "../../hooks/useCollection"
import { useFirestore } from "../../hooks/useFirestore"
import { timestamp } from "../../firebase/config"

import { colorStyles } from "../../components/selectStyles"
import SuccessModal from "../../components/SuccessModal"

import { Box, Button, Grid } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined"

const categories = stagesData

const contenedor = [
  { value: "LCL", label: "LCL" },
  { value: "FCL-20", label: "FCL 20" },
  { value: "FCL-40", label: "FCL 40" },
]
const modality = [
  { value: "ferro-camion", label: "Ferro Camión" },
  { value: "barco-camion", label: "Barco Camión" },
]

function formatDate(date) {
  let d = date.toDate()
  var year = d.toLocaleString("default", { year: "numeric" })
  var month = d.toLocaleString("default", { month: "2-digit" })
  var day = d.toLocaleString("default", { day: "2-digit" })
  var fecha = ""

  fecha = year + "-" + month + "-" + day

  return fecha.toString()
}

const EditShipments = ({ shipment, onEdit }) => {
  const { updateDocument, response } = useFirestore("shipments")
  const { documents: productsFromDB } = useCollection("products")
  const { documents: portsFromDB } = useCollection("ports")
  const { documents: suppliersFromDB } = useCollection("suppliers")

  const [name, setName] = useState(shipment.name)
  const [details, setDetails] = useState(shipment.details)
  const [eta, setEta] = useState(formatDate(shipment.dueDate))
  const [etd, setEtd] = useState(formatDate(shipment.etd))

  const [category, setCategory] = useState(shipment.category)
  const [supplier, setSupplier] = useState(shipment.supplier)
  const [outboundPort, setOutboundPort] = useState(shipment.outboundPort)
  const [inboundPort, setInboundPort] = useState(shipment.inboundPort)
  const [mode, setMode] = useState(shipment.modality)
  const [container, setContainer] = useState(shipment.container)
  const [productList, setProductList] = useState(
    shipment.productList ? shipment.productList : [{ quantity: 0, id: "" }]
  )
  //store from database
  const [productDB, setProductDB] = useState("")
  const [portDB, setPorttDB] = useState("")
  const [supplierDB, setSupplierDB] = useState("")
  //modal
  const [showModal, setShowModal] = useState(false)

  console.log("eta: ", eta, "etd: ", etd)
  //connecting to DB
  useEffect(() => {
    if (productsFromDB) {
      const productDB = productsFromDB.map((product) => {
        return {
          id: product.id,
          productCode: product.productCode,
          value: product.id,
          name: product.name,
          label: product.name,
        }
      })
      setProductDB(productDB)
    }
  }, [productsFromDB])

  useEffect(() => {
    if (portsFromDB) {
      const data = portsFromDB.map((port) => {
        return {
          id: port.id,
          value: port.value,
          label: port.label,
        }
      })
      setPorttDB(data)
    }
  }, [portsFromDB])

  useEffect(() => {
    if (suppliersFromDB) {
      const data = suppliersFromDB.map((supplier) => {
        return {
          id: supplier.id,
          value: supplier.value,
          label: supplier.label,
        }
      })
      setSupplierDB(data)
    }
  }, [suppliersFromDB])

  const handleNameChange = (e) => {
    setName(e)
  }
  const handleDetailsChange = (e) => {
    setDetails(e)
  }
  const handleEtaChange = (e) => {
    setEta(e)
  }
  const handleEtdChange = (e) => {
    setEtd(e)
  }
  const handleModalityChange = (e) => {
    setMode(e)
  }
  const handleContainerChange = (e) => {
    setContainer(e)
  }
  const handleCategoryChange = (e) => {
    setCategory(e)
  }
  const handleSupplierChange = (e) => {
    setSupplier(e)
  }
  const handleOutboundPortChange = (e) => {
    setOutboundPort(e)
  }
  const handleInboundPortChange = (e) => {
    setInboundPort(e)
  }
  const handleClose = () => {
    onEdit(false)
    setShowModal(false)
  }

  const handleProductChange = (option, index) => {
    const updatedList = [...productList]
    updatedList[index] = {
      ...option,
      quantity: updatedList[index] ? updatedList[index].quantity : 0,
    }
    setProductList(updatedList)
  }

  const handleQuantityChange = (e, index) => {
    const updatedList = [...productList]
    updatedList[index].quantity = parseInt(e.target.value)
    setProductList(updatedList)
  }

  const addProductField = () => {
    const updatedList = [...productList, { index: null, quantity: 0 }]
    setProductList(updatedList)
  }

  const removeProductField = (index) => {
    const updatedList = [...productList]
    updatedList.splice(index, 1)
    setProductList(updatedList)
  }

  const handleSubmit = async (shipment) => {
    await updateDocument(shipment.id, {
      name: name,
      category: category,
      details: details,
      inboundPort: inboundPort,
      outboundPort: outboundPort,
      modality: mode,
      productList,
      lastMod: timestamp.fromDate(new Date()),
      dueDate: timestamp.fromDate(new Date(eta)),
      etd: timestamp.fromDate(new Date(etd)),
      supplier: supplier,
      container: container,
    })
    if (!response.error) {
      console.log("Importacion Actualizada")
      setShowModal(true)
    }
  }

  return (
    <Box sx={{ marginTop: 15 }}>
      <Box sx={{ marginBottom: 2 }}>
        <Button
          variant="outlined"
          color="error"
          onClick={handleClose}
          startIcon={<ArrowBackIosNewOutlinedIcon />}
        >
          Regresar
        </Button>
      </Box>
      <h2 className="page-title">Editar Importación</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <label>
            Nombre de la Importación
            <input
              type="Text"
              placeholder="Nombre de la importación"
              value={name}
              onChange={(e) => handleNameChange(e.target.value)}
            />
          </label>
        </Grid>
        <Grid item xs={12} md={4}>
          <label>
            Estado del contenedor:
            <Select
              onChange={(option) => handleCategoryChange(option)}
              options={categories}
              styles={colorStyles}
              value={category}
            />
          </label>
        </Grid>
        <Grid item xs={12} md={4}>
          <label>
            ETA:
            <input
              type="date"
              value={eta}
              onChange={(e) => handleEtaChange(e.target.value)}
            />
          </label>
        </Grid>
        <Grid item xs={12} md={4}>
          <label>
            ETD:
            <input
              type="date"
              value={etd}
              onChange={(e) => handleEtdChange(e.target.value)}
            />
          </label>
        </Grid>
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={4}>
          <label>
            Proveedor:
            <Select
              onChange={(e) => handleSupplierChange(e)}
              options={supplierDB}
              placeholder="Seleccionar Proveedor"
              value={supplier}
            />
          </label>
        </Grid>

        <Grid item xs={12} md={4}>
          <label>
            Puerto de Salida:
            <Select
              onChange={(e) => handleOutboundPortChange(e)}
              options={portDB}
              placeholder="Seleccionar Puerto de Salida"
              value={outboundPort}
            />
          </label>
        </Grid>
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={4}>
          <label>
            Tipo de Contenedor:
            <Select
              onChange={(e) => handleContainerChange(e)}
              options={contenedor}
              placeholder="Seleccionar Contenedor"
              value={container}
            />
          </label>
        </Grid>
        {/*Packing List*/}
        <Grid item xs={12} md={12}>
          <Box sx={{ marginTop: 5, marginBottom: 5 }}>
            <h3 className="page-title">Packing List</h3>
          </Box>

          <Grid container spacing={2}>
            {productList.map((row, index) => (
              <>
                <Grid md={5} sm={12} sx={{ padding: "10px" }}>
                  <Select
                    onChange={(option) => handleProductChange(option, index)}
                    options={productDB}
                    placeholder="Seleccionar producto"
                    value={row}
                  />
                </Grid>
                <Grid md={5} sm={12} sx={{ padding: "10px" }}>
                  <input
                    required
                    type="number"
                    min="1"
                    onChange={(e) => handleQuantityChange(e, index)}
                    value={row.quantity}
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                </Grid>
                {index > 0 && (
                  <Grid md={2} sm={1} sx={{ padding: "10px" }}>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => removeProductField(index)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                )}
              </>
            ))}
          </Grid>
        </Grid>
        <Box sx={{ padding: 2 }}>
          <button className="btn" onClick={addProductField}>
            Agregar otro producto
          </button>
        </Box>

        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={4} />
        <Grid item xs={12} md={4}>
          <label>
            Puerto de Llegada:
            <Select
              onChange={(e) => handleInboundPortChange(e)}
              options={portDB}
              placeholder="Seleccionar Puerto de Llegada"
              value={inboundPort}
            />
          </label>
        </Grid>

        <Grid item xs={12} md={4}>
          <label>
            Modalidad de transporte:
            <Select
              onChange={(e) => handleModalityChange(e)}
              options={modality}
              placeholder="Seleccionar Modalidad"
              value={mode}
            />
          </label>
        </Grid>
        <Grid item xs={12} md={10}>
          <label>
            Detalles del contenedor (opcional):
            <textarea
              type="text"
              onChange={(e) => handleDetailsChange(e.target.value)}
              value={details}
            />
          </label>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{ padding: 2 }}>
            <Button variant="outlined" onClick={() => handleSubmit(shipment)}>
              Modificar Importación
            </Button>
          </Box>
        </Grid>
      </Grid>
      {showModal && (
        <SuccessModal
          title="Importación Modificada con Éxito"
          message="Revisa que los datos sean correctos a continuación"
          onClose={handleClose}
          open={showModal}
        />
      )}
    </Box>
  )
}

export default EditShipments
