import React, { useState, useEffect } from "react"
import { Box, Chip } from "@mui/material"
import { Link } from "react-router-dom"
import CustomButton from "../../components/tables/CustomButton"

const ShipmentsList = ({ data, isMobile }) => {
  const [originalData, setOriginalData] = useState(data)
  const [shipmentData, setShipmentData] = useState(data)
  const [sortState, setSortState] = useState({
    column: null,
    direction: "asc",
  })

  useEffect(() => {
    setOriginalData(data)
    setShipmentData(data)
  }, [data])

  const handleSort = (property) => {
    const isSameColumn = sortState.column === property
    const direction = isSameColumn
      ? sortState.direction === "asc"
        ? "desc"
        : "asc"
      : "asc"

    const sortedList = [...shipmentData].sort((a, b) => {
      const valueA = getProperty(a, property)
      const valueB = getProperty(b, property)

      if (valueA < valueB) return direction === "asc" ? -1 : 1
      if (valueA > valueB) return direction === "asc" ? 1 : -1
      return 0
    })

    setShipmentData(sortedList)
    setSortState({ column: property, direction })
  }

  const getProperty = (object, propertyPath) => {
    const parts = propertyPath.split(".")
    let value = object
    for (const part of parts) {
      value = value ? value[part] : null
    }
    return value
  }

  return (
    <Box sx={{ maxWidth: "100%", overflowX: "scroll" }}>
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <td onClick={() => handleSort("name")} style={{ width: isMobile ? "200px" : "auto" }}>
              <CustomButton>Nombre</CustomButton>
            </td>
            <td onClick={() => handleSort("supplier.label")} style={{ width: "150px" }}>
              <CustomButton>Proveedor</CustomButton>
            </td>
            <td onClick={() => handleSort("category.label")} style={{ width: "150px" }}>
              <CustomButton>Etapa</CustomButton>
            </td>
            <td onClick={() => handleSort("dueDate")} style={{ width: "150px" }}>
              <CustomButton>ETA</CustomButton>
            </td>
            <td onClick={() => handleSort("lastMod")} style={{ width: "150px" }}>
              <CustomButton>Última Mod</CustomButton>
            </td>
          </tr>
        </thead>
        <tbody>
          {shipmentData.map((d) => (
            <tr key={d.id}>
              <td style={{ fontWeight: "500" }}>
                <Link to={`/shipments/${d.id}`}>{d.name}</Link>
              </td>
              <td>{d.supplier.label}</td>
              <td>
                <Chip
                  label={d.category ? d.category.label : "No definida"}
                  sx={{
                    background: d.category ? d.category.color : "#000",
                    color: "white",
                  }}
                />
              </td>
              <td>{d.dueDate.toDate().toLocaleDateString("es-mx")}</td>
              <td>{d.lastMod.toDate().toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  )
}

export default ShipmentsList
