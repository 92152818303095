import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useLogout } from "../hooks/useLogout"
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined"
import { NavLink } from "react-router-dom"
import { Box } from "@mui/material"
import MenuButton from "./MenuButton"

const useStyles = makeStyles({
  root: {
    bottom: 0,
    backgroundColor: "rgba(20,33,61,1)",
    color: "rgba(255,255,255,0.8)",
    padding: "5px",
  },
})

function BottomMenu({ userData, isMobile }) {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const [open, setOpen] = useState(false)
  const { logout, isPending } = useLogout()

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <div className="no-print">
      <Box
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, width: "100vw" }}
      >
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue)
          }}
          showLabels
          className={classes.root}
        >
          <BottomNavigationAction
            component={NavLink}
            to="/landing"
            label="Inicio"
            icon={<HomeOutlinedIcon />}
            sx={{ flex: 1 }}
          />
          <BottomNavigationAction
            component={NavLink}
            to="/mobile-dashboard"
            label="Ver Módulos"
            icon={<GridViewOutlinedIcon />}
            sx={{ flex: 1 }}
          />
          <Box sx={{ flex: 1 }}>
            <MenuButton
              userData={userData}
              logout={logout}
              isMobile={isMobile}
            />
          </Box>
        </BottomNavigation>
      </Box>
    </div>
  )
}

export default BottomMenu
