import React from "react"
import { Clearance } from "../../components/tools/Clearance"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts"
import Divider from "@mui/material/Divider"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
//components
import Select from "react-select"
//styles
import "./Charts.css"
import { Box, Grid, Slider } from "@mui/material"
import Error404 from "../error404/Error404"

const data = [
  {
    name: "Page A",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
]
const data_meta = [
  {
    name: "Enero",
    uv: 4000,
    pv: 2400,
    amt: 3500,
  },
  {
    name: "Febrero",
    uv: 3000,
    pv: 1398,
    amt: 3500,
  },
  {
    name: "Marzo",
    uv: 2000,
    pv: 9800,
    amt: 3500,
  },
  {
    name: "Abril",
    uv: 2780,
    pv: 3908,
    amt: 3500,
  },
  {
    name: "Mayo",
    uv: 1890,
    pv: 4800,
    amt: 3500,
  },
  {
    name: "Junio",
    uv: 2390,
    pv: 3800,
    amt: 3500,
  },
  {
    name: "Julio",
    uv: 3490,
    pv: 4300,
    amt: 3500,
  },
  {
    name: "Agosto",
    uv: 1890,
    pv: 4800,
    amt: 3500,
  },
  {
    name: "Septiembre",
    uv: 3490,
    pv: 4300,
    amt: 3500,
  },
  {
    name: "Octubre",
    uv: 1890,
    pv: 4800,
    amt: 3500,
  },
  {
    name: "Noviembre",
    uv: 3490,
    pv: 4300,
    amt: 3500,
  },
  {
    name: "Diciembre",
    uv: 1890,
    pv: 4800,
    amt: 3500,
  },
]
const year_marks = [
  {
    value: 2021,
    label: "2021",
  },
  {
    value: 2022,
    label: "2022",
  },
  {
    value: 2023,
    label: "2023",
  },
]

const month_marks = [
  {
    value: 1,
    label: "E",
  },
  {
    value: 2,
    label: "F",
  },
  {
    value: 3,
    label: "M",
  },
  {
    value: 4,
    label: "A",
  },
  {
    value: 5,
    label: "M",
  },
  {
    value: 6,
    label: "J",
  },
  {
    value: 7,
    label: "J",
  },
  {
    value: 8,
    label: "A",
  },
  {
    value: 9,
    label: "S",
  },
  {
    value: 10,
    label: "O",
  },
  {
    value: 11,
    label: "N",
  },
  {
    value: 12,
    label: "D",
  },
]

const empresas = [
  { value: "mm", label: "Mat Markt" },
  { value: "ch", label: "Cortina Hawaiana" },
  { value: "pv", label: "Provial" },
]

const productos = [
  { value: "prod1", label: "Producto 1" },
  { value: "prod2", label: "Producto 2" },
  { value: "prod3", label: "Producto 3" },
  { value: "prod4", label: "Producto 4" },
  { value: "prod5", label: "Producto 5" },
  { value: "prod6", label: "Producto 6" },
]

const vendedores = [
  { value: "zertuche", label: "Zertuche" },
  { value: "palillo", label: "Palillo" },
  { value: "mitzi", label: "Mitzi" },
  { value: "dafne", label: "Dafne" },
  { value: "otro", label: "Otro" },
]
function valuetext(value) {
  return `${value}`
}

export default function Charts({ isMobile }) {
  const clearance = Clearance(3, 3)
  const CustomizedLabel = (props) => {
    const { x, y, stroke, value } = props

    return (
      <text
        x={x}
        y={y}
        dy={-16}
        fill={stroke}
        fontSize={10}
        textAnchor="middle"
      >
        {value}
      </text>
    )
  }

  const CustomizedAxisTick = (props) => {
    const { x, y, stroke, payload } = props
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={20}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    )
  }

  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Box
          sx={{
            marginTop: 15,
            marginBottom: 20,
            maxWidth: "100%",
            padding: 2,
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} padding={10}>
              <h2>Periodo</h2>
              <Box>
                <Box
                  sx={{
                    marginBottom: "20px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p>Año</p>
                  <Slider
                    aria-label="Custom marks"
                    min={2021}
                    max={2023}
                    defaultValue={2023}
                    getAriaValueText={valuetext}
                    step={1}
                    track="inverted"
                    valueLabelDisplay="auto"
                    marks={year_marks}
                    sx={{ marginLeft: "20px" }}
                  />
                </Box>
                <Box
                  sx={{
                    marginBottom: "20px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <p>Mes</p>
                  <Slider
                    aria-label="Custom marks"
                    min={1}
                    max={12}
                    defaultValue={12}
                    getAriaValueText={valuetext}
                    step={1}
                    valueLabelDisplay="auto"
                    marks={month_marks}
                    sx={{ marginLeft: "20px" }}
                  />
                </Box>
              </Box>
              <Box>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="money"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      sx={{ margin: "0" }}
                      value="money"
                      control={<Radio />}
                      label="Dinero"
                    />
                    <FormControlLabel
                      sx={{ margin: "0" }}
                      value="ventas"
                      control={<Radio />}
                      label="Ventas"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box sx={{marginTop: 5}}>
                <h2>Métricas</h2>
                <label>
                  <span>Empresa:</span>
                  <Select options={empresas} isMulti />
                </label>
                <label>
                  <span>Vendedor:</span>
                  <Select options={vendedores} isMulti />
                </label>
                <label>
                  <span>Productos:</span>
                  <Select options={productos} isMulti />
                </label>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={8}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <LineChart
                  width={!isMobile ? 600 : 450}
                  height={400}
                  data={data_meta}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 10,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    height={60}
                    tick={<CustomizedAxisTick />}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend wrapperStyle={{ top: "100%", left: 25 }} />
                  <Line
                    type="monotone"
                    dataKey="pv"
                    stroke="#8884d8"
                    label={<CustomizedLabel />}
                  />
                  <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                  <Line
                    type="monotone"
                    dataKey="amt"
                    stroke="#ff006e"
                    strokeDasharray="5 5"
                  />
                </LineChart>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}
