import React from "react"

//MUI
import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import ProjectsByUser from "./ProjectsByUser"
import ShipmentsByUser from "./ShipmentsByUser"
import QuotesByUser from "./QuotesByUser"

const UserSummary = ({ onEdit, user, isMobile, userData }) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            direction="column"
            justifyContent="center"
          >
            <Grid item xs={12} md={12}>
              <Avatar
                alt="Remy Sharp"
                src={user.photoURL}
                sx={{ width: 150, height: 150 }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <h2 style={{ textAlign: "center" }}>{userData.displayName}</h2>
              <p>{userData.email}</p>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                endIcon={<EditIcon />}
                onClick={() => onEdit(true)}
              >
                Editar Perfil
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ padding: 5 }}>
            <h2 className="page-title">Compañias asignadas</h2>
            <List dense="dense">
              {userData.companiesList?.map((ud) => (
                <ListItem>
                  <ListItemText>{ud.label}</ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          {/* Asignaciones a usuarios */}
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid container>
                <h3>Proyectos activos asignados:</h3>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ border: "1px solid #dddddd", padding: "10px" }}
                >
                  <Box sx={{ maxWidth: "100%", overflowX: "scroll" }}>
                    <ProjectsByUser user={user} isMobile={isMobile} />
                  </Box>
                </Grid>
                <h3 style={{ marginTop: "60px" }}>Importaciones asignadas:</h3>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ border: "1px solid #dddddd", padding: "10px" }}
                >
                  <Box sx={{ maxWidth: "100%", overflowX: "scroll" }}>
                    <ShipmentsByUser user={user} isMobile={isMobile} />
                  </Box>
                </Grid>
                <h3 style={{ marginTop: "60px" }}>Cotizaciones creadas:</h3>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{ border: "1px solid #dddddd", padding: "10px" }}
                >
                  <Box sx={{ maxWidth: "100%", overflowX: "scroll" }}>
                    <QuotesByUser user={user} isMobile={isMobile} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UserSummary
