import { React, useState } from "react"
//custom hooks
import { useAuthContext } from "../../hooks/useAuthContext"
import { Clearance } from "../tools/Clearance"

import { styled } from "@mui/material/styles"

import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import CssBaseline from "@mui/material/CssBaseline"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import CustomAppBar from "../CustomAppBar"
import "../Sidebar.css"

//modules
import PDProfile from "./PDProfile"
import PDProjectsModule from "./PDProjectsModule"
import PDShipmentsModule from "./PDShipmentsModule"
import PDProductsModule from "./PDProductsModule"
import PDQuotesModule from "./PDQuotesModule"
import PDChartsModule from "./PDChartsModule"
import PDWarehouseModule from "./PDWarehouseModule"
import PDClientsModule from "./PDClientsModule"
import PDCalculatorsModule from "./PDCalculatorsModule"
import PDMaster from "./PDMaster"

const drawerWidth = 260

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create("margin", {}),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}))

export default function PersistentDrawerLeft({ userData }) {
  const { user } = useAuthContext()
  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }


  return (
    <Box sx={{ display: "flex" }} className="no-print">
      <CssBaseline />
      <CustomAppBar
        position="fixed"
        userData={userData}
        open={open}
        handleDrawerOpen={handleDrawerOpen}
      />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>

        <Divider />
        {/*Profile Section*/}
        <PDProfile handleDrawerClose={handleDrawerClose} user={user} />
        <Divider />
        {/*Modules Section*/}
        <List>
          {/*Master module */}
          {Clearance(1, 1) && (
            <PDMaster handleDrawerClose={handleDrawerClose} />
          )}
          {/*Projects module */}
          {Clearance(5, 4) && (
            <PDProjectsModule handleDrawerClose={handleDrawerClose} />
          )}
          {/*Shipments module */}
          {Clearance(5, 4) && (
            <PDShipmentsModule handleDrawerClose={handleDrawerClose} />
          )}
          {/*Products module */}
          {Clearance(4, 4) && (
            <PDProductsModule handleDrawerClose={handleDrawerClose} />
          )}
          {/*Warehouse module 
          {Clearance(4, 4) && (
            <PDWarehouseModule handleDrawerClose={handleDrawerClose} />
          )}
            */}
          {/*Quotes module */}
          {Clearance(3, 3) && (
            <PDQuotesModule handleDrawerClose={handleDrawerClose} />
          )}
          {/*Cotizadores module */}
          {Clearance(4, 4) && (
            <PDCalculatorsModule handleDrawerClose={handleDrawerClose} />
          )}
          {/*Charts module */}
          {Clearance(3, 3) && (
            <PDChartsModule handleDrawerClose={handleDrawerClose} />
          )}

          {/*Clients module */}
          {Clearance(4, 4) && (
            <PDClientsModule handleDrawerClose={handleDrawerClose} />
          )}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
      </Main>
    </Box>
  )
}
