import { useState } from "react"

import { useFirestore } from "../../hooks/useFirestore"
// styles
//import "./Create.css"
import { Box } from "@mui/material"
import SuccessModal from "../../components/SuccessModal"


const CreateModule = () => {
  //const history = useHistory()
  const { addDocument, response } = useFirestore("modules")

  // form field values
  const [label, setName] = useState("")
  const [value, setCode] = useState("")
  const [formError, setFormError] = useState(null)
  //modal
  const [showModal, setShowModal] = useState(false)

  const handleLabel = (e) => {
    setName(e)
  }
  const handleValue = (e) => {
    setCode(e)
  }
  const handleClose = () => {
    setShowModal(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setFormError(null)

    const port = {
      label,
      value,
    }

    await addDocument(port)
    if (!response.error) {
      setShowModal(true)
      //history.push("/")
    }
  }

  return (
    <Box sx={{ marginTop: 20 }}>
      <div className="create-form">
        <h2 className="page-title">Crear Nuevo Módulo</h2>
        <form onSubmit={handleSubmit}>
          <label>
            <span>Nombre del Módulo:</span>
            <input
              required
              type="text"
              onChange={(e) => handleLabel(e.target.value)}
              value={label}
            />
          </label>
          <label>
            <span>Código del Módulo(No usar espacios):</span>
            <input
              required
              type="text"
              onChange={(e) => handleValue(e.target.value)}
              value={value}
            />
          </label>
          <button className="btn">Crear Módulo</button>
          {formError && <p className="error">{formError}</p>}
        </form>
        {showModal && (
          <SuccessModal
            title={`Módulo ${label} Creado`}

            message=""
            onClose={handleClose}
            open={showModal}
          />
        )}
      </div>
    </Box>
  )
}

export default CreateModule