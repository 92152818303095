import { React, useState, useEffect } from "react"
import Select from "react-select"

import { useCollection } from "../../hooks/useCollection"
import { timestamp } from "../../firebase/config"
import { useFirestore } from "../../hooks/useFirestore"
import { useHistory } from "react-router-dom"
import { Clearance } from "../../components/tools/Clearance"
import { stagesData, priorityData } from "../dashboard/StagesData"
// styles
import "./Create.css"
import { colorStyles } from "../../components/selectStyles"

import { Box } from "@mui/material"
import Error404 from "../error404/Error404"

const categories = [
  { value: "development", label: "Development" },
  { value: "design", label: "Design" },
  { value: "sales", label: "Sales" },
  { value: "marketing", label: "Marketing" },
]
const stages = stagesData
const priorities = priorityData

export default function Create({userData}) {
  const history = useHistory()
  const { addDocument, response } = useFirestore("projects")
  const { documents } = useCollection("users")
  const [users, setUsers] = useState([])

  // form field values
  const [name, setName] = useState("")
  const [details, setDetails] = useState("")
  const [dueDate, setDueDate] = useState("")
  const [category, setCategory] = useState("")
  const [assignedUsers, setAssignedUsers] = useState([])
  const [formError, setFormError] = useState(null)
  const [stage, setStage] = useState(null)
  const [priority, setPriority] = useState(null)

  const clearance = Clearance(4, 3)

  //leer los usuarios la primera vez que se ejecuta el componente o si cambia la lista
  useEffect(() => {
    if (documents) {
      const options = documents.map((user) => {
        return { value: user, label: user.displayName }
      })
      setUsers(options)
    }
  }, [documents])

  const handleStageChange = (option) => {
    setStage(option)
  }
  const handlePriorityChange = (option) => {
    setPriority(option)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    //avoid error for Select components
    setFormError(null)

    if (!category) {
      setFormError("Por favor elige una categoría para el proyecto")
      return
    }
    if (!priority) {
      setFormError("Por favor selecciona una prioridad para el proyecto")
      return
    }
    if (!stage) {
      setFormError("Por favor selecciona una etapa del proyecto")
      return
    }
    if (!dueDate.length > 0) {
      setFormError("Por favor selecciona la fecha de entrega para el proyecto")
      return
    }
    //empty array is a value
    if (assignedUsers.length < 1) {
      setFormError("Por favor asigna el proyecto al menos a una persona")
      return
    }
    //creator of the project
    const createdBy = {
      displayName: userData.displayName,
      photoURL: userData.photoURL,
      id: userData.id,
    }
    //Transforming user(s) assigned to the project to something less complex
    const assignedUsersList = assignedUsers.map((u) => {
      return {
        displayName: u.value.displayName,
        photoURL: u.value.photoURL,
        id: u.value.id,
      }
    })

    const project = {
      name,
      details,
      stage: stage,
      priority: priority,
      category: category.value,
      dueDate: timestamp.fromDate(new Date(dueDate)),
      comments: [],
      createdBy,
      assignedUsersList,
    }

    await addDocument(project)
    if (!response.error) {
      history.push("/")
    }
  }

  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Box sx={{ marginTop: 20, marginBottom: 20 }}>
          <div className="create-form">
            <h2 className="page-title">Crear nuevo proyecto</h2>
            <form onSubmit={handleSubmit}>
              <label>
                <span>Nombre del proyecto:</span>
                <input
                  required
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </label>
              <label>
                <span>Etapa:</span>
                <Select
                  onChange={(option) => handleStageChange(option)}
                  options={stages}
                  value={stage}
                  styles={colorStyles}
                />
              </label>
              <label>
                <span>Prioridad:</span>
                <Select
                  onChange={(option) => handlePriorityChange(option)}
                  options={priorities}
                  value={priority}
                  styles={colorStyles}
                />
              </label>
              <label>
                <span>Detalles del Proyecto:</span>
                <textarea
                  required
                  type="text"
                  onChange={(e) => setDetails(e.target.value)}
                  value={details}
                />
              </label>
              <label>
                <span>Fecha de entrega:</span>
                <input
                  required
                  type="date"
                  onChange={(e) => setDueDate(e.target.value)}
                  value={dueDate}
                />
              </label>
              <label>
                <span>Categoría del Proyecto:</span>
                <Select
                  onChange={(option) => setCategory(option)}
                  options={categories}
                />
              </label>
              <label>
                <span>Asignar proyecto a:</span>
                <Select
                  onChange={(option) => setAssignedUsers(option)}
                  options={users}
                  isMulti
                />
              </label>
              <button className="btn">Crear Proyecto</button>
              {formError && <p className="error">{formError}</p>}
            </form>
          </div>
        </Box>
      )}
    </>
  )
}
