import { useParams } from "react-router-dom"
import { useDocument } from "../../hooks/useDocument"
import { Link } from "react-router-dom"
//MUI
import { Grid, Box, Button } from "@mui/material"
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined"

// styles
import "./Product.css"
import ProductComments from "./ProductComments"
import ProductSummary from "./ProductSummary"

export default function Product({isMobile, userData}) {
  const { id } = useParams()
  const { document, error } = useDocument("products", id)

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading...</div>
  }


  return (
    <>
      <Box sx={{ marginTop: 15 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Button
              component={Link}
              to="/product-dashboard"
              variant="outlined"
              startIcon={<ArrowBackIosNewOutlinedIcon />}
            >
              Regresar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: 5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <ProductSummary product={document} />
          </Grid>
          <Grid item xs={12} md={12}>
            <ProductComments product={document} userData={userData} />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
