// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#14213d'
    },
    secondary: {
      main: '#0066FF'
    },
    heading: {
      main: '#444'
    },
    highlight: {
      main: '#fca311'
    },
    details: {
      main: '#ff006e',
      accent: '#f7f7f7',
      darker: "#dfe3ee"
    },
    success: {
      main: '#4caf50'
    },
    clear:{
      main: "#ffff",
      accent:"#fff",
      darker:"#fff"
    }
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: 16,
    h3: {
      fontWeight: 700,
      fontSize: '2.2rem'
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.75rem'
    },
    h5: {
      fontWeight: 500
    },
    h6: {
      fontWeight: 500
    }
  },
  MuiButtonBase: {
    defaultProps: {
      // The props to change the default for.
      //disableRipple: true, // No more ripple, on the whole application 💣!
      backgroundColor: '#ff006e',
    },
  },

})

export default theme;