import React, { createContext, useReducer, useEffect } from "react"
import { useAuthContext } from "../hooks/useAuthContext"
import { useDocument } from "../hooks/useDocument"
import { useCollection } from "../hooks/useCollection"

export const UserDataContext = createContext()

const initialState = {
  userData: null,
  userCompanies: [], // Initialize user companies array
  loading: true,
}

const userDataReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      return { ...state, userData: action.payload, loading: false }
    case "SET_USER_COMPANIES": // Handle setting user companies
      return { ...state, userCompanies: action.payload, loading: false }
    default:
      return state
  }
}

export const UserDataContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userDataReducer, initialState)
  const { user } = useAuthContext()
  const { document, error: userError } = useDocument("users", user?.uid)
  const { documents: companies, error: companiesError } = useCollection("empresas")

  useEffect(() => {
    if (user && document) {
      try {
        dispatch({ type: "SET_USER_DATA", payload: document })

        if (document.companiesList) {
          const userCompanyIds = Object.values(document.companiesList).map(company => company.id)
          const userCompanies = companies.filter(company => userCompanyIds.includes(company.id))
          dispatch({ type: "SET_USER_COMPANIES", payload: userCompanies })
        }
      } catch (error) {
        //console.error("Error processing user data:", error)
      }
    } else {
      //console.log("Error fetching user info:", userError)
    }
  }, [user, document, companies, userError, companiesError])

  return (
    <UserDataContext.Provider value={{ ...state, dispatch }}>
      {children}
    </UserDataContext.Provider>
  )
}
