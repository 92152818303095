import React, {useState, useEffect } from "react"
import { useUserRoleAndSecurity } from "../../hooks/useUserRoleAndSecurity"

const SortableTableFilter = ({ changeFilter }) => {
    //clearance required
  const { currentCompanyList, errorDoc } = useUserRoleAndSecurity()
  const [currentFilter, setCurrentFilter] = useState("todos")
  const [eDB, setEDB] = useState(null)

  const handleClick = (newFilter) => {
    setCurrentFilter(newFilter)
    changeFilter(newFilter)
  }

  // Connecting to DB empresas
  useEffect(() => {
    if (currentCompanyList) {
      const mappedLabels = currentCompanyList.map((empresa) => empresa.label)
      setEDB(mappedLabels)
    } else {
      //asignar empresa dummy?
      console.error("No hay empresas asignadas a este usuario: ", errorDoc)
    }
  }, [currentCompanyList, errorDoc])

  return (
    <div className="project-filter">
      <nav>
        <p>Ver por: </p>
        <button
          key={"todos"}
          onClick={() => handleClick("todos")}
          className={currentFilter === "todos" ? "active" : ""}
        >
          Todos
        </button>
        {eDB &&
          eDB.map((f) => (
            <button
              key={f} // Assuming f.label is unique
              onClick={() => handleClick(f)}
              className={currentFilter === f ? "active" : ""}
            >
              {f}
            </button>
          ))}
      </nav>
    </div>
  )
}

export default SortableTableFilter
