export const roles = [
  { value: "new", label: " 6. Doorman", color: "#505A5B", number: 6 },
  { value: "coffee", label: " 5. Coffee Fetcher", color: "#99D5C9", number: 5 },
  { value: "average", label: "4. Average Joe", color: "#6C969D", number: 4 },
  { value: "padawan", label: "3. Padawan", color: "#645E9D", number: 3 },
  { value: "admin", label: "2. Commander", color: "#392B58", number: 2 },
  {
    value: "master",
    label: "1. Master of Puppets",
    color: "#2D0320",
    number: 1,
  },
]
export const security = [
  { value: "basic", label: "4. Basic", color: "#99D5C9", number: 4 },
  { value: "average", label: "3. Average", color: "#645E9D", number: 3 },
  { value: "high", label: "2. High", color: "#392B58", number: 2 },
  { value: "top", label: "1. Top", color: "#2D0320", number: 1 },
]

export const table = (
  <table style={{width:'100%'}}>
    <thead>
      <tr style={{ color: "white" }}>
        <th style={{ color: "black", padding: "5px" }}>Role</th>
        <th style={{ background: roles[5].color, padding: "5px" }}>
          {roles[5].label}
        </th>
        <th style={{ background: roles[4].color, padding: "5px" }}>
          {roles[4].label}
        </th>
        <th style={{ background: roles[3].color, padding: "5px" }}>
          {roles[3].label}
        </th>
        <th style={{ background: roles[2].color, padding: "5px" }}>
          {roles[2].label}
        </th>
        <th style={{ background: roles[1].color, padding: "5px" }}>
          {roles[1].label}
        </th>
        <th style={{ background: roles[0].color, padding: "5px" }}>
          {roles[0].label}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Modulo Proyectos Lectura</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>No</td>
      </tr>
      <tr>
        <td style={{ padding: "5px" }}>Modulo Importaciones Lectura</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Modulo Productos Lectura</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Modulo Cotizaciones Lectura</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Modulo Gráficas Lectura</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Modulo Usuarios Lectura</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Modulo Empresas Lectura</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr style={{ color: "white" }}>
        <th style={{ color: "black", padding: "5px" }}>
          Seguridad (recomendada)
        </th>
        <th style={{ background: security[3].color, padding: "5px" }}>
          {security[3].label}
        </th>
        <th style={{ background: security[2].color, padding: "5px" }}>
          {security[2].label}
        </th>
        <th style={{ background: security[1].color, padding: "5px" }}>
          {security[1].label}
        </th>
        <th style={{ background: security[1].color, padding: "5px" }}>
          {security[1].label}
        </th>
        <th style={{ background: security[0].color, padding: "5px" }}>
          {security[0].label}
        </th>
        <th style={{ background: security[0].color, padding: "5px" }}>
          {security[0].label}
        </th>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Crear Proyectos</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Crear Importaciones</td>
        <td>Si</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Crear Productos</td>
        <td>Si</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Crear Cotizaciones</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Graficas (?)</td>
        <td>Si</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Crear Usuarios</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Crear Empresas</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Modificar Proyectos</td>
        <td>Si</td>
        <td>Si</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Modificar Importaciones</td>
        <td>Si</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Modificar Productos</td>
        <td>Si</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Modificar Cotizaciones</td>
        <td>Si</td>
        <td>Propios</td>
        <td>Propios</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Graficas (?)</td>
        <td>Si</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Modificar Usuarios</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Modificar Empresas</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Eliminar Proyectos</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Eliminar Importaciones</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Eliminar Productos</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Eliminar Cotizaciones</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Graficas (?)</td>
        <td>Si</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>Eliminar Usuarios</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
      <tr>
        <td>Eliminar Empresas</td>
        <td>Si</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
      </tr>
    </tbody>
  </table>
)
