export  const colorStyles = {
    control: (styles, { selectProps: { inputValue, value } }) => ({
      ...styles,
      backgroundColor: value ? value.color : 'white',
      borderColor: value ? value.color : 'gray',
      boxShadow: value ? `0 0 0 1px ${value.color}` : null,
      minWidth: '200px',
      width:'100%',
      transition: 'all .2s ease-in-out',
      '&:hover': {
        borderColor: value ? value.color : 'gray'
      },
      color: value ? 'white' : 'black'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? `${data.color}2B`
            : null,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? data.color : `${data.color}26`)
      }
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      color: 'white'
    })
  };