import React, { forwardRef, useState } from "react"

//MUI
import { Box, Button, Divider, Grid } from "@mui/material"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"


function getFirstSubstringAfterSlash(pathname) {
  const split_parts = pathname.split("/")
  const location = split_parts[1]
  return location
}
const formatMoney = (value) => {
  const number = Number(value)
  return isNaN(number)
    ? ""
    : number.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
        maximumFractionDigits: "2",
      })
}

const pathname = window.location.pathname
const location = getFirstSubstringAfterSlash(pathname)

const PrintableQuote = forwardRef((props, ref) => {
  const { cotizacion, cotReady, editQuote, isMobile } = props
  const iva = cotizacion.totalCost * 0.16
  const total = cotizacion.totalCost * 1.16

  const handleReady = (ready) => {
    editQuote(cotizacion)
    cotReady(ready)
  }

  const createMarkup = (html) => {
    return { __html: html }
  }


  return (
    <Box
      sx={{ marginTop: 0, width: "100%", marginLeft: 5, marginRight: 5 }}
      ref={ref}
    >
      <Box className="no-print">
        <Grid container spacing={2}>
          <Grid item xs={8} md={10} />
          {!cotizacion.id && (
            <Grid item xs={2} md={1}>
              <button className="btn" onClick={() => handleReady(false)}>
                Editar
              </button>
            </Grid>
          )}
        </Grid>
      </Box>
      {/* Encabezado Empresa */}
      <Box>
        <Grid container spacing={2}>
          <Grid item md={3} xs={3}>
            <img
              src={cotizacion.empresa.photoURL}
              width={!isMobile ? "200px" : "100px"}
              alt="logo"
            />
          </Grid>
          <Grid item md={9} xs={9} alignItems="center">
            <h1 style={{ textAlign: "center" }}>{cotizacion.empresa.label}</h1>
            <p style={{ textAlign: "center", fontSize: "12px" }}>
              de Hulempak SA de CV
            </p>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ marginTop: "40px" }}>
        {/* Datos Cliente */}
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <p>Fecha: {cotizacion.fecha}</p>
          </Grid>
          <Grid item md={8} xs={12}>
            <p>Cotización: {cotizacion.cotizacion} </p>
          </Grid>
          <Grid item md={4} xs={12}>
            <p>Empresa: {cotizacion.empresaCliente}</p>
          </Grid>
          <Grid item md={8} xs={12}>
            <p>Nombre del Cliente: {cotizacion.nombreCliente}</p>
          </Grid>
          <Grid item md={4} xs={12}>
            <p>Email: {cotizacion.email}</p>
          </Grid>
          <Grid item md={8} xs={12}>
            <p>Teléfono: {cotizacion.telefono}</p>
          </Grid>
        </Grid>
      </Box>
      {/*Productos */}
      <TableContainer
        sx={{
          marginTop: "40px",
          overflowX: isMobile ? "visible" : "auto",
        }}
      >
        <Table sx={{ minWidth: !isMobile ? 650 : "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "10%" }}>Cantidad</TableCell>
              <TableCell sx={{ width: "10%" }}>Unidad</TableCell>
              <TableCell sx={{ width: "50%" }}>Descripción</TableCell>
              <TableCell sx={{ width: "10%" }}>P. Unitario</TableCell>
              <TableCell sx={{ width: "20%" }}>Importe</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cotizacion.productList &&
              cotizacion.productList.map((product, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>{product.quantity.toLocaleString()}</TableCell>
                  <TableCell>{product.unidades.value}</TableCell>
                  <TableCell>{product.producto}</TableCell>
                  <TableCell>{formatMoney(product.precio)}</TableCell>
                  <TableCell>
                    {formatMoney(product.costoTotal)}
                  </TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>

            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>
                
                {formatMoney(cotizacion.totalCost)}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>IVA</TableCell>
              <TableCell>
                {formatMoney(iva)}
              </TableCell>
            </TableRow>
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Total</TableCell>
              <TableCell>
                {formatMoney(total)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {/*Footer */}
      <Box>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
          <div dangerouslySetInnerHTML={createMarkup(cotizacion?.details || "")} />
            {/* <p>Comentarios adicionales: {cotizacion.details}</p> */}
          </Grid>
          <Grid item md={12} xs={12}>
            <p>*LOS PRECIOS COTIZADOS SON EN MONEDA NACIONAL</p>
            <p>
              *LA MERCANCÍA SE ENTREGA UNICAMENTE AL ESTAR PAGADA EN SU
              TOTALIDAD.
            </p>
            <p>
              *NUESTRAS COTIZACIONES NO INCLUYEN COSTO DE ENVÍO A MENOS QUE SE
              ESTABLEZCA LO CONTRARIO
            </p>
            <p>
              *ANTES DE REALIZAR PAGO ALGUNO FAVOR DE SOLICITAR EXISTENCIAS DEL
              PRODUCTO A ADQUIRIR.
            </p>
            {cotizacion.empresa.privacyPolicy && (
              <p>
                *REVISA NUESTRA POLÍTICA DE VENTAS Y DEVOLUCIONES EN{" "}
                {cotizacion.empresa.privacyPolicy}
              </p>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box>
        {/* Pie de página Empresa */}
        <Grid container spacing={2} sx={{ marginTop: 5 }}>
          <Grid item xs={12} md={12}>
            <Divider />
          </Grid>
          <Grid item md={3} xs={12}>
            <img
              src={cotizacion.empresa.photoURL}
              width={!isMobile ? "200px" : "100px"}
              alt="logo"
            />
          </Grid>
          <Grid item md={9} xs={12}>
            <p style={{ textAlign: "center" }}>{cotizacion.empresa.website}</p>
            <p style={{ textAlign: "center" }}>
              {cotizacion.empresa.companyEmail}
            </p>
            <p style={{ textAlign: "center" }}>
              Tel: {cotizacion.empresa.companyPhone}
            </p>
            <p style={{ textAlign: "center" }}>
              Whatsapp: {cotizacion.empresa.companyWhatsapp}
            </p>
            <p style={{ textAlign: "center" }}>{cotizacion.empresa.address}</p>
          </Grid>
        </Grid>
      </Box>
      <p className="no-print">{location}</p>
    </Box>
  )
})

export default PrintableQuote
