import React from "react"
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material"

import { Link } from "react-router-dom"

const ReusableCardWithList = ({ title, mainIcon, listItems }) => {
  return (
    <Grid item xs={12} md={3}>
      <Card sx={{ minHeight: "250px" }}>
        <CardHeader
          avatar={<IconButton>{mainIcon}</IconButton>}
          title={title}
        ></CardHeader>
        <Divider />
        <CardContent>
          <List dense={true}>
            {listItems?.map((item) => (
              <ListItemButton key={item.key} component={Link} to={item.to}>
                <ListItemIcon >{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            ))}
          </List>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ReusableCardWithList
