import React, { useCallback, useRef, useState } from "react"
import { useFirestore } from "../hooks/useFirestore"

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material"

//styles
import "./ProductEditableTable.css"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"

function getDifferentAttributes(newRow, oldRow) {
  const differentAttributes = []
  for (const key in newRow) {
    if (newRow.hasOwnProperty(key)) {
      if (newRow[key] !== oldRow[key]) {
        differentAttributes.push(key)
      }
    }
  }
  return differentAttributes
}

function compareData(newRow, oldRow) {
  //comparación de datos
  const comparison = getDifferentAttributes(newRow, oldRow)
  if (comparison.length > 0) {
    comparison.forEach((key) => {
      //console.log(`${key}: ${newRow[key]}`)
    })
    return { isDifferent: true, comparison }
  } else {
    //console.log("same data")

    return { isDifferent: false, comparison }
  }
}

export default function ProductEditableTable({ products }) {
  //state to update row
  const [promiseArguments, setPromiseArguments] = useState(null)
  const noButtonRef = useRef(null)
  const { updateDocument, response } = useFirestore("products")

  const handleEntered = () => {
    // The `autoFocus` is not used because, if used, the same Enter that saves
    // the cell triggers "No". Instead, we manually focus the "No" button once
    // the dialog is fully open.
    // noButtonRef.current?.focus();
  }

  const handleYes = () => {
    const { newRow, oldRow, reject, resolve, comparison } = promiseArguments
    let cleanData = ""
    if (
      comparison.comparison[0] !== "name" &&
      comparison.comparison[0] !== "productCode"
    ) {
      cleanData = newRow[comparison.comparison]
        .replace(/[^0-9. ]/g, "")
        .replace(/\s/g, "")
    } else {
      cleanData = newRow[comparison.comparison]
    }

    //console.log("yeah: ", comparison.comparison[0], cleanData)
    console.log("attempt: ", newRow[comparison.comparison[0]])
    handleUpdate(newRow, oldRow, comparison, cleanData, resolve, reject)
  }
  const handleNo = () => {
    const { oldRow, resolve } = promiseArguments
    resolve(oldRow) // Resolve with the old row to not update the internal state
    setPromiseArguments(null)
  }

  const handleUpdate = async (
    newRow,
    oldRow,
    comparison,
    cleanData,
    resolve,
    reject
  ) => {
    const fieldToUpdate = comparison.comparison[0]
    await updateDocument(newRow.id, { [fieldToUpdate]: cleanData })
    if (!response.error) {
      resolve(newRow)
      setPromiseArguments(null)
    } else {
      reject(oldRow)
      setPromiseArguments(null)
    }
  }

  const processRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        //resto del codigo
        const comparison = compareData(newRow, oldRow)
        if (comparison.isDifferent) {
          //data changed
          // Save the arguments to resolve or reject the promise later
          console.log("key: ", comparison.comparison)
          setPromiseArguments({ resolve, reject, newRow, oldRow, comparison })
        } else {
          //nothing changed
          resolve(oldRow)
        }
      }),
    []
  )
  const renderConfirmDialog = () => {
    if (!promiseArguments) {
      return null
    }

    const { newRow, oldRow } = promiseArguments
    const comparison = compareData(newRow, oldRow)

    return (
      <Dialog
        maxWidth="xs"
        TransitionProps={{ onEntered: handleEntered }}
        open={!!promiseArguments}
      >
        <DialogTitle>Estás seguro?</DialogTitle>
        <DialogContent dividers>
          {`Presionar 'Si' cambiará ${oldRow[comparison.comparison]} por ${
            newRow[comparison.comparison]
          }`}
        </DialogContent>
        <DialogActions>
          <Button ref={noButtonRef} onClick={handleNo}>
            No
          </Button>
          <Button onClick={handleYes}>Si</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const columns = [
    {
      field: "productCode",
      headerName: "Codigo de Producto",
      width: 300,
      cellClassName: "bold-header",
      editable: true,
    },
    { field: "name", headerName: "Nombre", editable: true, width: 500 },
    { field: "ancho", headerName: "Ancho", editable: true },
    { field: "largo", headerName: "Largo", editable: true },
    { field: "peso", headerName: "Peso", editable: true },
    {
      field: "largoRollo",
      headerName: "Largo del Rollo",
      width: 120,
      editable: true,
    },
    {
      field: "espesorMM",
      headerName: "Espesor en mm",
      width: 130,
      editable: true,
    },
    {
      field: "espesorIn",
      headerName: "Espesor en In",
      width: 120,
      editable: true,
    },
    {
      field: "precioHPKMetro",
      headerName: "Precio por metro Hulempak",
      width: 220,
      editable: true,
    },
    {
      field: "precioHPKRollo",
      headerName: "Precio por rollo Hulempak",
      width: 220,
      editable: true,
    },
    {
      field: "precioPzHPK",
      headerName: "Precio a Hulempak por Pieza",
      width: 220,
      editable: true,
    },
    {
      field: "precioFilialesMetro",
      headerName: "Precio por metro Filiales por Metro",
      width: 300,
      editable: true,
    },
    {
      field: "precioFilialesRollo",
      headerName: "Precio por metro Filiales por Rollo",
      width: 300,
      editable: true,
    },
    {
      field: "precioPzFiliales",
      headerName: "Precio a filiales por Pieza",
      width: 300,
      editable: true,
    },
    {
      field: "precioMLMetro",
      headerName: "Precio por metro Mercado Libre por Metro",
      width: 300,
      editable: true,
    },
    {
      field: "precioMLRollo",
      headerName: "Precio por metro Mercado Libre por Rollo",
      width: 300,
      editable: true,
    },
    {
      field: "precioPzML",
      headerName: "Precio a Mercado Libre por Pieza",
      width: 300,
      editable: true,
    },
    {
      field: "precioPublicoMetro",
      headerName: "Precio por metro al publico por Metro",
      width: 300,
      editable: true,
    },
    {
      field: "precioPublico1Rollo",
      headerName: "Precio por metro al publico por 1 Rollo",
      width: 300,
      editable: true,
    },
    {
      field: "precioPublico24Rollo",
      headerName: "Precio por metro al publico por 2 -4  Rollos",
      width: 300,
      editable: true,
    },
    {
      field: "precioPublico5masRollo",
      headerName: "Precio por metro al publico por 5 o más  Rollos",
      width: 300,
      editable: true,
    },
    {
      field: "precioPublicoRollo",
      headerName: "Precio por metro al publico por Rollo",
      width: 300,
      editable: true,
    },
    {
      field: "precioPz",
      headerName: "Precio a al publico por Pieza",
      width: 300,
      editable: true,
    },
    {
      field: "assignedTagsList",
      headerName: "Tags",
      width: 300,
      editable: false,
    },
  ]

  const data = products.map((product) => {
    const tags = product.assignedTagsList.map((tag) => tag.tag).join(", ")
    return {
      id: product.id,
      productCode: product.productCode || "",
      name: product.name || "",
      ancho: product.ancho ? `${product.ancho} m` : "",
      largo: product.largo ? `${product.largo} m` : "",
      peso: product.peso ? `${product.peso} kg` : "",
      largoRollo: product.largoRollo ? `${product.largoRollo} m` : "",
      espesorMM: product.espesorMM ? `${product.espesorMM}mm` : "",
      espesorIn: product.espesorIn ? `${product.espesorIn}"` : "",
      precioHPKMetro: product.precioHPKMetro
        ? `$${product.precioHPKMetro}`
        : "",
      precioPzHPK: product.precioPzHPK ? `$${product.precioPzHPK}` : "",
      precioHPKRollo: product.precioHPKRollo
        ? `$${product.precioHPKRollo}`
        : "",
      precioFilialesMetro: product.precioFilialesMetro
        ? `$${product.precioFilialesMetro}`
        : "",
      precioFilialesRollo: product.precioFilialesRollo
        ? `$${product.precioFilialesRollo}`
        : "",
      precioPzFiliales: product.precioPzFiliales
        ? `$${product.precioPzFiliales}`
        : "",
      precioMLMetro: product.precioMLMetro ? `$${product.precioMLMetro}` : "",
      precioMLRollo: product.precioMLRollo ? `$${product.precioMLRollo}` : "",
      precioPzML: product.precioPzML ? `$${product.precioPzML}` : "",
      precioPublicoMetro: product.precioPublicoMetro
        ? `$${product.precioPublicoMetro}`
        : "",
      precioPublicoRollo: product.precioPublicoRollo
        ? `$${product.precioPublicoRollo}`
        : "",
      precioPublico1Rollo: product.precioPublico1Rollo
        ? `$${product.precioPublico1Rollo}`
        : "",
      precioPublico24Rollo: product.precioPublico24Rollo
        ? `$${product.precioPublico24Rollo}`
        : "",
      precioPublico5masRollo: product.precioPublico5masRollo
        ? `$${product.precioPublico5masRollo}`
        : "",
      precioPz: product.precioPz ? `$${product.precioPz}` : "",
      assignedTagsList: tags,
    }
  })

  return (
    <div className="responsive">
      <Box
        sx={{
          height: 600,
        }}
      >
        {renderConfirmDialog()}
        <DataGrid
          className="datagrid"
          slots={{ toolbar: GridToolbar }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ancho: false,
                largo: false,
                peso: false,
                largoRollo: false,
                espesorMM: false,
                espesorIn: false,
                espesorIn2: false,
                precioFilialesMetro: false,
                precioFilialesRollo: false,
                precioPzFiliales: false,
                precioMLMetro: false,
                precioMLRollo: false,
                precioPzML: false,
                precioHPKMetro: false,
                precioPzHPK: false,
                precioHPKRollo: false,
                precioPublico1Rollo: false,
                precioPublico24Rollo: false,
                precioPublico5masRollo: false,
              },
            },
          }}
          getRowId={(row) => row.id}
          columns={columns}
          rows={data}
          processRowUpdate={processRowUpdate}
        />
      </Box>
    </div>
  )
}
