import React from "react"
import { Clearance } from "../../components/tools/Clearance"
import { table } from "./UsersClassificationTable"
import { Box, Grid } from "@mui/material"
import Error404 from "../error404/Error404"

const UserRolesDescription = ({ isMobile }) => {
  return (
    <>
      {!Clearance(1, 1) && <Error404 />}
      {Clearance(1, 1) && (
        <Grid item xs={12} md={12}>
          <Box sx={{ marginTop: !isMobile ? 20 : 5 }}>
            <h1 className="page-title">Roles y Seguridad</h1>
            {table}
          </Box>
        </Grid>
      )}
    </>
  )
}

export default UserRolesDescription
