import React from "react"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { Link } from "react-router-dom"
import ReusableCardWithList from "./ReusableCardWithList"

import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined"
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import SearchIcon from "@mui/icons-material/Search"
import AddIcon from "@mui/icons-material/Add"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined"
import UserSummaryTable from "./UserSummaryTable"

const usuarios = [
  {
    key: "users-dashboard",
    to: "/users-dashboard",
    icon: <SearchIcon />,
    label: "Ver usuarios",
  },
  {
    key: "roles",
    to: "/users-roles",
    icon: <LockOutlinedIcon />,
    label: "Ver Roles",
  },
]
const empresas = [
  {
    key: "company-dashboard",
    to: "/company-dashboard",
    icon: <SearchIcon />,
    label: "Ver Empresas",
  },
  {
    key: "company-creation",
    to: "/create-company",
    icon: <AddIcon />,
    label: "Crear Empresa",
  },
]

const modulos = [
  {
    key: "modules",
    to: "/modules",
    icon: <SearchIcon />,
    label: "Ver Módulos",
  },
  {
    key: "module-creation",
    to: "/create-module",
    icon: <AddIcon />,
    label: "Crear Módulo",
  },
]

const MasterDashboard = ({ userData, userCompanies, isMobile }) => {
  console.log("User companies context return: ", userCompanies)
  return (
    <Container sx={{ marginTop: !isMobile ? 20 : 5, padding: 2 }}>
      <h1>Master Tools</h1>
      <Grid container spacing={2}>
        <ReusableCardWithList
          mainIcon={<GroupOutlinedIcon />}
          title="Usuarios"
          key="usuarios"
          listItems={usuarios}
        />
        <ReusableCardWithList
          mainIcon={<StorefrontOutlinedIcon />}
          title="Empresas"
          key="Empresas"
          listItems={empresas}
        />
        <ReusableCardWithList
          mainIcon={<DashboardCustomizeOutlinedIcon />}
          title="Módulos"
          key="Modulos"
          listItems={modulos}
        />
        <ReusableCardWithList
          mainIcon={<SettingsOutlinedIcon />}
          title="Settings"
          key="Settings"
        />
      </Grid>
      <Box sx={{ marginTop: 5 }}>
        <h2>Información de usuario actual</h2>
        <p>Nombre: {userData?.displayName}</p>
        <p>Email: {userData?.email}</p>
        <p>Compañía Activa: {userData?.activeCompany.label}</p>
        <h3>Empresas asignadas:</h3>
        <UserSummaryTable userData={userData} />

        <h3>Información de empresas asignadas:</h3>
        {userCompanies?.map((uc) => (
          <React.Fragment key={uc.id}>
            <h4>{uc.label}</h4>
            {uc.modules.map((ucm) => (
              <p key={ucm.label}>{ucm.label}</p>
            ))}
          </React.Fragment>
        ))}

        <h3>Roles: </h3>
        <p>{userData?.role.label}</p>
        <h3>Seguridad: </h3>
        <p>{userData?.security.label}</p>
      </Box>
    </Container>
  )
}

export default MasterDashboard
