import React from "react"
import { Clearance } from "../../components/tools/Clearance"
import List from "@mui/material/List"
import { Box } from "@mui/material"

import PDProjectsModule from "../../components/persistantDrawer/PDProjectsModule"
import PDShipmentsModule from "../../components/persistantDrawer/PDShipmentsModule"
import PDProductsModule from "../../components/persistantDrawer/PDProductsModule"
import PDQuotesModule from "../../components/persistantDrawer/PDQuotesModule"
import PDChartsModule from "../../components/persistantDrawer/PDChartsModule"
import PDUsersModule from "../../components/persistantDrawer/PDUsersModule"
import PDCompaniesModule from "../../components/persistantDrawer/PDCompaniesModule"
import PDProfile from "../../components/persistantDrawer/PDProfile"
import { Divider } from "@material-ui/core"
import PDWarehouseModule from "../../components/persistantDrawer/PDWarehouseModule"
import PDClientsModule from "../../components/persistantDrawer/PDClientsModule"
import PDCalculatorsModule from "../../components/persistantDrawer/PDCalculatorsModule"
import PDMaster from "../../components/persistantDrawer/PDMaster"

const MobileMenu = ({ isMobile, userData }) => {
  const handleDrawerClose = () => {}
  return (
    <Box sx={{ marginTop: !isMobile ? 20 : 5, marginBottom: 10, padding: 2 }}>
      <h2 className="page-title">Menú</h2>

      <List sx={{ width: "100%" }} aria-labelledby="nested-list-subheader">
        <PDProfile handleDrawerClose={handleDrawerClose} user={userData} />
        <Divider />
        {/*Master module */}
        {Clearance(1, 1) && <PDMaster handleDrawerClose={handleDrawerClose} />}
        {/*Projects module */}
        {Clearance(5, 4) && (
          <PDProjectsModule handleDrawerClose={handleDrawerClose} />
        )}
        {/*Shipments module */}
        {Clearance(5, 4) && (
          <PDShipmentsModule handleDrawerClose={handleDrawerClose} />
        )}
        {/*Products module */}
        {Clearance(4, 4) && (
          <PDProductsModule handleDrawerClose={handleDrawerClose} />
        )}
        {/*Products module 
        {Clearance(3, 4) && (
          <PDWarehouseModule handleDrawerClose={handleDrawerClose} />
        )}*/}
        {/*Quotes module */}
        {Clearance(3, 3) && (
          <PDQuotesModule handleDrawerClose={handleDrawerClose} />
        )}
        {/*Cotizadores module */}
        {Clearance(4, 4) && (
          <PDCalculatorsModule handleDrawerClose={handleDrawerClose} />
        )}
        {/*Charts module */}
        {Clearance(3, 3) && (
          <PDChartsModule handleDrawerClose={handleDrawerClose} />
        )}
        {/*Customers Modile*/}
        {Clearance(4, 4) && (
          <PDClientsModule handleDrawerClose={handleDrawerClose} />
        )}
      </List>
    </Box>
  )
}

export default MobileMenu
