/**
 * CreateQuoteView
 *
 * This component is the presentation layer for creating a new quotation. It includes form elements
 * for inputting various details such as company information, client details, products, and additional comments.
 * It leverages Material UI components for UI elements and react-select for dropdowns.
 *
 * Props:
 * - clearance (boolean): Determines if the user has the necessary clearance to access the component.
 * - formError (string): Displays any form validation errors.
 * - isLoading (boolean): Indicates if the component is in a loading state.
 * - productList (array): List of products to be included in the quotation.
 * - showModal (boolean): Controls the visibility of the success modal.
 * - empresa, assignedCompany, cotizacion, fecha, nombreCliente, telefono, email, details (various types): These are various form fields for the quote creation.
 * - mappedUserCompanies, companyCustomers, unidades (arrays): Data arrays for select options.
 * - handleCreateCompany, handleClose, handleEmpresaChange, handleEmpresaClienteChange, handleCotizacionChange, handleFechaChange, handleNombreClienteChange, handleTelefonoChange, handleEmailChange, hanldeDetailsChange, handleProductChange, handleQuantityChange, handlePriceChange, handleUnitChange, addProductField, removeProductField, handleSubmit (functions): These are event handlers for various actions in the form.
 *
 * Usage:
 * ```jsx
 * <CreateQuoteView
 *   clearance={userClearance}
 *   formError={formErrorMessage}
 *   isLoading={loadingState}
 *   productList={productListData}
 *   showModal={isModalVisible}
 *   {...otherProps}
 * />
 * ```
 *
 * This component should be used in scenarios where a user needs to create or edit a quotation.
 */
import React from "react"
import { Alert, Box, Button, Grid, Snackbar } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import AddIcon from "@mui/icons-material/Add"
import SaveIcon from "@mui/icons-material/Save"
import Select, { components } from "react-select"
import CreatableSelect from "react-select/creatable"
import SuccessModal from "../../../components/SuccessModal"
import "./CreateQuote.css"
import Error404 from "../../error404/Error404"
import Comments from "../items/Comments"

const CreateQuoteView = ({
  clearance,
  formError,
  isLoading,
  productList,
  showModal,
  openBackdrop,
  empresa,
  assignedCompany,
  cotizacion,
  fecha,
  nuevoCliente,
  nuevoClienteCreado,
  nombreCliente,
  telefono,
  email,
  details,
  mappedUserCompanies,
  companyCustomers,
  companyAgents,
  companyAgent,
  unidades,
  handleCreateCompany,
  handleCompanyAgentChange,
  handleCreateAgent,
  handleClose,
  handleEmpresaChange,
  handleEmpresaClienteChange,
  handleCotizacionChange,
  handleFechaChange,
  handleNuevoCliente,
  handleNombreClienteChange,
  handleTelefonoChange,
  handleEmailChange,
  hanldeDetailsChange,
  handleProductChange,
  handleQuantityChange,
  handlePriceChange,
  handleUnitChange,
  addProductField,
  removeProductField,
  handleSubmit,
}) => {
  if (!clearance) return <p>Access Denied</p>

  console.log("company Agents: ", companyAgents)

  const SelectMenuButton = (props) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <Button
          startIcon={<AddIcon />}
          sx={{ width: "100%", justifyContent: "flex-start" }}
          onClick={handleNuevoCliente}
        >
          Crear Cliente Nuevo
        </Button>
      </components.MenuList>
    )
  }

  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Box sx={{ marginTop: 20 }}>
          <div className="">
            <h2 className="page-title">Crear nueva cotización</h2>

            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item md={3} sm={12} sx={{ padding: "10px" }}>
                  <span>Elegir Formato:</span>
                  <Select
                    required
                    onChange={(e) => handleEmpresaChange(e)}
                    options={mappedUserCompanies}
                    placeholder="Empresa"
                    value={empresa}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {empresa && (
                  <Grid item md={3} sm={12} sx={{ padding: "10px" }}>
                    <label>
                      <span>Empresa del Cliente:</span>
                      <CreatableSelect
                        autofocus="false"
                        onChange={(option) => {
                          handleEmpresaClienteChange(option)
                        }}
                        options={companyCustomers}
                        onCreateOption={handleCreateCompany}
                        placeholder="Empresa del Cliente"
                        value={assignedCompany}
                      />
                    </label>
                  </Grid>
                )}
              </Grid>

              {/*Render after empresaCliente is created */}

              {assignedCompany && (
                <Grid container spacing={2}>
                  {/*TODO: Aqui esta la sugerencia de nombre de cliente */}
                  {!nuevoCliente && (
                    <Grid item md={3} sm={12} sx={{ padding: "10px" }}>
                      <label>
                        <span>Nombre del Cliente:</span>
                        <Select
                          autofocus="false"
                          onChange={(option) => {
                            handleCompanyAgentChange(option)
                          }}
                          options={companyAgents}
                          placeholder="Cliente"
                          value={companyAgent}
                          components={{ MenuList: SelectMenuButton }}
                        />
                      </label>
                    </Grid>
                  )}
                  {nuevoCliente && (
                    <Grid item md={3} sm={12} sx={{ padding: "10px" }}>
                      <label>
                        <span>Nombre:</span>
                        <input
                          required
                          type="text"
                          onChange={(e) =>
                            handleNombreClienteChange(e.target.value)
                          }
                          value={nombreCliente}
                        />
                      </label>
                    </Grid>
                  )}
                  {(nuevoCliente || companyAgent) && (
                    <>
                      <Grid item md={3} sm={12} sx={{ padding: "10px" }}>
                        <label>
                          <span>Teléfono:</span>
                          <input
                            required
                            type="text"
                            onChange={(e) =>
                              handleTelefonoChange(e.target.value)
                            }
                            value={telefono}
                          />
                        </label>
                      </Grid>
                      <Grid item md={3} sm={12} sx={{ padding: "10px" }}>
                        <label>
                          <span>Email:</span>
                          <input
                            required
                            type="text"
                            onChange={(e) => handleEmailChange(e.target.value)}
                            value={email}
                          />
                        </label>
                      </Grid>
                    </>
                  )}

                  {!companyAgent && !nuevoClienteCreado && nuevoCliente && (
                    <Grid item md={4} xs={12}>
                      <Button
                        startIcon={<SaveIcon />}
                        variant="outlined"
                        onClick={handleCreateAgent}
                      >
                        Guardar Cliente
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
              {assignedCompany && (companyAgent || nuevoClienteCreado) && (
                <Grid container spacing={2}>
                  <Grid item md={3} sm={12} sx={{ padding: "10px" }}>
                    <label>
                      <span>Número de cotización:</span>
                      <input
                        required
                        type="text"
                        onChange={(e) => handleCotizacionChange(e.target.value)}
                        value={cotizacion}
                        onWheel={(event) => event.currentTarget.blur()}
                      />
                    </label>
                  </Grid>
                  <Grid item md={3} sm={12} sx={{ padding: "10px" }}>
                    <label>
                      <span>Fecha:</span>
                      <input
                        required
                        type="date"
                        onChange={(e) => handleFechaChange(e.target.value)}
                        value={fecha}
                      />
                    </label>
                  </Grid>
                </Grid>
              )}

              {assignedCompany && (companyAgent || nuevoClienteCreado) && (
                <h3 className="page-title">Productos</h3>
              )}

              {assignedCompany && (companyAgent || nuevoClienteCreado) && (
                <Box sx={{ marginTop: 5 }}>
                  <Box container>
                    {productList.map((row, index) => (
                      <Grid container spacing={2} key={row}>
                        <Grid item md={12} sm={12} sx={{ padding: "10px" }}>
                          <span>Nombre del producto {row.index}:</span>
                          <input
                            required
                            type="text"
                            onChange={(e) => handleProductChange(e, index)}
                            placeholder="Nombre del producto con medidas"
                            value={productList[index].producto}
                          />
                        </Grid>
                        <Grid item md={4} sm={12} sx={{ padding: "10px" }}>
                          <span>Cantidad:</span>
                          <input
                            required
                            type="number"
                            min="1"
                            onChange={(e) => handleQuantityChange(e, index)}
                            value={productList[index].quantity}
                            placeholder="cantidad"
                            onWheel={(event) => event.currentTarget.blur()}
                          />
                        </Grid>
                        <Grid item md={4} sm={12} sx={{ padding: "10px" }}>
                          <span>Unidades:</span>
                          <Select
                            required
                            onChange={(option) =>
                              handleUnitChange(option, index)
                            }
                            options={unidades}
                            placeholder="Unidad"
                            value={productList[index].unidades}
                          />
                        </Grid>
                        <Grid item md={4} sm={12} sx={{ padding: "10px" }}>
                          <span>Precio Unitario:</span>
                          <input
                            required
                            type="number"
                            min="1"
                            onChange={(e) => handlePriceChange(e, index)}
                            placeholder="precio"
                            onWheel={(event) => event.currentTarget.blur()}
                            value={productList[index].precio}
                          />
                        </Grid>

                        {index > 0 && (
                          <Grid item md={2} sm={1} sx={{ padding: "10px" }}>
                            <Button
                              variant="contained"
                              aria-label="delete"
                              size=""
                              onClick={() => removeProductField(index)}
                              color="error"
                              endIcon={<DeleteIcon />}
                            >
                              Borrar
                            </Button>
                          </Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sx={{
                            borderBottom: "1px solid #dddddd",
                            margin: "30px",
                          }}
                        />
                      </Grid>
                    ))}
                  </Box>
                </Box>
              )}

              {assignedCompany && (companyAgent || nuevoClienteCreado) && (
                <Button onClick={addProductField} variant="outlined">
                  Agregar otro producto
                </Button>
              )}
              {assignedCompany && (companyAgent || nuevoClienteCreado) && (
                <Grid container spacing={2}>
                  <Grid item md={9} sm={12} sx={{ padding: "10px" }}>
                    {/* <label>
                      <span>Comentarios adicionales (vigencia):</span>
                      <textarea
                        type="text"
                        onChange={(e) => hanldeDetailsChange(e.target.value)}
                        value={details}
                        placeholder="Ej. Vigencia de la cotización o detalles específicos sobre la venta"
                      />
                    </label> */}
                    <label>
                      <span>Comentarios adicionales (vigencia):</span>
                      <Comments
                        comentarios={details}
                        hanldeDetailsChange={hanldeDetailsChange}
                      />
                    </label>
                  </Grid>
                </Grid>
              )}

              {assignedCompany && (companyAgent || nuevoClienteCreado) && (
                <Button onClick={handleSubmit} variant="outlined">
                  Crear Cotización
                </Button>
              )}
              {showModal && (
                <SuccessModal
                  title="Cotización Creada"
                  message="Revisa la cotización aquí"
                  onClose={handleClose}
                  onOK={handleClose}
                  open={showModal}
                />
              )}
              <Snackbar
                open={openBackdrop}
                autoHideDuration={2000}
                onClose={handleClose}
              >
                <Alert severity="error">{formError}</Alert>
              </Snackbar>
            </form>
          </div>
        </Box>
      )}
    </>
  )
}

export default CreateQuoteView
