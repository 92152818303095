import React, { useState } from "react"
// styles
import { Box, Grid } from "@mui/material"
//components
import QuoteTemplate from "./QuoteTemplate"
import CreateQuoteLogic from "./CreateQuote/CreateQuoteLogic"

const PrintQuote = ({userData}) => {
  const [quote, setQuote] = useState("")
  const [ready, setReady] = useState(false)

  const sendQuote = (quote) => {
    setQuote(quote)
  }
  const cotReady = (flag) => {
    setReady(flag)
  }
  const editQuote = (cot) =>{
    console.log("cotizacion transitoria: ", cot)
    setQuote(cot)
  }

  return (
    <Box>
      <Grid container spacing={2}>
        {!ready && (
          <Grid item xs={12} md={12}>
            <CreateQuoteLogic sendQuote={sendQuote} cotReady={cotReady} editQuote={quote} userData={userData}/>
          </Grid>
        )}
        {ready && (
          <Grid item xs={12} md={12} sx={{ marginTop: 15 }}>
            <QuoteTemplate cotizacion={quote} cotReady={cotReady} editQuote={editQuote} userData={userData}/>
          </Grid>
        )}
        <Grid item xs={12} md={4}></Grid>
      </Grid>
    </Box>
  )
}

export default PrintQuote
