import React, { useEffect, useState } from "react"
import { useFirestore } from "../../hooks/useFirestore"
import {
  Avatar,
  Box,
  Button,
  CardContent,
  CardHeader,
  Container,
  List,
} from "@mui/material"
import Select from "react-select"
import SecuritySelector from "./SecuritySelector"

const roles = [
  { id: "direc", value: "direc", label: "Director" },
  { id: "admin", value: "admin", label: "Administrador" },
  { id: "colab", value: "colab", label: "Colaborador" },
  { id: "viewer", value: "viewer", label: "Espectador" },
]

const EditUser = ({ user, companies, onUpdateUser, isMobile }) => {
  const { updateDocument, response } = useFirestore("users")

  const [role, setRole] = useState(null)
  const [userCompaniesList, setUserCompaniesList] = useState(user.companiesList || null)

  const handleCompaniesChange = async (selectedCompanies, user) => {
    const updatedUser = {
      ...user,
      companiesList: selectedCompanies,
    }
    onUpdateUser(updatedUser)
    await updateDocument(user.uid, { companiesList: selectedCompanies })
  }
  useEffect(() => {
    if (user && user.companiesList) {
      setUserCompaniesList(user.companiesList)
    }
  }, [user])

  const handlePermissionChange = async (permission) => {
    if (user && user.companiesList) {
      const updatedCompaniesList = user.companiesList.map(company => {
        if (company.id === permission.companyId) {
          const updatedModules = company.modules.map(module => 
            module.id === permission.module.id ? permission.module : module
          )
          return { ...company, modules: updatedModules }
        }
        return company
      })
  
      const updatedUser = { ...user, companiesList: updatedCompaniesList }
      await updateDocument(user.uid, { companiesList: updatedUser.companiesList })
  
      // Update the local state to reflect the changes
      setUserCompaniesList(updatedCompaniesList)
  
      // Optionally, you can also update the user state or call onUpdateUser with the updated user
      onUpdateUser(updatedUser)
    }
  }
  
  
  

  const handleRoleChange = (e) => {
    setRole(e)
  }

  useEffect(() => {
    setUserCompaniesList(user.companiesList)
  }, [user])


  return (
    <Container
      sx={{ marginTop: !isMobile ? 20 : 5, marginBottom: !isMobile ? 20 : 5 }}
    >
      <h2 className="page-title">Editar Usuario</h2>
      <Box sx={{ marginBottom: 2, border: "1px solid #dddddd" }}>
        <CardHeader
          avatar={<Avatar src={user.photoURL} />}
          title={user.displayName}
        ></CardHeader>
        <CardContent>
          <label>
            Compañias asignadas
            <Select
              isMulti
              options={companies}
              value={userCompaniesList}
              onChange={(selectedCompanies) =>
                handleCompaniesChange(selectedCompanies, user)
              }
            />
          </label>
          <label>Permisos por empresa:</label>
          {userCompaniesList.map((userCompany) => (
            <Box sx={{ marginTop: 5 }} key={userCompany?.id}>
              <h1>{userCompany?.label}</h1>
              <label>
                Rol en la empresa:
                <Select
                  options={roles}
                  value={role}
                  onChange={handleRoleChange}
                />
              </label>
              <List dense="dense" sx={{ padding: 0 }}>
                {userCompany?.modules.map((module) => (
                    <SecuritySelector
                      module={module}
                      companyId={userCompany.id}
                      onPermissionChange={handlePermissionChange}
                      isMobile={isMobile}
                    />
                  ))}
              </List>
            </Box>
          ))}
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <Button variant="contained" onClick={() => onUpdateUser(null)}>
              Salir
            </Button>
          </Box>
        </CardContent>
      </Box>
    </Container>
  )
}

export default EditUser
