/**
 * useCustomerList Hook
 *
 * This custom hook is designed for fetching and organizing data related to companies and their clients
 * from a Firestore database. It manages data for user-assigned companies, customer companies in the database,
 * customer companies related to a selected team, and clients/agents corresponding to a selected customer company.
 *
 * Parameters:
 * - selectedCompany (object|null, optional): The team currently selected by the user.
 * - customerCompany (object|null, optional): The customer company currently selected.
 *
 * Returns:
 * - eDB (array): Teams or companies which the current user is assigned (eDB)
 * - customerCompanies (array): Get all the customers that are in the database
 * - companyCustomers (array): Array of customer companies related to the selected team.
 * - companyAgents (array): Array of customers/agents corresponding to the currently selected customer company.
 *
 * Usage:
 * ```javascript
 * const { customerCompanies, eDB, companyCustomers, companyAgents } = useCustomerList(selectedCompany, customerCompany)
 * ```
 *
 * The hook uses `useCollection` to fetch data from "empresas", "empresasClientes", and "clientes" collections.
 * It also uses `useUserRoleAndSecurity` to filter companies based on the user's role and access rights.
 * The hook provides filtered and mapped data to facilitate displaying relevant information in user interfaces.
 */

import { useState, useEffect } from "react"
import { useCollection } from "../useCollection"
import { useUserRoleAndSecurity } from "../useUserRoleAndSecurity"

export const useCustomerList = (
  selectedCompany = null,
  customerCompany = null
) => {
  //Empresas a las que está asignado el usuario
  const { documents: empresas, response: empresasResponse } =
    useCollection("empresas")
  //Empresas de clientes en la base de datos
  const { documents: empresasClientesDB, response: empresasClientesResponse } =
    useCollection("empresasClientes")
  //Clientes en la base de datos
  const { documents: clientes, response: clientesResponse } =
    useCollection("clientes")
  const { currentCompanyList, errorDoc } = useUserRoleAndSecurity()
  //Empresas desglosadas a las que pertenece el usuario
  const [eDB, setEDB] = useState(null)
  //Empresas de clientes a las cuales tiene acceso el usuario
  const [customerCompanies, setCustomerCompanies] = useState([])
  //Empresas de clientes correspondientes a empresa(equipo) actual
  const [companyCustomers, setCompanyCustomers] = useState([])
  // Clients corresponding to the currently selected customer Company
  const [companyAgents, setCompanyAgents] = useState([])

  //Teams or companies which the current user is assigned (eDB)
  useEffect(() => {
    if (empresas && currentCompanyList) {
      const filteredEmpresas = empresas
        .filter((empresa) =>
          currentCompanyList.map((company) => company.id).includes(empresa.id)
        )
        .map((empresa) => ({
          id: empresa.id,
          name: empresa.name,
          value: empresa.value,
          code: empresa.code,
          label: empresa.label,
          color: empresa.color,
          color2: empresa.color2,
          photoURL: empresa.photoURL,
          address: empresa.address,
          website: empresa.website,
          companyEmail: empresa.companyEmail,
          companyPhone: empresa.companyPhone,
          companyWhatsapp: empresa.companyWhatsapp,
          privacyPolicy: empresa.privacyPolicy,
        }))

      setEDB(filteredEmpresas)
    } else {
      console.error("Error al recuperar empresas: ", errorDoc, empresasResponse)
    }
  }, [empresas, currentCompanyList, errorDoc, empresasResponse])

  //Get all the customers that are in the database
  useEffect(() => {
    if (empresasClientesDB && eDB) {
      const filteredEmpresas = empresasClientesDB.filter((empresa) =>
        eDB.some((e) => e.id === empresa.owner)
      )

      const e = filteredEmpresas.map((empresa) => {
        return {
          id: empresa.id,
          label: empresa.label,
          value: empresa.id,
          name: empresa.name,
          owner: empresa.owner,
          ownerPhoto: empresa.ownerPhoto,
          createdBy: empresa.createdBy,
        }
      })

      setCustomerCompanies(e)
      console.log("Customer companies: ", e)
    }
  }, [empresasClientesDB, eDB, empresasClientesResponse])

  //Array of customer companies related to the selected team.
  useEffect(() => {
    if (selectedCompany) {
      const outputCompanies = customerCompanies.filter(
        (e) => e.owner === selectedCompany.id
      )
      setCompanyCustomers(outputCompanies)
    }
  }, [selectedCompany, customerCompanies])

  //Array of customers/agents corresponding to the currently selected customer company.
  useEffect(() => {
    if (customerCompany && clientes) {
      const filteredClients = clientes.filter(
        (cliente) => cliente.empresaCliente === customerCompany.id
      )

      // Map filteredClients to add 'value' and 'label' properties
      const clientsWithProperties = filteredClients.map((cliente) => ({
        value: cliente.id,
        label: cliente.nombreCliente,
        ...cliente, // Include all existing properties if needed
      }))

      console.log("Filtered clients with properties: ", clientsWithProperties)

      setCompanyAgents(clientsWithProperties)
    }
  }, [customerCompany, clientes])

  return { customerCompanies, eDB, companyCustomers, companyAgents }
}
