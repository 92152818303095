export const availableColumns = [
    // { value: 'productCode', label: 'Codigo de Producto' },
    // { value: 'name', label: 'Nombre' },
    { value: "ancho", label: "Ancho" },
    { value: "largo", label: "Largo" },
    { value: "peso", label: "Peso" },
    { value: "largoRollo", label: "Largo del Rollo" },
    { value: "espesorMM", label: "Espesor en mm" },
    { value: "espesorIn", label: "Espesor en In" },
    { value: "espesorIn", label: "Espesor en In" },
    { value: "precioHPKMetro", label: "Precio por metro Hulempak" },
    { value: "precioPzHPK", label: "Precio a Hulempak por Pieza" },
    {
      value: "precioFilialesMetro",
      label: "Precio por metro Filiales por Metro",
    },
    {
      value: "precioFilialesRollo",
      label: "Precio por metro Filiales por Rollo",
    },
    { value: "precioPzFiliales", label: "Precio a filiales por Pieza" },
    {
      value: "precioMLMetro",
      label: "Precio por metro Mercado Libre por Metro",
    },
    {
      value: "precioMLRollo",
      label: "Precio por metro Mercado Libre por Rollo",
    },
    { value: "precioPzML", label: "Precio a Mercado Libre por Pieza" },
    {
      value: "precioPublicoMetro",
      label: "Precio por metro al publico por Metro",
    },
    {
      value: "precioPublicoRollo",
      label: "Precio por metro al publico por Rollo",
    },
    {
      value: "precioPublico1Rollo",
      label: "Precio por metro al publico por 1 Rollo",
    },
    {
      value: "precioPublico24Rollo",
      label: "Precio por metro al publico por 2-4 Rollos",
    },
    {
      value: "precioPublico5masRollo",
      label: "Precio por metro al publico por 5 más Rollo",
    },
    { value: "precioPz", label: "Precio a al publico por Pieza" },
  ]
