import { Box, Button, Container } from "@mui/material"
import React, { useEffect, useState } from "react"
import axios from "axios"
import { projectFunctions, timestamp } from "../../firebase/config"

const Tests = ({ userData }) => {
  const [selectData, setSelectData] = useState([])

  useEffect(() => {
    let processing = true
    axiosFetchData(processing)
    return () => {
      processing = false
    }
  }, [])
  const axiosFetchData = async (processing) => {
    await axios
      .get("http://localhost:4000/users")
      .then((res) => {
        if (processing) {
          setSelectData(res.data)
        }
      })
      .catch((err) => console.log(err))
  }
  const handleLog = () => { 
    console.log("log creation triggered")
    const addLog = projectFunctions.httpsCallable("addLog")
    addLog({
      action: "Creación",
      module: "Tests",
      element: "Log",
      message: "Prueba",
      user: { id: userData.id, name: userData.displayName },
    })
      .then((response) => {
        console.log(response.data) // Access the data property of the response
      })
      .catch((error) => {
        console.error("Error adding log:", error)
      })
  }

  return (
    <Box sx={{ marginTop: 20 }}>
      <h1>Tests</h1>
      <Container>
        {selectData?.map((u) => (
          <p>{u.lastName}</p>
        ))}
        <Button variant="contained" onClick={handleLog}>
          Ejecutar log
        </Button>
      </Container>
    </Box>
  )
}

export default Tests
