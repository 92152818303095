import { Box, Grid, Typography } from "@mui/material"
import SummaryCard from "../../components/summaryCard/SummaryCard"
import React from "react"
import { priorityData } from "./StagesData"

const PriorityLayout = ({ objects, users, type }) => {
  // Check if objects array is defined and not empty
  if (!objects || objects.length === 0) {
    return <div>No objects to display</div>
  }

  // Reverse the priorityData array
  const reversedPriorityData = [...priorityData].reverse()

  const objectsByPriority = {
    urgent: [],
    high: [],
    medium: [],
    low: [],
    nr: [],
  }

  // Group objects by priority
  objects.forEach((object) => {
    if (object.priority && object.priority.value in objectsByPriority) {
      objectsByPriority[object.priority.value].push(object)
    } else {
      console.warn(`Invalid or missing priority: ${object.priority?.value}`)
    }
  })

  function sortByDueDate(arr) {
    arr.sort((a, b) => a.dueDate - b.dueDate)
  }

  // Sort each array within objectsByPriority by dueDate
  for (const priority in objectsByPriority) {
    if (objectsByPriority.hasOwnProperty(priority)) {
      sortByDueDate(objectsByPriority[priority])
    }
  }

  return (
    <Box sx={{ marginTop: 5 }}>
      <Grid container spacing={2}>
        {reversedPriorityData.map((priority, index) => (
          <Grid item xs={12} md={2} key={index}>
            <p style={{ color: priority.color }}>{priority.label}</p>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        {Object.entries(objectsByPriority).map(([priority, objects]) => (
          <Grid item xs={12} md={2} key={priority}>
            <Grid container spacing={2}>
              {objects?.map((object) => (
                <Grid item key={object.id}>
                  <SummaryCard object={object} users={users} type={type} />
                </Grid>
              ))}
              {objects.length === 0 && (
                <Grid item>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>Good Job ☺!</Typography>
                </Grid>
              )}
              {/* Display "Good job" if the priority array is empty */}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default PriorityLayout
