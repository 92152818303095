import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useCollection } from "../../hooks/useCollection"
import { Box } from "@mui/material"
import CustomButton from "../../components/tables/CustomButton"

const CustomerQuotes = ({
  customer,
  isMobile,
  onTotalCotChange,
  onCountCotChange,
}) => {
  const { documents, error } = useCollection("cotizaciones")
  const [sortState, setSortState] = useState({
    column: null,
    direction: "asc",
  })

  const [quotes, setQuotes] = useState(null)

  //callback functions to parent
  const handleCount = () => {
    const quotesCount = quotes ? quotes.length : 0
    if (onCountCotChange) {
      onCountCotChange(quotesCount)
    }
  }

  const handleTotal = () => {
    if (quotes && quotes.length > 0) {
      const sumTotal = quotes.reduce((acc, quote) => acc + quote.total, 0)
      if (onTotalCotChange) {
        onTotalCotChange(sumTotal)
      }
    } else {
      if (onTotalCotChange) {
        onTotalCotChange(0)
      }
    }
  }

  useEffect(() => {
    if (documents) {
      const filtered = documents.filter((p) => p.clienteId === customer?.id)

      const fil = filtered.map((p) => ({
        id: p.id,
        cotizacion: p.cotizacion,
        date: p.fecha,
        total: p.totalCost,
      }))

      if (fil.length > 0) {
        setQuotes(fil)
      }
    }
    if (error) {
      console.log("Error while mapping the quotes: ", error)
    }
  }, [documents, error, customer])

  //Totals

  useEffect(() => {
    if (quotes) {
      handleCount()
      handleTotal()
    }
  }, [quotes])

  const handleSort = (property) => {
    const isSameColumn = sortState.column === property
    const direction = isSameColumn
      ? sortState.direction === "asc"
        ? "desc"
        : "asc"
      : "asc"

    const sortedList = [...quotes].sort((a, b) => {
      const valueA = getProperty(a, property)
      const valueB = getProperty(b, property)

      if (valueA < valueB) return direction === "asc" ? -1 : 1
      if (valueA > valueB) return direction === "asc" ? 1 : -1
      return 0
    })

    setQuotes(sortedList)
    setSortState({ column: property, direction })
  }
  const getProperty = (object, propertyPath) => {
    const parts = propertyPath.split(".")
    let value = object
    for (const part of parts) {
      value = value ? value[part] : null
    }
    return value
  }

  return (
    <Box sx={{ maxWidth: "100%", overflowX: "scroll" }}>
      {!quotes && <p>No existen cotizaciones creadas por ti</p>}
      {quotes && (
        <table className="styled-table" style={{ width: "100%" }}>
          <thead style={{ background: "#14213d", color: "white" }}>
            <tr>
              <td
                style={{ width: isMobile ? "300px" : "auto" }}
                onClick={() => handleSort("cotizacion")}
              >
                <CustomButton>Número</CustomButton>
              </td>

              <td style={{ width: "auto" }} onClick={() => handleSort("date")}>
                <CustomButton>Fecha</CustomButton>
              </td>

              <td style={{ width: "auto" }} onClick={() => handleSort("total")}>
                <CustomButton>Total</CustomButton>
              </td>
            </tr>
          </thead>
          <tbody>
            {quotes.map((q) => (
              <tr key={q.id}>
                <td style={{ padding: "5px", fontWeight: "bold" }}>
                  <Link to={`/cotizaciones/${q.id}`}>{q.cotizacion}</Link>
                </td>
                <td style={{ padding: "5px" }}>{q.date}</td>
                <td style={{ padding: "5px" }}>
                  ${q.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Box>
  )
}

export default CustomerQuotes
