import { useState, React } from "react"
import { useParams } from "react-router-dom"
import { useDocument } from "../../hooks/useDocument"
import { Link } from "react-router-dom"
import { Clearance } from "../../components/tools/Clearance"
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined"

// styles
import "./Project.css"
import ProjectComments from "./ProjectComments"
import ProjectSummary from "./ProjectSummary"
import { Box, Button, Grid } from "@mui/material"
import EditProject from "./EditProject"

export default function Project({userData}) {
  const { id } = useParams()
  const { document, error } = useDocument("projects", id)
  const [edit, setEdit] = useState(false)

  const onEdit = (editStatus) => {
    setEdit(editStatus)
  }

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading...</div>
  }

  return (
    <>
      <Box sx={{ marginTop: 15 }}>
        {!edit && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Button
                component={Link}
                exact
                to="/"
                variant="outlined"
                startIcon={<ArrowBackIosNewOutlinedIcon />}
              >
                Regresar
              </Button>
            </Grid>
            <Grid item xs={1} md={1}>
              <Button variant="outlined" onClick={() => setEdit(true)}>
                Editar{" "}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box sx={{ marginTop: !edit ? 5 : 20 }}>
        <Grid container spacing={2}>
          {!edit && (
            <>
              <Grid item xs={12} md={8}>
                <ProjectSummary project={document} userData={userData}/>
              </Grid>
              <Grid item xs={12} md={4}>
                <ProjectComments project={document} userData={userData}/>
              </Grid>
            </>
          )}
          {edit && (
            <Grid container spacing={2}>
              <EditProject project={document} onEdit={onEdit} userData={userData}/>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}
