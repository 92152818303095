import React, { useState } from "react"
import { Clearance } from "../../components/tools/Clearance"

import { Box, Container } from "@mui/material"

import CompaniesSummary from "./CompaniesSummary"
import EditCompany from "./EditCompany"
import Error404 from "../error404/Error404"

const CompanyDashboard = ({isMobile}) => {
  const [edit, setEdit] = useState(false)
  const [empresaEditada, setEmpresaEditada] = useState("")

  const clearance = Clearance(1, 1)

  const onEdit = (e, empresa) => {
    setEdit(e)
    setEmpresaEditada(empresa)
  }
  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Container sx={{ marginTop: !isMobile ? 20 : 5 ,marginBottom: !isMobile ? 20 : 5, padding:2}}>
          <h2 className="page-title">Companies Dashboard</h2>
          {!edit && <CompaniesSummary onEdit={onEdit} />}
          {edit && (
            <EditCompany empresaEditada={empresaEditada} onEdit={onEdit} isMobile={isMobile}/>
          )}
        </Container>
      )}
    </>
  )
}

export default CompanyDashboard
