import { React, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Box, Chip } from "@mui/material"
import { useCollection } from "../../hooks/useCollection"
import CustomButton from "../../components/tables/CustomButton"

const ProjectsByUser = ({ user, isMobile }) => {
  //Getting documents for user
  const { error, documents } = useCollection("projects")
  const [projects, setProjects] = useState(null)
  const [sortState, setSortState] = useState({
    column: null,
    direction: "asc",
  })

  useEffect(() => {
    if (documents) {
      const filtered = documents.filter((p) => {
        // Check if any assigned user's id matches user.uid
        const assignedUserMatch = p.assignedUsersList.some(
          (u) => u.id === user.id
        )

        // Check if the stage.value is not equal to "backlog"
        const stageNotBacklog = p.stage.value !== "backlog"

        // Apply OR conditional between assignedUserMatch and stageNotBacklog
        return assignedUserMatch && stageNotBacklog
      })

      const proj = filtered.map((p) => ({
        id: p.id,
        name: p.name,
        stage: p.stage,
        priority: p.priority,
        date: p.dueDate,
      }))

      if (filtered.length > 0) {
        setProjects(proj)
      }
    }
    if (error) {
      console.log("Error while mapping the projects: ", error)
    }
  }, [documents, error, user])

  const handleSort = (property) => {
    // Check if the same column is clicked again to toggle the sorting direction
    const isSameColumn = sortState.column === property
    const direction = isSameColumn
      ? sortState.direction === "asc"
        ? "desc"
        : "asc"
      : "asc"

    // Sort the projectData based on the selected property and direction
    const sortedList = [...projects].sort((a, b) => {
      if (property === "date") {
        // Special case for date sorting
        const dateA = a[property] ? a[property].toDate() : null
        const dateB = b[property] ? b[property].toDate() : null

        if (!dateA && !dateB) return 0
        if (!dateA) return direction === "asc" ? 1 : -1
        if (!dateB) return direction === "asc" ? -1 : 1

        return direction === "asc" ? dateA - dateB : dateB - dateA
      } else {
        // Default sorting for other columns
        const valueA = getProperty(a, property)
        const valueB = getProperty(b, property)

        if (valueA < valueB) return direction === "asc" ? -1 : 1
        if (valueA > valueB) return direction === "asc" ? 1 : -1
        return 0
      }
    })

    // Update the state with the sorted list and sorting state
    setProjects(sortedList)
    setSortState({ column: property, direction })
  }
  const getProperty = (object, propertyPath) => {
    const parts = propertyPath.split(".")
    let value = object
    for (const part of parts) {
      value = value ? value[part] : null
    }
    return value
  }

  return (
    <Box sx={{ maxWidth: "100%", overflowX: "scroll" }}>
      {!projects && <p>No existen proyectos asignados a ti</p>}
      {projects && (
        <table className="styled-table" style={{ width: "100%" }}>
          <thead>
            <tr style={{ padding: "20px" }}>
              <td
                onClick={() => handleSort("name")}
                style={{ width: isMobile ? "300px" : "auto" }}
              >
                <CustomButton>Nombre</CustomButton>
              </td>
              <td
                onClick={() => handleSort("priority.label")}
                style={{ width: "150px" }}
              >
                <CustomButton>Prioridad</CustomButton>
              </td>

              <td
                onClick={() => handleSort("stage.label")}
                style={{ width: "150px" }}
              >
                <CustomButton>Etapa</CustomButton>
              </td>
              <td
                onClick={() => handleSort("date")}
                style={{ width: "150px" }}
              >
                <CustomButton>Due Date</CustomButton>
              </td>
            </tr>
          </thead>
          <tbody>
            {projects.map((p) => (
              <tr key={p.id}>
                <td style={{ padding: "5px" }}>
                  <Link to={`/projects/${p.id}`}>{p.name}</Link>
                </td>
                <td style={{ padding: "5px" }}>
                  <Chip
                    label={p.priority ? p.priority.label : "No definida"}
                    sx={{
                      background: p.priority ? p.priority.color : "#000",
                      color: "white",
                    }}
                  />
                </td>
                <td style={{ padding: "5px" }}>
                  <Chip
                    label={p.stage ? p.stage.label : "No definida"}
                    sx={{
                      background: p.stage ? p.stage.color : "#000",
                      color: "white",
                    }}
                  />
                </td>
                <td style={{ padding: "5px" }}>{p.date.toDate().toLocaleDateString("es-mx")}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Box>
  )
}

export default ProjectsByUser
