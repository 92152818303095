import { React, forwardRef, useState } from "react"
import { NavLink as NavLinkBase } from "react-router-dom"
import { Clearance } from "../tools/Clearance"
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"

//MUI ICONS
import { ExpandLess } from "@material-ui/icons"
import { ExpandMore } from "@material-ui/icons"
import SearchIcon from "@mui/icons-material/Search"
import AddIcon from "@mui/icons-material/Add"
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined"

const PDClientsModule = ({ handleDrawerClose }) => {
  const NavLink = forwardRef((props, ref) => (
    <NavLinkBase ref={ref} {...props} className={props.activeClassName} />
  ))
  const [open, setOpen] = useState(false)
  const clearance = Clearance(4,4)

  const handleOpen = () => {
    setOpen(!open)
  }
  return (
    <ListItem
      key={"users"}
      disablePadding
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <ListItemButton onClick={handleOpen} sx={{ width: "100%" }}>
        <ListItemIcon>
          <PersonPinOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Clientes" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">
          {clearance && (
            <ListItem
              key={"ver-usuarios"}
              component={NavLink}
              to="/customers-dashboard"
              onClick={() => {
                handleDrawerClose()
                handleOpen()
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText secondary="Ver Clientes" />
              </ListItemButton>
            </ListItem>
          )}
          {clearance && (
            <ListItem
              key={"ver-roles"}
              component={NavLink}
              to="/create-customer"
              onClick={() => {
                handleDrawerClose()
                handleOpen()
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText secondary="Crear Cliente" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Collapse>
    </ListItem>
  )
}

export default PDClientsModule
