import { React, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Box, Chip } from "@mui/material"
import { useCollection } from "../../hooks/useCollection"
import CustomButton from "../../components/tables/CustomButton"

const ShipmentsByUser = ({ user, isMobile }) => {
  const { error, documents } = useCollection("shipments")
  const [shipments, setShipments] = useState(null)

  const [sortState, setSortState] = useState({
    column: null,
    direction: "asc",
  })

  useEffect(() => {
    if (documents) {
      const filtered = documents.filter((p) =>
        p.assignedUsersList.some((u) => u.id === user.id)
      )

      const fil = filtered.map((p) => ({
        id: p.id,
        name: p.name,
        supplier: p.supplier,
        stage: p.category,
        date: p.dueDate.toDate().toLocaleDateString("es-mx"),
      }))

      if (filtered.length > 0) {
        setShipments(fil)
      }
    }
    if (error) {
      console.log("Error while mapping the shipments: ", error)
    }
  }, [documents, error, user.id])

  const handleSort = (property) => {
    const isSameColumn = sortState.column === property
    const direction = isSameColumn
      ? sortState.direction === "asc"
        ? "desc"
        : "asc"
      : "asc"

    const sortedList = [...shipments].sort((a, b) => {
      const valueA = getProperty(a, property)
      const valueB = getProperty(b, property)

      if (valueA < valueB) return direction === "asc" ? -1 : 1
      if (valueA > valueB) return direction === "asc" ? 1 : -1
      return 0
    })

    setShipments(sortedList)
    setSortState({ column: property, direction })
  }
  const getProperty = (object, propertyPath) => {
    const parts = propertyPath.split(".")
    let value = object
    for (const part of parts) {
      value = value ? value[part] : null
    }
    return value
  }

  return (
    <Box sx={{ maxWidth: "100%", overflowX: "scroll" }}>
      {!shipments && <p>No existen importaciones asignadas a ti</p>}
      {shipments && (
        <table className="styled-table" style={{ width: "100%" }}>
          <thead style={{ background: "#14213d", color: "white" }}>
            <tr>
              <td
                style={{ width: isMobile ? "300px" : "auto" }}
                onClick={() => handleSort("name")}
              >
                <CustomButton>Nombre</CustomButton>
              </td>
              <td
                style={{ width: "150px" }}
                onClick={() => handleSort("supplier.label")}
              >
                <CustomButton>Proveedor</CustomButton>
              </td>
              <td
                style={{ width: "150px" }}
                onClick={() => handleSort("stage.label")}
              >
                <CustomButton>Etapa</CustomButton>
              </td>
              <td style={{ width: "150px" }} onClick={() => handleSort("date")}>
                <CustomButton>ETA</CustomButton>
              </td>
            </tr>
          </thead>
          <tbody>
            {shipments.map((s) => (
              <tr key={s.id}>
                <td style={{ padding: "5px" }}>
                  <Link to={`/shipments/${s.id}`}>{s.name}</Link>
                </td>
                <td style={{ padding: "5px" }}>{s.supplier.label}</td>
                <td style={{ padding: "5px" }}>
                  <Chip
                    label={s.stage ? s.stage.label : "No definida"}
                    sx={{
                      background: s.stage ? s.stage.color : "#000",
                      color: "white",
                    }}
                  />
                </td>
                <td style={{ padding: "5px" }}>{s.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Box>
  )
}

export default ShipmentsByUser
