import { React, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useDocument } from "../../hooks/useDocument"
import { useCollection } from "../../hooks/useCollection"
import AddIcon from "@mui/icons-material/Add"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
} from "@mui/material"
import CreateSubWarehouse from "./CreateSubWarehouse"
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined"

const Warehouse = ({ isMobile }) => {
  const { id } = useParams()
  const { document, error } = useDocument("warehouses", id)
  const [subwarehouses, setSubwarehouses] = useState(null)
  const { documents: subWarehousedoc, error: subWarehouseerror } =
    useCollection("subwarehouses")

  const [onEdit, setOnEdit] = useState(false)

  const handleOnEdit = (e) => {
    setOnEdit(e)
  }

  useEffect(() => {
    if (subWarehousedoc) {
      const filteredSubwarehouses = subWarehousedoc
        .filter((e) => e.warehouse[0].id === document.id)
        .map((e) => ({
          id: e.id,
          company: e.company,
          label: e.label,
          value: e.value,
          warehouse: e.warehouse,
        }))

      setSubwarehouses(filteredSubwarehouses)
    } else {
      console.log("No subwarehouses available: ", subWarehouseerror)
    }
  }, [subWarehousedoc, subWarehouseerror, document])

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading...</div>
  }
  return (
    <Box sx={{ marginTop: !isMobile ? 20 : 5 }}>
      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        <Button
          component={Link}
          to="/almacenes-dashboard"
          variant="outlined"
          startIcon={<ArrowBackIosNewOutlinedIcon />}
        >
          Regresar
        </Button>
      </Box>
      <p style={{ fontSize: "12px" }}>{document?.company.label}</p>
      <h1>{document?.label}</h1>
      {!onEdit && (
        <Box sx={{ marginTop: 5 }}>
          <Grid container spacing={2} sx={{ marginTop: 5 }}>
            <Grid item xs={12} md={3}>
              <Card
                onClick={() => setOnEdit(true)}
                sx={{
                  minWidth: 275,
                  background: "rgba(20,33,61,1)",
                  color: "white",
                }}
              >
                <CardContent>
                  <p style={{ fontSize: "12px" }}>{document.label}</p>
                  <h2 className="">Nuevo Subalmacén</h2>
                </CardContent>
                <CardActions>
                  <Button variant="contained" endIcon={<AddIcon />}>
                    Crear Subalmacén
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            {subwarehouses &&
              subwarehouses.map((sw) => (
                <Grid item xs={12} md={3}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <p style={{ fontSize: "12px" }}>
                        {sw.warehouse[0].label}
                      </p>
                      <h2 className="">{sw.label}</h2>
                    </CardContent>
                    <CardActions>
                      <Link to={`/subalmacenes/${sw.id}`} key={sw.id}>
                        <Button variant="outlined">Ver Almacén</Button>
                      </Link>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
          </Grid>
        </Box>
      )}
      {onEdit && (
        <CreateSubWarehouse
          handleOnEdit={handleOnEdit}
          empresa={document?.company}
          almacen={document ? document : null}
        />
      )}
    </Box>
  )
}

export default Warehouse
