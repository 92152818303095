import React, { useEffect, useState } from "react"
import { useFirestore } from "../../hooks/useFirestore"
import { useCollection } from "../../hooks/useCollection"
import { projectStorage } from "../../firebase/config"

import { HexColorPicker } from "react-colorful"
import Select from "react-select"

import { Avatar, Box, Button, List } from "@mui/material"
import SuccessModal from "../../components/SuccessModal"
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined"
import LiItem from "./LiItem"

const EditCompany = ({ empresaEditada, onEdit, isMobile }) => {
  const { updateDocument, response } = useFirestore("empresas")
  const { error: errorLines, documents: linesDocuments } =
    useCollection("productLines")
  const { error: errorModules, documents: modulesDocuments } =
    useCollection("modules")

  const [thumbnailError, setThumbnailError] = useState(null)
  const [imageChanged, setImageChanged] = useState(false)
  const [color, setColor] = useState(empresaEditada.color)
  const [color2, setColor2] = useState(empresaEditada.color2)
  const [avatar, setAvatar] = useState(empresaEditada.photoURL || null)
  const [name, setName] = useState(empresaEditada.label)
  const [address, setAddress] = useState(empresaEditada.address)
  const [website, setWebsite] = useState(empresaEditada.website)
  const [privacyPolicy, setPrivacyPolicy] = useState(
    empresaEditada.privacyPolicy
  )
  const [companyEmail, setCompanyEmail] = useState(empresaEditada.companyEmail)
  const [companyPhone, setCompanyPhone] = useState(empresaEditada.companyPhone)
  const [companyWhatsapp, setCompanyWhatsapp] = useState(
    empresaEditada.companyWhatsapp
  )
  //DB info
  const [productLines, setProductLines] = useState(null)
  const [companyProductLines, setCompanyProductLines] = useState(
    empresaEditada.productLines
  )
  const [modules, setModules] = useState(null)
  const [companyModules, setCompanyModules] = useState(empresaEditada.modules)

  useEffect(() => {
    if (linesDocuments) {
      const lineCollection = linesDocuments.map((line) => {
        return { id: line.id, value: line.value, label: line.label }
      })
      setProductLines(lineCollection)
    } else {
      console.log("No product lines available: ", errorLines)
    }
  }, [linesDocuments, errorLines])

  useEffect(() => {
    if (modulesDocuments) {
      const modulesCollection = modulesDocuments.map((mod) => {
        return { id: mod.id, value: mod.value, label: mod.label }
      })
      setModules(modulesCollection)
    } else {
      console.log("No modules avaiable: ", errorModules)
    }
  }, [modulesDocuments, errorModules])

  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleColor = (e) => {
    setColor(e)
  }
  const handleColor2 = (e) => {
    setColor2(e)
  }
  const handleAddressChange = (e) => {
    setAddress(e.target.value)
  }
  const handleWebsiteChange = (e) => {
    setWebsite(e.target.value)
  }
  const handlePrivacyPolicyChange = (e) => {
    setPrivacyPolicy(e.target.value)
  }
  const handleCompanyEmailChange = (e) => {
    setCompanyEmail(e.target.value)
  }
  const handleCompanyPhoneChange = (e) => {
    setCompanyPhone(e.target.value)
  }
  const handleCompanyWhatsappChange = (e) => {
    setCompanyWhatsapp(e.target.value)
  }
  const handleFileChange = (e) => {
    setAvatar(null)
    let selected = e.target.files[0]

    if (!selected) {
      setThumbnailError("Please select a file")
      return
    }

    if (!selected.type.includes("image")) {
      setThumbnailError("Selected file must be an image")
      return
    }

    if (selected.size > 600000) {
      setThumbnailError("Image file size must be less than 600kb")
      return
    }

    setImageChanged(true)
    setThumbnailError(null)
    setAvatar(selected)
    console.log("avatar", avatar)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    //missing data
    if (empresaEditada) {
      if (
        !name ||
        !color ||
        !color2 ||
        !address ||
        !website ||
        !companyEmail ||
        !companyPhone ||
        !companyWhatsapp ||
        !privacyPolicy
      ) {
        setShowModal(true)
        return
      }

      //Profile pic changed

      if (imageChanged) {
        const uploadPath = `thumbnails/${empresaEditada.id}/${avatar.name}`
        const img = await projectStorage.ref(uploadPath).put(avatar)
        const imgUrl = await img.ref.getDownloadURL()

        //await updateProfile({ displayName: name, photoURL: imgUrl })

        await updateDocument(empresaEditada.id, {
          label: name,
          name: name,
          photoURL: imgUrl,
          color: color,
          color2: color2,
          address: address,
          website: website,
          companyEmail: companyEmail,
          companyPhone: companyPhone,
          companyWhatsapp: companyWhatsapp,
          privacyPolicy: privacyPolicy,
          modules: companyModules ? companyModules : modules[0],
          productLines: companyProductLines
            ? companyProductLines
            : productLines[0], //dummy product line failsafe
        })
        if (!response.error) {
        }
      } else {
        //await updateProfile({ displayName: name })
        await updateDocument(empresaEditada.id, {
          label: name,
          name: name,
          color: color,
          color2: color2,
          address: address,
          website: website,
          companyEmail: companyEmail,
          companyPhone: companyPhone,
          companyWhatsapp: companyWhatsapp,
          privacyPolicy: privacyPolicy,
          modules: companyModules ? companyModules : modules[0],
          productLines: companyProductLines
            ? companyProductLines
            : productLines[0],
        })
        if (!response.error) {
        }
      }

      onEdit(false, "")
    }
  }

  return (
    <Box>
      <Button
        variant="contained"
        startIcon={<ArrowBackIosNewOutlinedIcon />}
        onClick={() => onEdit(false, "")}
      >
        Regresar
      </Button>
      <List
        sx={{
          width: "100%",
          justifyContent: "flex-start",
          display: "flex",
          flexDirection: !isMobile ? "column" : "row",
          alignContent: "flex-start",
        }}
      >
        <LiItem>
          <Avatar
            alt="Remy Sharp"
            src={avatar} // Use avatar state or empresaEditada.photoURL
            sx={{ width: 100, height: 100 }}
          />
          Editar foto de la empresa
          <input type="file" onChange={handleFileChange} />
          {thumbnailError && <div className="error">{thumbnailError}</div>}
        </LiItem>
        <LiItem>
          <span>Módulos contratados</span>
          <Select
            isMulti
            onChange={(option) => setCompanyModules(option)}
            options={modules}
            placeholder="Selecciona al menos un módulo"
            value={companyModules}
          />
        </LiItem>

        <LiItem>
          <span>Líneas de producto por empresa:</span>
          <Select
            isMulti
            onChange={(option) => setCompanyProductLines(option)}
            options={productLines}
            placeholder="Selecciona al menos una línea"
            value={companyProductLines}
          />
        </LiItem>
        <LiItem>
          Editar nombre de la empresa:
          <input
            type="text"
            onChange={(e) => handleNameChange(e)}
            value={name}
          />
        </LiItem>
        <LiItem>
          Editar email:
          <input
            type="email"
            onChange={(e) => handleCompanyEmailChange(e)}
            value={companyEmail}
          />
        </LiItem>
        <LiItem>
          Editar telefono para llamadas:
          <input
            type="phone"
            onChange={(e) => handleCompanyPhoneChange(e)}
            value={companyPhone}
          />
        </LiItem>
        <LiItem>
          Editar telefono para Whatsapp:
          <input
            type="phone"
            onChange={(e) => handleCompanyWhatsappChange(e)}
            value={companyWhatsapp}
          />
        </LiItem>
        <LiItem>
          Editar sitio web:
          <input
            type="text"
            onChange={(e) => handleWebsiteChange(e)}
            value={website}
          />
        </LiItem>
        <LiItem>
          Editar Política de privacidad:
          <input
            type="text"
            onChange={(e) => handlePrivacyPolicyChange(e)}
            value={privacyPolicy}
          />
        </LiItem>
        <LiItem>
          Editar dirección física:
          <textarea
            type="text"
            onChange={(e) => handleAddressChange(e)}
            value={address}
          />
        </LiItem>
        <LiItem>
          <span>Color Primario de la Empresa:</span>
          <HexColorPicker color={color} onChange={(e) => handleColor(e)} />
          <input
            required
            type="text"
            onChange={(e) => handleColor(e)}
            value={color}
          />
        </LiItem>
        <LiItem>
          <span>Color contraste de la Empresa:</span>
          <HexColorPicker color={color2} onChange={(e) => handleColor2(e)} />
          <input
            required
            type="text"
            onChange={(e) => handleColor2(e)}
            value={color2}
          />
        </LiItem>
        <LiItem>
          <Button variant="contained" onClick={handleSubmit}>
            Guardar cambios
          </Button>
        </LiItem>
      </List>
      {showModal && (
        <SuccessModal
          title="Error en la captura"
          message="Revisa que los datos sean correctos."
          onClose={handleCloseModal}
          open={showModal}
        />
      )}
    </Box>
  )
}

export default EditCompany
