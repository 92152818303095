import { useCollection } from "../../hooks/useCollection"
import { useEffect, useState } from "react"
import { stagesData } from "./StagesData"

// components
import ObjectList from "../../components/ObjectList"
import ShipmentsFilter from "./ShipmentsFilter"
import ShipmentsProducts from "../shipments/ShipmentsProducts"

// styles
import "./ShipmentsDashboard.css"
import Section from "../../components/Section"
import { Box, Grid } from "@mui/material"

import ShipmentsChronogram from "../shipments/ShipmentsChronogram"
import ChangeView from "../../components/ChangeView"
import ShipmentsList from "./ShipmentsList"
import ChangeStage from "../../components/ChangeStage"
import { Clearance } from "../../components/tools/Clearance"
import Error404 from "../error404/Error404"

export default function ShipmentsDashboard({isMobile}) {
  //TODO: get current user info based on uid
  const { documents, error } = useCollection("shipments")
  const [filter, setFilter] = useState("Importaciones")

  const [shipments, setShipments] = useState(null)
  const [currentView, setCurrentView] = useState("list")
  const [currentStage, setCurrentStage] = useState(null)

  const handleViewChange = (newView) => {
    setCurrentView(newView)
    setCurrentStage(null)
  }

  const handleNewStage = (option) => {
    setCurrentStage(option)
  }
  const changeFilter = (newFilter) => {
    console.log("nuevo filtro: ", newFilter)
    setFilter(newFilter)
  }

  useEffect(() => {
    if (documents && currentStage !== null) {
      const filteredShipments = documents.filter(
        (document) => document.category.value === currentStage.value
      )
      setShipments(filteredShipments)
    } else if (documents && currentStage === null) {
      setShipments(documents)
    } else {
      console.log("Error retrieving shipments")
    }
  }, [documents, currentStage])

  return (
    <>
      {!Clearance(5, 4) && <Error404 />}
      {Clearance(5, 4) && (
        <Box sx={{ marginTop: !isMobile ? 20 : 5 , padding:2}}>
          <div className="responsive">
            <h2 className="page-title">Shipments Dashboard</h2>
            {error && <p className="error">{error}</p>}
            {documents && <ShipmentsFilter changeFilter={changeFilter} />}

            {filter === "Importaciones" && (
              <Grid container>
                <ChangeView
                  handleViewChange={handleViewChange}
                  currentView={currentView}
                />
                <Grid item xs={12} md={2} style={{marginTop: isMobile? "20px" : "0"}}>
                  <ChangeStage stages={stagesData} newStage={handleNewStage} />
                </Grid>
              </Grid>
            )}

            {shipments && filter === "Importaciones" && (
              <Section>
                {currentView === "grid" && (
                  <ObjectList
                    type="shipments"
                    objects={shipments}
                    sortvar="name"
                  />
                )}
                {currentView === "list" && <ShipmentsList data={shipments} isMobile={isMobile}/>}
              </Section>
            )}
            {shipments && filter === "Productos" && (
              <Section>
                <ShipmentsProducts />
              </Section>
            )}
            {shipments && filter === "Cronograma" && (
              <Section>
                <ShipmentsChronogram />
              </Section>
            )}
          </div>
        </Box>
      )}
    </>
  )
}
