import React, {useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Box } from "@mui/material"
import { useCollection } from "../../hooks/useCollection"
import CustomButton from "../../components/tables/CustomButton"

const QuotesByUser = ({ user, isMobile }) => {
  const { error, documents } = useCollection("cotizaciones")
  const [sortState, setSortState] = useState({
    column: null,
    direction: "asc",
  })

  const [quotes, setQuotes] = useState(null)

  useEffect(() => {
    if (documents) {
      const filtered = documents.filter((p) => p.createdBy === user.id)

      const fil = filtered.map((p) => ({
        id: p.id,
        cotizacion: p.cotizacion,
        empresa: p.empresa,
        empresaCliente: p.empresaCliente ? p.empresaCliente : "",
        nombreCliente: p.nombreCliente ? p.nombreCliente : "",
        total: p.totalCost,
      }))

      if (fil.length > 0) {
        setQuotes(fil)
      }
    }
    if (error) {
      console.log("Error while mapping the quotes: ", error)
    }
  }, [documents, error, user])

  const handleSort = (property) => {
    const isSameColumn = sortState.column === property
    const direction = isSameColumn
      ? sortState.direction === "asc"
        ? "desc"
        : "asc"
      : "asc"

    const sortedList = [...quotes].sort((a, b) => {
      const valueA = getProperty(a, property)
      const valueB = getProperty(b, property)

      if (valueA < valueB) return direction === "asc" ? -1 : 1
      if (valueA > valueB) return direction === "asc" ? 1 : -1
      return 0
    })

    setQuotes(sortedList)
    setSortState({ column: property, direction })
  }
  const getProperty = (object, propertyPath) => {
    const parts = propertyPath.split(".")
    let value = object
    for (const part of parts) {
      value = value ? value[part] : null
    }
    return value
  }

  return (
    <Box sx={{ maxWidth: "100%", overflowX: "scroll" }}>
      {!quotes && <p>No existen cotizaciones creadas por ti</p>}
      {quotes && (
        <table className="styled-table" style={{ width: "100%" }}>
          <thead style={{ background: "#14213d", color: "white" }}>
            <tr>
              <td
                style={{ width: isMobile ? "300px" : "auto" }}
                onClick={() => handleSort("cotizacion")}
              >
                <CustomButton>Número</CustomButton>
              </td>
              <td
                style={{ width: "150px" }}
                onClick={() => handleSort("empresa.label")}
              >
                <CustomButton>Empresa</CustomButton>
              </td>
              <td
                style={{ width: "150px" }}
                onClick={() => handleSort("empresaCliente")}
              >
                <CustomButton>Empresa Cliente</CustomButton>
              </td>
              <td
                style={{ width: "150px" }}
                onClick={() => handleSort("nombreCliente")}
              >
                <CustomButton>Nombre Cliente</CustomButton>
              </td>
              <td
                style={{ width: "150px" }}
                onClick={() => handleSort("total")}
              >
                <CustomButton>Total</CustomButton>
              </td>
            </tr>
          </thead>
          <tbody>
            {quotes.map((q) => (
              <tr key={q.id}>
                <td style={{ padding: "5px" }}>
                  <Link to={`/cotizaciones/${q.id}`}>{q.cotizacion}</Link>
                </td>
                <td style={{ padding: "5px" }}>{q.empresa.label}</td>
                <td style={{ padding: "5px" }}>{q.empresaCliente}</td>
                <td style={{ padding: "5px" }}>{q.nombreCliente}</td>
                <td style={{ padding: "5px" }}>
                  ${q.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Box>
  )
}

export default QuotesByUser
