import { React, forwardRef, useState } from "react"
import { NavLink as NavLinkBase } from "react-router-dom"
import { Clearance } from "../tools/Clearance"
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"

//MUI ICONS
import { ExpandLess } from "@material-ui/icons"
import { ExpandMore } from "@material-ui/icons"
import AddIcon from "@mui/icons-material/Add"
import SearchIcon from "@mui/icons-material/Search"
import DirectionsBoatOutlinedIcon from "@mui/icons-material/DirectionsBoatOutlined"

const PDShipmentsModule = ({ handleDrawerClose }) => {
  const NavLink = forwardRef((props, ref) => (
    <NavLinkBase ref={ref} {...props} className={props.activeClassName} />
  ))
  const [open, setOpen] = useState(false)
  const clearance = Clearance(2, 2)

  const handleOpen = () => {
    setOpen(!open)
  }
  return (
    <ListItem
      key={"shipments"}
      disablePadding
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <ListItemButton onClick={handleOpen} sx={{ width: "100%" }}>
        <ListItemIcon>
          <DirectionsBoatOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Importaciones" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">
          <ListItem
            key={"ver-importaciones"}
            component={NavLink}
            to="/shipments-dashboard"
            onClick={() => {
              handleDrawerClose()
              handleOpen()
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText secondary="Ver Importaciones" />
            </ListItemButton>
          </ListItem>
          {clearance && (
            <ListItem
              key={"crear-importaciones"}
              component={NavLink}
              to="/createshipments"
              onClick={() => {
                handleDrawerClose()
                handleOpen()
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText secondary="Crear Importacion" />
              </ListItemButton>
            </ListItem>
          )}
          {clearance && (
            <ListItem
              key={"crear-proveedor"}
              component={NavLink}
              to="/create-suppliers"
              onClick={() => {
                handleDrawerClose()
                handleOpen()
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText secondary="Crear Proveedor" />
              </ListItemButton>
            </ListItem>
          )}
          {clearance && (
            <ListItem
              key={"crear-puerto"}
              component={NavLink}
              to="/create-ports"
              onClick={() => {
                handleDrawerClose()
                handleOpen()
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText secondary="Crear Puerto" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Collapse>
    </ListItem>
  )
}

export default PDShipmentsModule
