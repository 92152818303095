import { useState, React } from "react"
import { useFirestore } from "../../hooks/useFirestore"
import { useUserRoleAndSecurity } from "../../hooks/useUserRoleAndSecurity"
import SuccessModal from "../../components/SuccessModal"
import { Box } from "@mui/material"

const CreateSubWarehouse = ({ isMobile, handleOnEdit, empresa, almacen }) => {
  const { addDocument, response } = useFirestore("subwarehouses")

  const [label, setName] = useState("")

  const [formError, setFormError] = useState(null)
  //modal
  const [showModal, setShowModal] = useState(false)

  const handleLabel = (e) => {
    setName(e)
  }

  const handleClose = () => {
    setShowModal(false)
    handleOnEdit(false)
  }

  function escapeAndLowerCase(inputString) {
    // Remove spaces from the inputString
    const stringWithoutSpaces = inputString.replace(/\s/g, "")

    // Escape special characters in the stringWithoutSpaces
    const escapedString = stringWithoutSpaces.replace(
      /[\\`*_{}[\]()#+\-.!]/g,
      "\\$&"
    )

    const lowercasedString = escapedString.toLowerCase()

    return lowercasedString
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setFormError(null)

    const val = escapeAndLowerCase(label)

    const port = {
      company: empresa,
      warehouse: [{ id: almacen.id, label: almacen.label }],
      label,
      value: almacen.value + "-" + val,
    }

    await addDocument(port)
    if (!response.error) {
      setShowModal(true)
    }
  }
  return (
    <Box>
      <div className="create-form">
        <h2 className="page-title">Crear Nuevo Subalmacén</h2>
        <form onSubmit={handleSubmit}>
          <label>
            <span>Nombre del SubAlmacén:</span>
            <input
              required
              type="text"
              onChange={(e) => handleLabel(e.target.value)}
              value={label}
            />
          </label>

          <button className="btn">Crear Subalmacén</button>
          {formError && <p className="error">{formError}</p>}
        </form>
        {showModal && (
          <SuccessModal
            title="Subalmacén Creado"
            message=""
            onClose={handleClose}
            open={showModal}
          />
        )}
      </div>
    </Box>
  )
}

export default CreateSubWarehouse
