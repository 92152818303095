//Menu button for navBar

import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"
import Settings from "@mui/icons-material/Settings"
import Logout from "@mui/icons-material/Logout"
import LoopIcon from "@mui/icons-material/Loop"
import ShortcutOutlinedIcon from "@mui/icons-material/ShortcutOutlined"
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined"

import BadgeAvatar from "./BadgeAvatar"
import PopUpMenu from "./PopUpMenu"

const MenuButton = ({ userData, logout, isMobile }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [popupOpen, setPopupOpen] = useState(false)
  const [activeCompany, setActiveCompany] = useState(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    //console.log("active company updated")
  }, [userData])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const togglePopup = () => {
    // Method to toggle PopUpMenu visibility
    setPopupOpen(!popupOpen)
  }

  const handleActiveCompany = (ac) => {
    setActiveCompany(ac)
  }
  const handleMultipleCompanies = () => {
    return userData && userData.companiesList.length > 1
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        {activeCompany && !isMobile && (
          <Typography sx={{ fontSize: "14px" }}>
            {activeCompany.label}
          </Typography>
        )}
        <Tooltip title="Configuraciones">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <BadgeAvatar img={userData?.photoURL} isMobile={isMobile} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        
        transformOrigin={{
          horizontal: "right",
          vertical: !isMobile ? "top" : "bottom",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: !isMobile ? "bottom" : "top",
        }}
      >
        {activeCompany && isMobile && (
          <MenuItem>
            <Typography sx={{ fontSize: "14px" }}>
              {activeCompany.label}
            </Typography>
          </MenuItem>
        )}
        <MenuItem onClick={handleClose} component={Link} to="/profile">
          <Avatar sx={{width: '32px', height:'32px' , marginRight:'5px'}} src={userData?.photoURL} /> {userData?.displayName}
        </MenuItem>
        <MenuItem onClick={handleClose} disabled>
          <ListItemIcon>
            <NotificationsActiveOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Notificaciones
        </MenuItem>
        <MenuItem onClick={handleClose} disabled>
          <ListItemIcon>
            <ShortcutOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Accesos Directos
        </MenuItem>

        {handleMultipleCompanies() && (
          <Box>
            <Divider />
            <MenuItem
              onClick={() => {
                handleClose()
                togglePopup()
              }}
            >
              <ListItemIcon>
                <LoopIcon fontSize="small" />
              </ListItemIcon>
              Cambiar Empresa Activa
            </MenuItem>
          </Box>
        )}

        <Divider />
        <MenuItem onClick={handleClose} disabled>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Configuración
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            logout()
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Cerrar Sesión
        </MenuItem>
      </Menu>
      <PopUpMenu
        open={popupOpen}
        handleOpen={togglePopup}
        userData={userData}
        currentCo={handleActiveCompany}
      />
    </>
  )
}
export default MenuButton
