import React, { useState } from "react"
import { useFirestore } from "../../hooks/useFirestore"
import { Box, Button, Grid } from "@mui/material"
import { timestamp } from "../../firebase/config"
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined"

import SuccessModal from "../../components/SuccessModal"

function formatDate(date) {
  let d = date.toDate()
  var year = d.toLocaleString("default", { year: "numeric" })
  var month = d.toLocaleString("default", { month: "2-digit" })
  var day = d.toLocaleString("default", { day: "2-digit" })
  var fecha = ""

  fecha = year + "-" + month + "-" + day

  return fecha.toString()
}

const EditProject = ({ project, onEdit }) => {
  const { updateDocument, response } = useFirestore("projects")
  const [name, setName] = useState(project.name)
  const [details, setDetails] = useState(project.details)
  const [dueDate, setDueDate] = useState(formatDate(project.dueDate))
  //modal
  const [showModal, setShowModal] = useState(false)

  const handleClose = () => {
    onEdit(false)
  }

  const handleSubmit = async (project) => {
    await updateDocument(project.id, {
      name: name,
      details: details,
      dueDate: timestamp.fromDate(new Date(dueDate)),
    })
    if (!response.error) {
      console.log("Importacion Actualizada")
      setShowModal(true)
    }
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Button
            onClick={handleClose}
            variant="outlined"
            startIcon={<ArrowBackIosNewOutlinedIcon />}
          >
            Regresar
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <label>
                <span>Nombre del proyecto:</span>
                <input
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </label>
            </Grid>
            <Grid item xs={12} md={12}>
              <label>
                <span>ETA del proyecto:</span>
                <input
                  type="date"
                  onChange={(e) => setDueDate(e.target.value)}
                  value={dueDate}
                />
              </label>
            </Grid>
            <Grid item xs={12} md={12}>
              <label>
                <span>Detalles del proyecto:</span>
                <textarea
                  type="text"
                  onChange={(e) => setDetails(e.target.value)}
                  value={details}
                />
              </label>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button variant="outlined" onClick={() => handleSubmit(project)}>
                Guardar
              </Button>
            </Grid>
            {showModal && (
              <SuccessModal
                title="Proyecto Modificada con Éxito"
                message="Revisa que los datos sean correctos a continuación"
                onClose={handleClose}
                open={showModal}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EditProject
