import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@mui/material"
import { Link } from "react-router-dom"
import { useParams } from "react-router-dom"
import { useDocument } from "../../hooks/useDocument"
import { useCollection } from "../../hooks/useCollection"
import { useFirestore } from "../../hooks/useFirestore"
import { useHistory } from "react-router-dom"
import { Clearance } from "../../components/tools/Clearance"

import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined"
import Error404 from "../error404/Error404"
import CustomerQuotes from "./CustomerQuotes"

const Customer = ({ isMobile }) => {
  const { id } = useParams()
  const history = useHistory()
  const masterClearance = Clearance(1, 1)
  const clearance = Clearance(3, 3)
  const { document, error } = useDocument("clientes", id)
  const { documents: usuarios, error: errorusuario } = useCollection("users")
  const { deleteDocument } = useFirestore("clientes")

  const [users, setUsers] = useState(null)
  const [cliente, setCliente] = useState(null)
  const [customerOwner, setCustomerOwner] = useState(null)
  const [total, setTotal] = useState("")
  const [count, setCount] = useState("")

  useEffect(() => {
    if (usuarios) {
      const usrs = usuarios.map((u) => {
        return { id: u.id, name: u.displayName }
      })
      setUsers(usrs)
    } else {
      console.log("No product lines available: ", errorusuario)
    }
  }, [usuarios, errorusuario])

  console.log("usuarsss: ", users)

  useEffect(() => {
    if (document && document.createdBy && users) {
      setCliente(document)
      const createdByUser = users.find((user) => user.id === document.createdBy)
      if (createdByUser) {
        setCustomerOwner(createdByUser.name)
      } else {
        console.error("User not found for createdBy:", document.createdBy)
      }
    }
  }, [document, users])

  const handleCotTotalChange = (newTotal) => {
    setTotal(newTotal)
  }

  const handleCotCountChange = (newCount) => {
    setCount(newCount)
  }

  const handleDelete = () => {
    deleteDocument(document.id)
    history.push("/customers-dashboard")
  }
  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading...</div>
  }
  const formatMoney = (value) => {
    const number = Number(value)
    return isNaN(number)
      ? ""
      : number.toLocaleString("es-MX", {
          style: "currency",
          currency: "MXN",
          maximumFractionDigits: "2",
        })
  }
  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Box sx={{ marginTop: !isMobile ? 20 : 5, padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Button
                component={Link}
                to="/customers-dashboard"
                variant="outlined"
                startIcon={<ArrowBackIosNewOutlinedIcon />}
              >
                Regresar
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <h1>{document.empresaClienteLabel}</h1>
              <Typography variant="body2" color="text.secondary">
                <p>Vendedor: {customerOwner}</p>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ minHeight: "200px" }}>
                <CardHeader
                  action={
                    <IconButton>
                      <EditOutlinedIcon />
                    </IconButton>
                  }
                  title={document.nombreCliente}
                ></CardHeader>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <p>Email: {document.email}</p>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <p>Teléfono: {document.telefono}</p>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Card sx={{ minHeight: "200px" }}>
                    <CardHeader
                      title={`X Ventas`}
                      avatar={
                        <Avatar sx={{ bgcolor: "green" }} aria-label="recipe">
                          $
                        </Avatar>
                      }
                    ></CardHeader>
                    <CardContent>
                      <h1 style={{ width: "100%", textAlign: "center" }}>
                      X
                      </h1>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card sx={{ minHeight: "200px" }}>
                    <CardHeader
                      title={`${count} Cotizaciones`}
                      avatar={
                        <Avatar sx={{ bgcolor: "#14213d" }} aria-label="recipe">
                          C
                        </Avatar>
                      }
                    ></CardHeader>
                    <CardContent>
                      <h1 style={{ width: "100%", textAlign: "center" }}>
                        {formatMoney(total)}
                      </h1>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <h2>Cotizaciones</h2>
              <CustomerQuotes
                customer={cliente}
                onTotalCotChange={handleCotTotalChange}
                onCountCotChange={handleCotCountChange}
              />
            </Grid>
          </Grid>

          {masterClearance && (
            <Button
              className="btn no-print"
              onClick={handleDelete}
              variant="outlined"
              color="error"
              sx={{ marginTop: !isMobile ? 5 : 2 }}
            >
              Borrar Cliente
            </Button>
          )}
        </Box>
      )}
    </>
  )
}

export default Customer
