import { Box, Divider } from "@mui/material"
import React from "react"
import CH_Form from "./charmadas/CH_Form"

const Calculators = ({ isMobile }) => {
  return (
    <Box sx={{ marginTop: !isMobile ? 20 : 5, padding: 2 }}>
      <h1>Cotizadores</h1>
      <Divider />
      <CH_Form />
      <Divider />
    </Box>
  )
}

export default Calculators
