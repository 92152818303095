import { useState, React } from "react"
//components
import Select from "react-select"
import { Button, Grid } from "@mui/material"
import { CSVLink } from "react-csv"
import { availableColumns } from "./AvailableProperties"

export default function ProductColumnSelection({
  newColumns,
  tableData,
  tableHeader,
}) {
  const [columns, setColumns] = useState()

  const options = availableColumns

  const handleColumnChange = (option) => {
    setColumns(option)
    newColumns(option)
  }

  return (
    <div>
      <nav>
        <Grid
          container
          spacing={2}
          sx={{
            background: "#f7f7f7",
            padding: "16px",
            paddingTop: "0px",
          }}
        >
          <Grid item xs={12} md={5}>
            <Select
              options={options}
              onChange={(option) => handleColumnChange(option)}
              isMulti
              placeholder="seleccionar columnas"
              value={columns}
            />
          </Grid>
          <Grid item xs={12} md={4} />
          <Grid item xs={12} md={3}>
            <Button
              variant="contained"
              sx={{
                color: "#fff",
                background: "#006400",
                marginLeft: "10px",
              }}
            >
              <CSVLink
                data={tableData}
                headers={tableHeader}
                filename={"the_dojo.csv"}
                target="_blank"
              >
                Descargar en Excel
              </CSVLink>
            </Button>
          </Grid>
        </Grid>
      </nav>
    </div>
  )
}
