import React from "react"
import { Box } from "@mui/material"
import Error404 from "../error404/Error404"
import SortableTable from "../../components/tables/SortableTable" // Import SortableTable
import { Clearance } from "../../components/tools/Clearance"

const CustomersDashboard = ({ isMobile }) => {
  const fields = [
    {
      label: "Nombre",
      link: true,
      value: "nombreCliente",
      type: "text",
      width: "200px",
      isSearchTerm: true,
      isMobile: true,
    },
    {
      label: "Empresa",
      link: false,
      value: "empresa.label",
      type: "text",
      width: "150px",
      isSearchTerm: true,
      isMobile: true,
    },
    {
      label: "Empresa Clente",
      link: false,
      value: "empresaClienteLabel",
      type: "text",
      width: "200px",
      isSearchTerm: true,
      isMobile: true,
    },
    {
      label: "Email",
      link: false,
      value: "email",
      type: "text",
      width: "250px",
      isSearchTerm: true,
      isMobile: true,
    },
    {
      label: "Teléfono",
      link: false,
      value: "telefono",
      type: "number",
      width: "150px",
      isSearchTerm: true,
      isMobile: true,
    },
  ]

  const clearance = Clearance(3, 3)

  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Box sx={{ marginTop: !isMobile ? 20 : 5, marginBottom: !isMobile ? 20 : 5, padding:2 }}>
          
          <SortableTable
            isMobile={isMobile}
            collection="clientes"
            fields={fields}
            texts={{
              title: "Clientes",
              error: "No existen cotizaciones válidas",
            }}
            path={"customer"}
            clearance={clearance}
          />
        </Box>
      )}
    </>
  )
}

export default CustomersDashboard
