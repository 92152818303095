import { Box, Grid } from "@mui/material"
import React from "react"
import { Clearance } from "../../components/tools/Clearance"
import img from "../../assets/img/business.jpg"
import ImgCard from "../../components/ImgCard"

//Eventually createa db with posts for content
const posts = [
  {
    id: "hgfdsa",
    title: "Módulo: Importaciones",
    img: "https://images.pexels.com/photos/1596884/pexels-photo-1596884.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    shortdesc:
      " It adds support for GitHub-specific extensions to the language: tables, strikethrough, tasklists, and literal URLs.",
  },
  {
    id: "hgfdsb",
    title: "Módulo: Productos",
    img: "https://images.pexels.com/photos/7679669/pexels-photo-7679669.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    shortdesc:
      " It adds support for GitHub-specific extensions to the language: tables, strikethrough, tasklists, and literal URLs.",
  },
  {
    id: "hgfdsv",
    title: "Módulo: Cotizaciones",
    img: "https://images.pexels.com/photos/7513419/pexels-photo-7513419.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    shortdesc:
      " It adds support for GitHub-specific extensions to the language: tables, strikethrough, tasklists, and literal URLs.",
  },
  {
    id: "hgfdsd",
    title: "Módulo: Gráficas",
    img: "https://images.pexels.com/photos/4473398/pexels-photo-4473398.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    shortdesc:
      " It adds support for GitHub-specific extensions to the language: tables, strikethrough, tasklists, and literal URLs.",
  },
]

const Landing = ({ isMobile, userData }) => {
  const sectionStyle = {
    width: "100vw",
    height: "auto",
    backgroundImage: `url(${img})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }
  return (
    <Box
      sx={{
        marginTop: !isMobile ? 20 : 5,
      }}
    >
      <div
        id="landing-with-photo-container"
        style={{
          position: "relative", // Required for overlay
          overflow: "hidden", // Hide overflow
        }}
      >
        {/* Background Grid with white overlay */}
        <Grid
          id="landing-with-photo"
          container
          spacing={2}
          sx={{
            paddingTop: 20,
            paddingBottom: 20,
          }}
          style={sectionStyle}
        >
          {/* White overlay */}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "200vw", // Adjust as needed
              height: "100%",
              backgroundColor: "rgba(255, 255, 255, 0.7)", // Adjust opacity here
            }}
          ></div>

          <Grid item xs={12} md={6} sx={{ zIndex: 10 }}>
            <h1>
              Hola {userData?.displayName} <br />
              {!Clearance(5, 4)
                ? "¡Gracias por registrarte!"
                : "¡Es un placer verte de nuevo!"}
            </h1>
            <p>
              {!Clearance(5, 4)
                ? "Pronto te asignarán a un equipo empresarial y podrás navegar libremente"
                : "Descubre las últimas noticias y tutoriales que harán que tu experiencia en el portal sea aún más emocionante. ¡Vamos allá!"}
            </p>
          </Grid>
        </Grid>
      </div>

      <div className="container">
        <Grid
          container
          spacing={!isMobile ? 2 : 5}
          direction={!isMobile ? "row" : "column"}
          alignItems={"center"}
          sx={{ paddingTop: 10, paddingBottom: !isMobile ? 20 : 5 }}
        >
          {posts &&
            posts.map((post) => (
              <Grid item xs={12} md={3} key={post.id}>
                <ImgCard post={post} />
              </Grid>
            ))}
        </Grid>
      </div>
    </Box>
  )
}

export default Landing
