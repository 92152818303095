import React, { useRef } from "react"
import { Grid, FormControl } from "@mui/material"
import StarterKit from "@tiptap/starter-kit"
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditor,
  MenuButtonOrderedList,
} from "mui-tiptap"

const Comments = ({ comentarios, hanldeDetailsChange }) => {
  const rteRef = useRef(null)
  return (
    <Grid item xs={12} md={12}>
      <FormControl fullWidth>
        <RichTextEditor
          ref={rteRef}
          extensions={[StarterKit]} // Or any Tiptap extensions you wish!
          content={comentarios} // Set the initial content to comentarios
          onUpdate={({ editor }) => {
            hanldeDetailsChange({ target: { value: editor.getHTML() } })
          }} // Handle content changes
          // Optionally include `renderControls` for a menu-bar atop the editor:
          renderControls={() => (
            <MenuControlsContainer>
              <MenuSelectHeading />
              <MenuDivider />
              <MenuButtonBold />
              <MenuButtonItalic />
              <MenuButtonOrderedList />
              {/* Add more controls of your choosing here */}
            </MenuControlsContainer>
          )}
        />
      </FormControl>
    </Grid>
  )
}

export default Comments
