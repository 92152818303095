import React from "react"
import { Box } from "@mui/material"
import Error404 from "../error404/Error404"
import SortableTable from "../../components/tables/SortableTable" // Import SortableTable
import { Clearance } from "../../components/tools/Clearance"

const QuoteDashboard = ({ isMobile }) => {
  const fields = [
    {
      label: "Cotización",
      link: true,
      value: "cotizacion",
      type: "text",
      width: "150px",
      isSearchTerm: true,
      isMobile: true,
    },
    {
      label: "Empresa",
      link: false,
      value: "empresa.name",
      type: "text",
      width: "150px",
      isSearchTerm: true,
      isMobile: true,
    },
    {
      label: "Empresa Clente",
      link: false,
      value: "empresaCliente",
      type: "text",
      width: "200px",
      isSearchTerm: true,
      isMobile: true,
    },
    {
      label: "Nombre Cliente",
      link: false,
      value: "nombreCliente",
      type: "text",
      width: "200px",
      isSearchTerm: true,
      isMobile: true,
    },
    {
      label: "Valor",
      link: false,
      value: "totalCost",
      type: "money",
      width: "150px",
      isSearchTerm: true,
      isMobile: true,
    },
  ]

  const clearance = Clearance(3, 3)

  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Box sx={{ marginTop: !isMobile ? 20 : 5 , padding:2 }}>
          <SortableTable
            isMobile={isMobile}
            collection="cotizaciones"
            fields={fields}
            texts={{
              title: "Cotizaciones",
              error: "No existen cotizaciones válidas",
            }}
            path={"cotizaciones"}
            clearance={clearance}
          />
        </Box>
      )}
    </>
  )
}

export default QuoteDashboard
