import React from "react"
import Avatar from "@mui/material/Avatar"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"
import { Chip, Stack } from "@mui/material"

const SummaryCard = ({ object, type, users }) => {
  // Function to check if the object is overdue
  const overdue = () => {
    const today = new Date()
    return object.dueDate.toDate() < today
  }

  const done = () => {
    if (
      object.stage.value === "backlog" ||
      object.stage.value === "terminado"
    ) {
      return true
    } else {
      return false
    }
  }

  function cropTitle(inputString) {
    if (inputString.length <= 25) {
      return inputString;
    } else {
      return inputString.substring(0, 25) + '...';
    }
  }

  return (
    <Link to={`/${type}/${object.id}`} key={object.id}>
      <Card variant="outlined" sx={{ minHeight: 150, width: 275 }}>
        <CardContent>
          {/* Display a "Tarde" Chip if the object is overdue */}
          {overdue() && !done() && (
            <Chip
              variant="contained"
              label="Tarde"
              sx={{ fontSize: 11, background: "#8C3333", color: "white" }}
              size="small"
            />
          )}
          {!overdue() && !done() && (
            <Chip
              variant="contained"
              label="A Tiempo"
              sx={{ fontSize: 11, background: "#7A9D54", color: "white" }}
              size="small"
            />
          )}
          {done() && (
            <Chip
              variant="contained"
              label="Terminado"
              sx={{ fontSize: 11, background: "#CFD2CF", color: "black" }}
              size="small"
            />
          )}
          <Typography
            variant="h5"
            sx={{ fontSize: 16 }}
            component="div"
            gutterBottom
          >
            {cropTitle(object.name)}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Due: {object.dueDate.toDate().toLocaleDateString("es-mx")}
          </Typography>
          <Stack direction="row" spacing={1}>
            {object.assignedUsersList.map((user) => (
              <Avatar
                sx={{ width: 24, height: 24 }}
                key={user.id}
                src={users.find((u) => u.id === user.id)?.photoURL} // Fix user object access
              />
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Link>
  )
}

export default SummaryCard
