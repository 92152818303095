import React, { useEffect, useState } from "react"

import { useUpdateUser } from "../../hooks/useUpdateUser"
import { useFirestore } from "../../hooks/useFirestore"
import { projectStorage } from "../../firebase/config"

import { Avatar, Box, Button, Grid } from "@mui/material"
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined"

const EditUser = ({ onEdit, user }) => {
  const { user: userToUpdate, updateProfile } = useUpdateUser()
  const { updateDocument, response } = useFirestore("users")

  const [thumbnailError, setThumbnailError] = useState(null)
  const [imageChanged, setImageChanged] = useState(false)
  const [avatar, setAvatar] = useState(null)
  const [name, setName] = useState(null)

  useEffect(() => {
    if (user) {
      setAvatar(user.photoURL)
      setName(user.displayName)
    }
  }, [user])

  const handleFileChange = (e) => {
    setAvatar(null)
    let selected = e.target.files[0]

    if (!selected) {
      setThumbnailError("Please select a file")
      return
    }

    if (!selected.type.includes("image")) {
      setThumbnailError("Selected file must be an image")
      return
    }

    if (selected.size > 600000) {
      setThumbnailError("Image file size must be less than 600kb")
      return
    }

    setImageChanged(true)
    setThumbnailError(null)
    setAvatar(selected)
    console.log("avatar", avatar)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (userToUpdate) {
      //Profile pic changed

      if (imageChanged) {
        const uploadPath = `thumbnails/${user.id}/${avatar.name}`
        const img = await projectStorage.ref(uploadPath).put(avatar)
        const imgUrl = await img.ref.getDownloadURL()

        await updateProfile({ displayName: name, photoURL: imgUrl })

        await updateDocument(user.id, { displayName: name, photoURL: imgUrl })
        if (!response.error) {
        }
      } else {
        await updateProfile({ displayName: name })
        await updateDocument(user.id, { displayName: name })
        if (!response.error) {
        }
      }

      onEdit(false)
    }
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Button
            onClick={() => onEdit(false)}
            variant="outlined"
            startIcon={<ArrowBackIosNewOutlinedIcon />}
          >
            Regresar
          </Button>
        </Grid>

        <form onSubmit={handleSubmit} className="auth-form">
          <label>
            <Avatar
              alt="Remy Sharp"
              src={avatar}
              sx={{ width: 50, height: 50 }}
            />
            Editar foto de perfil
            <input type="file" onChange={handleFileChange} />
            {thumbnailError && <div className="error">{thumbnailError}</div>}
          </label>
          <label>
            <span>{name}</span>
            Editar nombre:
            <input type="text" onChange={(e) => setName(e.target.value)} />
          </label>
          <Button variant="contained" onClick={handleSubmit}>
            Guardar cambios
          </Button>
        </form>
      </Grid>
    </Box>
  )
}

export default EditUser
