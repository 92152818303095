import { useState } from "react"

const filterList = ["Importaciones", "Productos", "Cronograma"]

export default function ShipmentsFilter({ changeFilter }) {
  const [currentFilter, setCurrentFilter] = useState("Importaciones")


  const handleClick = (newFilter) => {
    setCurrentFilter(newFilter)
    changeFilter(newFilter)
  }

  return (
    <>
      <div className="project-filter">
        <nav>
          <p>Ver por: </p>
          {filterList.map((f) => (
            <button
              key={f}
              onClick={() => handleClick(f)}
              className={currentFilter === f ? "active" : ""}
            >
              {f}
            </button>
          ))}
        </nav>
      </div>
      
    </>
  )
}
