import { Box, Button, Chip } from "@mui/material"
import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import CustomButton from "../../components/tables/CustomButton"

const ProjectList = ({ data, filter, isMobile }) => {
  // Initialize state variables
  const [projectData, setProjectData] = useState(data) // Initialize with your data
  const [sortState, setSortState] = useState({
    column: null, // Property to sort by (e.g., "name", "priority")
    direction: "asc", // Initial sorting direction
  })

  // Function to handle sorting
  const handleSort = (property) => {
    // Check if the same column is clicked again to toggle the sorting direction
    const isSameColumn = sortState.column === property
    const direction = isSameColumn
      ? sortState.direction === "asc"
        ? "desc"
        : "asc"
      : "asc"

    // Sort the projectData based on the selected property and direction
    const sortedList = [...projectData].sort((a, b) => {
      if (property === "dueDate") {
        // Special case for date sorting
        const dateA = a[property] ? a[property].toDate() : null
        const dateB = b[property] ? b[property].toDate() : null

        if (!dateA && !dateB) return 0
        if (!dateA) return direction === "asc" ? 1 : -1
        if (!dateB) return direction === "asc" ? -1 : 1

        return direction === "asc" ? dateA - dateB : dateB - dateA
      } else {
        // Default sorting for other columns
        const valueA = getProperty(a, property)
        const valueB = getProperty(b, property)

        if (valueA < valueB) return direction === "asc" ? -1 : 1
        if (valueA > valueB) return direction === "asc" ? 1 : -1
        return 0
      }
    })

    // Update the state with the sorted list and sorting state
    setProjectData(sortedList)
    setSortState({ column: property, direction })
  }

  // Helper function to get nested property
  const getProperty = (object, propertyPath) => {
    const parts = propertyPath.split(".")
    let value = object
    for (const part of parts) {
      value = value ? value[part] : null
    }
    return value
  }

  // Apply the filter when the filter prop changes
  useEffect(() => {
    if (filter === "all") {
      // If filter is "all," show all projects without filtering
      setProjectData(data)
    } else {
      // Apply the filter based on the selected category
      const filteredList = data.filter((item) => item.category === filter)
      setProjectData(filteredList)
    }
  }, [filter, data])

  return (
    <Box sx={{ maxWidth: "100%", overflowX: "scroll" }}>
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr style={{ padding: "20px" }}>
            <td
              onClick={() => handleSort("name")}
              style={{ width: isMobile ? "300px" : "auto" }}
            >
              <CustomButton>Nombre</CustomButton>
            </td>
            <td
              onClick={() => handleSort("priority.label")}
              style={{ width: "150px" }}
            >
              <CustomButton>Prioridad</CustomButton>
            </td>

            <td
              onClick={() => handleSort("category")}
              style={{ width: "150px" }}
            >
              <CustomButton>Categoría</CustomButton>
            </td>

            <td
              onClick={() => handleSort("stage.label")}
              style={{ width: "150px" }}
            >
              <CustomButton>Etapa</CustomButton>
            </td>
            <td
              onClick={() => handleSort("dueDate")}
              style={{ width: "150px" }}
            >
              <CustomButton>Due Date</CustomButton>
            </td>
          </tr>
        </thead>
        <tbody>
          {projectData.map((d) => (
            <tr key={d.id}>
              <td style={{ fontWeight: "500" }}>
                <Link to={`/projects/${d.id}`}>{d.name}</Link>
              </td>
              <td>
                <Chip
                  label={d.priority ? d.priority.label : "No definida"}
                  sx={{
                    background: d.priority ? d.priority.color : "#000",
                    color: "white",
                    fontSize: isMobile ? "0.7em" : "0.9em",
                  }}
                />
              </td>
              <td>{d.category}</td>
              <td style={{ color: d.stage.color }}>
                <Chip
                  label={d.stage ? d.stage.label : "No definida"}
                  sx={{
                    background: d.stage ? d.stage.color : "#000",
                    color: "white",
                    fontSize: isMobile ? "0.7em" : "0.9em",
                  }}
                />
              </td>
              <td>{d.dueDate.toDate().toLocaleDateString("es-mx")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  )
}

export default ProjectList
