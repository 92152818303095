import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"
import "firebase/firebase-functions"


const firebaseConfig = {
  apiKey: "AIzaSyCXHVr49m-hTiCP8LQuqws0d7a99sboirY",
  authDomain: "cooking-ninja-react-project.firebaseapp.com",
  projectId: "cooking-ninja-react-project",
  storageBucket: "cooking-ninja-react-project.appspot.com",
  messagingSenderId: "325482779917",
  appId: "1:325482779917:web:b39da8f36da23460c00c44",
}

//initialize firebase
firebase.initializeApp(firebaseConfig)

//initialize services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectFunctions = firebase.functions()

//initialize storage
const projectStorage = firebase.storage()
//timestamp
const timestamp = firebase.firestore.Timestamp

export {
  projectFirestore,
  projectAuth,
  timestamp,
  projectStorage,
  projectFunctions,
}
