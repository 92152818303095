import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
} from "@mui/material"
import { useCollection } from "../../hooks/useCollection"
import { Link } from "react-router-dom"
import { useUserRoleAndSecurity } from "../../hooks/useUserRoleAndSecurity"
import React from "react"

const WarehouseDashboard = ({ isMobile }) => {
  const { currentCompanyList } = useUserRoleAndSecurity()
  const { documents, error } = useCollection("warehouses")

  // Step 4: Filter the filtered documents using the productLines
  const warehouses = currentCompanyList
    ? documents?.filter((document) =>
        currentCompanyList.some(
          (currCompany) => document.company.value === currCompany.value
        )
      )
    : []

  console.log("Filtered products:", warehouses)
  return (
    <Box sx={{ marginTop: !isMobile ? 20 : 5, padding:2 }}>
      <h1 className="page-title">Almacenes</h1>
      <Grid container spacing={2} sx={{ marginTop: 5 }}>
        {warehouses?.map((wh) => (
          <Grid item xs={12} md={3}>
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <p style={{ fontSize: "12px" }}>{wh.company.label}</p>
                <h2 className="">{wh.label}</h2>
              </CardContent>
              <CardActions>
                <Link to={`/almacenes/${wh.id}`} key={wh.id}>
                  <Button variant="outlined">Ver Almacén</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default WarehouseDashboard
