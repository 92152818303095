import { useCollection } from "../../hooks/useCollection"
import { useState, useEffect } from "react"
import { Clearance } from "../../components/tools/Clearance"
import Section from "../../components/Section"
import { priorityData, stagesData } from "./StagesData"

import "./Dashboard.css"
import { Box, Button, Grid } from "@mui/material"
import ChangeView from "../../components/ChangeView"
import ProjectList from "./ProjectList"
import ChangeStage from "../../components/ChangeStage"
import ChangePriority from "../../components/ChangePriority"
import Error404 from "../error404/Error404"
import PriorityLayout from "./PriorityLayout"

export default function Dashboard({ isMobile, userData }) {
  const { documents, error } = useCollection("projects")
  const [filter, setFilter] = useState("all")
  const [currentView, setCurrentView] = useState("list")
  const [currentStage, setCurrentStage] = useState(null)
  const [currentPriority, setCurrentPriority] = useState(null)
  const { documents: userDocuments } = useCollection("users")
  const [users, setUsers] = useState([])

  useEffect(() => {
    if (userDocuments) {
      const options = userDocuments.map((user) => {
        return {
          uid: user.id,
          photoURL: user.photoURL,
          label: user.displayName,
        }
      })
      setUsers(options)
      //console.log("users: ", users)
    }
  }, [userDocuments])

  const handleNewStage = (option) => {
    setCurrentStage(option)
  }

  const handleNewPriority = (option) => {
    setCurrentPriority(option)
  }

  const changeFilter = (newFilter) => {
    setFilter(newFilter)
  }

  const handleViewChange = (newView) => {
    console.log("current view: ", currentView)
    setCurrentView(newView)
  }

  const projects = documents
    ? documents.filter((document) => {
        let stageFilterPassed = false
        let categoryFilterPassed = false
        let priorityFilterPassed = false

        // Check if the project matches the selected stage filter
        if (
          currentStage === null ||
          document.stage.value === currentStage.value
        ) {
          stageFilterPassed = true
        }

        if (
          currentPriority === null ||
          document.priority.value === currentPriority.value
        ) {
          priorityFilterPassed = true
        }

        // Check if the project matches the selected category filter
        switch (filter) {
          case "all":
            categoryFilterPassed = true
            break
          case "mine":
            categoryFilterPassed = document.assignedUsersList.some(
              (u) => u.id === userData.id
            )
            break
          case "development":
          case "design":
          case "sales":
          case "marketing":
            categoryFilterPassed = document.category === filter
            break
          default:
            categoryFilterPassed = true
            break
        }

        // Return true if both stage and category filters are passed
        return stageFilterPassed && priorityFilterPassed && categoryFilterPassed
      })
    : null

  return (
    <>
      {!Clearance(5, 4) && <Error404 />}

      {Clearance(5, 4) && (
        <Box sx={{ marginTop: !isMobile ? 20 : 5, padding: 2 }}>
          <h2 className="page-title">Proyectos</h2>

          {error && <p className="error">{error}</p>}
          {!isMobile && (
            <Grid container spacing={15} paddingTop={5}>
              <ChangeView
                handleViewChange={handleViewChange}
                currentView={currentView}
              />
              <Grid item xs={12} md={2}>
                <ChangeStage stages={stagesData} newStage={handleNewStage} />
              </Grid>
              <Grid item xs={12} md={2}>
                <ChangePriority
                  priorities={priorityData}
                  newPriority={handleNewPriority}
                />
              </Grid>
            </Grid>
          )}
          {isMobile && (
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <ChangeStage stages={stagesData} newStage={handleNewStage} />
              </Grid>
              <Grid item xs={12} md={2}>
                <ChangePriority
                  priorities={priorityData}
                  newPriority={handleNewPriority}
                />
              </Grid>
            </Grid>
          )}
          {projects && (
            <Section>
              {currentView === "grid" && (
                // <ObjectList type="projects" objects={projects} sortvar="name" />
                <PriorityLayout
                  objects={projects}
                  isMobile={isMobile}
                  type="projects"
                  users={users}
                />
              )}
              {currentView === "list" && (
                <ProjectList
                  data={projects}
                  filter={filter}
                  isMobile={isMobile}
                />
              )}
            </Section>
          )}
        </Box>
      )}
    </>
  )
}
