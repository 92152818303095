import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useLogout } from "../hooks/useLogout"
import { useAuthContext } from "../hooks/useAuthContext"
import { styled } from "@mui/material/styles"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import { Box, Button } from "@mui/material"

// styles & images
import "./Navbar.css"
import Temple from "../assets/temple.svg"
import MenuButton from "./MenuButton"

const drawerWidth = 240

const buttonStyle = {
  backgroundColor: "white",
  "&:hover": {
    color: "#fff",
  },
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const CustomAppBar = ({ open, handleDrawerOpen, userData }) => {
  const { logout, isPending } = useLogout()
  const { user } = useAuthContext()
  const [userCompaniesList, setUserCompaniesList] = useState(null)

  useEffect(() => {
    if (userData) {
      const uCompanies = userData.companiesList.map((uc) => ({
        id: uc.id,
        label: uc.label,
        value: uc.value,
        key: uc.key,
      }))
      setUserCompaniesList(uCompanies)
    }
  }, [userData])

  //console.log("User data in custom app bar: ", userData)

  return (
    <div className="no-print">
      <AppBar
        position="fixed"
        open={open}
        sx={{ marginBottom: "20px", padding: "10px", width: "100%" }}
        className="no-print"
      >
        <Toolbar className="no-print">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              style={{ filter: "invert(100%)", marginRight: "10px" }}
              src={Temple}
              alt="dojo logo"
            />
            <span>Portal Mamalon</span>
          </Box>
          <div style={{ marginLeft: "auto" }}>
            {!user && (
              <>
                <Link
                  style={{ color: "#FFF", marginRight: "10px" }}
                  to="/login"
                >
                  Login
                </Link>
                <Link style={{ color: "#FFF" }} to="/signup">
                  Signup
                </Link>
              </>
            )}
            {user && (
              <>
                {!isPending && <MenuButton userData={userData} logout={logout}/>}
                {isPending && (
                  <Button sx={buttonStyle} disabled>
                    Loging out...
                  </Button>
                )}
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  )
}
export default CustomAppBar
