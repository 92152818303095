import React, { useEffect, useState } from "react"
import { useCollection } from "../../hooks/useCollection"
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Box,
  Grid,
} from "@mui/material"
import CompanySummary from "./CompanySummary"

const CompaniesSummary = ({ onEdit }) => {
  const { error, documents } = useCollection("empresas")

  const [empresas, setEmpresas] = useState(null)

  useEffect(() => {
    if (documents) {
      const emp = documents.map((e) => {
        return {
          id: e.id,
          code: e.code,
          color: e.color,
          color2: e.color2,
          label: e.label,
          name: e.name,
          value: e.value,
          photoURL: e.photoURL,
          address: e.address,
          website: e.website,
          companyEmail: e.companyEmail,
          companyPhone: e.companyPhone,
          companyWhatsapp: e.companyWhatsapp,
          privacyPolicy: e.privacyPolicy,
          modules: e.modules,
          productLines: e.productLines,
        }
      })
      setEmpresas(emp)
    } else {
      console.log("No hay empresas registradas", error)
    }
  }, [documents, error])

  return (
    <Box>
      <List>
        {empresas &&
          empresas.map((e) => <CompanySummary e={e} onEdit={onEdit} />)}
      </List>
    </Box>
  )
}

export default CompaniesSummary
