import React from "react"
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"

const labels = {
  0: "NO_ACCESS",
  1: "NO_ACCESS",
  2: "READ",
  3: "UPDATE",
  4: "CREATE",
  5: "DELETE",
}

const UserSummaryTable = ({ userData }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="company-modules">
        <TableHead>
          <TableRow>
            <TableCell>Empresa</TableCell>
            {Array.from(
              new Set(
                userData?.companiesList.flatMap((company) =>
                  company.modules.map((module) => module.label)
                )
              )
            ).map((label, index) => (
              <TableCell key={index}>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {userData?.companiesList.map((company) => (
            <TableRow key={company.label}>
              <TableCell component="th" scope="row">
                {company.label}
              </TableCell>
              {Array.from(
                new Set(
                  userData.companiesList.flatMap((company) =>
                    company.modules.map((module) => module.label)
                  )
                )
              ).map((label, index) => {
                const module = company.modules.find(
                  (mod) => mod.label === label
                )
                return (
                  <TableCell key={`${company.label}-${index}`}>
                    {module ? module.security : "N/A"}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UserSummaryTable
