import { React, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDocument } from "../../hooks/useDocument"
import { useCollection } from "../../hooks/useCollection"
import { useFirestore } from "../../hooks/useFirestore"
import Select from "react-select"

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material"

const SubWarehouse = ({ isMobile }) => {
  const { id } = useParams()
  const { documents: productsFromDB } = useCollection("products")
  const { documents } = useCollection("subwarehouses")
  const [currWarehouse, setCurrWarehouse] = useState(null)
  const { updateDocument, response } = useFirestore("subwarehouses")
  const [pdb, setPdb] = useState(null)
  const [product, setProduct] = useState(null)
  const [productoManual, setProductoManual] = useState("")
  const [checked, setChecked] = useState(false)
  const { document, error } = useDocument("subwarehouses", id)

  const handleCheckChange = (event) => {
    setChecked(event.target.checked)
  }

  const handleInputChange = (event) => {
    setProductoManual(event.target.value)
  }

  const handleProductoChange = (e) => {
    setProduct(e)
  }

  useEffect(() => {
    if (productsFromDB) {
      const productDB = productsFromDB.map((product) => {
        return {
          id: product.id,
          productCode: product.productCode,
          value: product.id,
          name: product.name,
          label: product.name,
        }
      })
      setPdb(productDB)
    }
  }, [productsFromDB])

  useEffect(() => {
    if (documents) {
      // Find the document with the matching ID
      const matchingDocument = documents.find((doc) => doc.id === id)

      if (matchingDocument) {
        // Assign the matching document to currWarehouse
        setCurrWarehouse(matchingDocument)
      }
    }
  }, [documents, id])

  const handleAgregar = async () => {
    // Ensure document.productList is initialized as an empty array
    const productList = document.productList || []

    if (product) {
      // Handle product select Change
      await updateDocument(document.id, {
        productList: [...productList, product],
      })
    }

    if (productoManual !== "") {
      // Handle producto Manual
      const manualProd = {
        id: productoManual,
        productCode: productoManual,
        value: productoManual,
        name: productoManual,
        label: productoManual,
      }

      await updateDocument(id, {
        productList: [...productList, manualProd],
      })
    }

    setProduct(null)
    setProductoManual("")
  }

  return (
    <Box sx={{ marginTop: !isMobile ? 20 : 5 }}>
      {document?.warehouse[0].label}
      <h1>{document?.label}</h1>
      <Box marginTop={5}>
        <p>Asignar Productos</p>
        <Grid container spacing={2}>
          <Grid item xs={9} md={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onChange={handleCheckChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Producto Manual"
            />
            <label>
              {!checked && (
                <Select
                  onChange={(e) => handleProductoChange(e)}
                  options={pdb}
                  placeholder="Producto"
                  value={product}
                />
              )}

              {checked && (
                <input
                  type="text"
                  placeholder="Producto Manual"
                  onChange={(e) => handleInputChange(e)}
                  value={productoManual}
                />
              )}
            </label>

            <Button variant="outlined" onClick={handleAgregar}>
              Agregar
            </Button>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 5 }}>
          <h1 className="page-title">Productos Asignados</h1>
          {currWarehouse?.productList?.map((productItem) => (
            <p key={productItem.id}>{productItem.label}</p>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default SubWarehouse
