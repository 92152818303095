import { React, useState, useEffect } from "react"
import { useCollection } from "../../hooks/useCollection"
import { Box, Button } from "@mui/material"
import { Link } from "react-router-dom"
import CustomButton from "../../components/tables/CustomButton"

const ShipmentsChronogram = () => {
  const { documents, error } = useCollection("shipments")

  // Define initial sorting state
  const initialSortState = {
    column: "name", // Default sorting column
    direction: "asc", // Default sorting direction
  }

  const objDefault = [{ value: "no", label: "No especificado", color: "" }]

  const [shipmentList, setShipmentList] = useState([])
  const [sortState, setSortState] = useState(initialSortState)

  useEffect(() => {
    if (documents) {
      const shipments = documents.map((doc) => {
        return {
          id: doc.id,
          name: doc.name,
          category: doc.category ? doc.category : objDefault,
          almacenajes: doc.almacenajes || null,
          demoras: doc.demoras || null,
          supplier: doc.supplier ? doc.supplier : objDefault,
          container: doc.container ? doc.container : objDefault,
          eta: doc.dueDate,
          inboundPort: doc.inboundPort ? doc.inboundPort : objDefault,
          outboundPort: doc.outboundPort ? doc.outboundPort : objDefault,
          telex: doc.telex ? doc.telex : objDefault,
          impuestos: doc.impuestos ? doc.impuestos : objDefault,
          docs: doc.docs ? doc.docs : objDefault,
          cartas: doc.cartas ? doc.cartas : objDefault,
          pedimento: doc.pedimento ? doc.pedimento : objDefault,
          gondola: doc.gondola ? doc.gondola : objDefault,
          fueraTerminal: doc.fueraTerminal ? doc.fueraTerminal : objDefault,
        }
      })
      setShipmentList(shipments)
      if (error) {
        console.log("shipments chronogram error: ", error)
      }
    }
  }, [documents, error])

  // Function to handle sorting
  const handleSort = (property) => {
    // Check if the same column is clicked again to toggle the sorting direction
    const isSameColumn = sortState.column === property
    const direction = isSameColumn
      ? sortState.direction === "asc"
        ? "desc"
        : "asc"
      : "asc"

    // Sort the shipmentList based on the selected property and direction
    const sortedList = [...shipmentList].sort((a, b) => {
      if (property === "eta") {
        // Special case for date sorting
        const dateA = a[property] ? a[property].toDate() : null
        const dateB = b[property] ? b[property].toDate() : null

        if (!dateA && !dateB) return 0
        if (!dateA) return direction === "asc" ? 1 : -1
        if (!dateB) return direction === "asc" ? -1 : 1

        return direction === "asc" ? dateA - dateB : dateB - dateA
      } else {
        // Default sorting for other columns
        const valueA = getProperty(a, property)
        const valueB = getProperty(b, property)

        if (valueA < valueB) return direction === "asc" ? -1 : 1
        if (valueA > valueB) return direction === "asc" ? 1 : -1
        return 0
      }
    })

    // Update the state with the sorted list and sorting state
    setShipmentList(sortedList)
    setSortState({ column: property, direction })
  }

  // Helper function to get nested property
  const getProperty = (object, propertyPath) => {
    const parts = propertyPath.split(".")
    let value = object
    for (const part of parts) {
      value = value ? value[part] : null
    }
    return value
  }

  return (
    <Box sx={{ maxWidth: "100%", overflowX: "scroll" }}>
      {shipmentList && (
        <Box sx={{ width: "150%" }}>
          <table className="styled-table">
            <thead style={{ cursor: "pointer" }}>
              <tr>
                <td onClick={() => handleSort("name")}>
                  <CustomButton>Nombre</CustomButton>
                </td>
                <td onClick={() => handleSort("category.label")}>
                  <CustomButton>Etapa</CustomButton>
                </td>
                <td onClick={() => handleSort("supplier.label")}>
                  <CustomButton>Proveedor</CustomButton>
                </td>
                <td onClick={() => handleSort("eta")}>
                  <CustomButton>ETA</CustomButton>
                </td>
                <td onClick={() => handleSort("container.label")}>
                  <CustomButton>Tipo</CustomButton>
                </td>
                <td onClick={() => handleSort("inboundPort.label")}>
                  <CustomButton>Puerto Llegada</CustomButton>
                </td>
                <td onClick={() => handleSort("almacenajes")}>
                  <CustomButton>Almacenajes</CustomButton>
                </td>
                <td onClick={() => handleSort("demoras")}>
                  <CustomButton>Demoras</CustomButton>
                </td>
                <td onClick={() => handleSort("telex.label")}>
                  <CustomButton>Telex Enviado</CustomButton>
                </td>
                <td onClick={() => handleSort("docs.label")}>
                  <CustomButton>Docs Enviados</CustomButton>
                </td>
                <td onClick={() => handleSort("impuestos.label")}>
                  <CustomButton>Solicitud de Impuestos Pagada</CustomButton>
                </td>
                <td onClick={() => handleSort("cartas.label")}>
                  <CustomButton>Cartas Enviadas</CustomButton>
                </td>
                <td onClick={() => handleSort("pedimento.label")}>
                  <CustomButton>Pedimento Enviado</CustomButton>
                </td>
                <td onClick={() => handleSort("gondola.label")}>
                  <CustomButton>Cargado a Góndola</CustomButton>
                </td>
                <td onClick={() => handleSort("fueraTerminal.label")}>
                  <CustomButton>Fuera de Terminal</CustomButton>
                </td>
              </tr>
            </thead>
            <tbody>
              {shipmentList.map((ship) => (
                <tr key={ship.id}>
                  <td style={{ fontWeight: "bold" }}>
                    <Link to={`/shipments/${ship.id}`}>{ship.name}</Link>
                  </td>
                  <td
                    style={{ background: ship.category.color, color: "#fff" }}
                  >
                    {ship.category.label}
                  </td>
                  <td>{ship.supplier.label}</td>
                  <td>
                    {ship.eta
                      ? ship.eta.toDate().toLocaleDateString("es-mx")
                      : ""}
                  </td>
                  <td>{ship.container.label}</td>
                  <td>{ship.inboundPort.label}</td>
                  <td>
                    {ship.almacenajes
                      ? ship.almacenajes.toDate().toLocaleDateString("es-mx")
                      : ""}
                  </td>
                  <td>
                    {ship.demoras
                      ? ship.demoras.toDate().toLocaleDateString("es-mx")
                      : ""}
                  </td>
                  <td style={{ background: ship.telex.color, color: "#fff" }}>
                    {ship.telex.label}
                  </td>
                  <td style={{ background: ship.docs.color, color: "#fff" }}>
                    {ship.docs.label}
                  </td>
                  <td
                    style={{ background: ship.impuestos.color, color: "#fff" }}
                  >
                    {ship.impuestos.label}
                  </td>
                  <td style={{ background: ship.cartas.color, color: "#fff" }}>
                    {ship.cartas.label}
                  </td>
                  <td
                    style={{ background: ship.pedimento.color, color: "#fff" }}
                  >
                    {ship.pedimento.label}
                  </td>
                  <td style={{ background: ship.gondola.color, color: "#fff" }}>
                    {ship.gondola.label}
                  </td>
                  <td
                    style={{
                      background: ship.fueraTerminal.color,
                      color: "#fff",
                    }}
                  >
                    {ship.fueraTerminal.label}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      )}
    </Box>
  )
}

export default ShipmentsChronogram
