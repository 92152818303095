import { React, useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
import { Box } from '@mui/material'
//styles
import './Login.css'

export default function Login() {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { login, isPending, error } = useLogin()

  const handleSubmit = (e) => {
    e.preventDefault()

    login(email, password)

  }

  return (
    <Box sx={{ marginTop: 20 }}>
      <form onSubmit={handleSubmit} className="auth-form">
        <h2>login</h2>
        <label>
          <span>email:</span>
          <input
            required
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </label>
        <label>
          <span>password:</span>
          <input
            required
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </label>
        {!isPending && <button className="btn">Login</button>}
        {isPending && <button disabled className="btn">Loading...</button>}
        {error && <div className='error'>{error}</div>}
      </form>
    </Box>

  )
}
