import React from "react"
//styles
import "./ProductSummary.css"
import "../../components/ProductList.css"
import { useFirestore } from "../../hooks/useFirestore"
import { useHistory } from "react-router-dom"
import { Clearance } from "../../components/tools/Clearance"

export default function ProductSummary({ product }) {
  const history = useHistory()
  const masterClearance = Clearance(1, 1)
  const { deleteDocument } = useFirestore("products")
  const handleDelete = () => {
    deleteDocument(product.id)
    history.push("/product-dashboard")
  }

  return (
    <>
      <div className="product-summary">
        <h1 className="">{product.productCode}</h1>
        <h2 className="page-title">{product.name}</h2>
        <p className="due-date">Línea de Producto: {product.category}</p>
        <div className="responsive">
          <h2 className="page-title">Medidas</h2>
          <table class="styled-table">
            <thead>
              <tr>
                <th>Ancho</th>
                <th>Largo</th>
                {product.espesorMM && <th>Espesor (mm)</th>}
                {product.espesorIn && <th>Espesor (in)</th>}
                {product.largoRollo && <th>Largo del Rollo</th>}
                {product.peso && <th>Peso por unidad</th>}
                {product.peso && product.largoRollo && <th>Peso por rollo</th>}
              </tr>
            </thead>
            <tr>
              <td>{product.ancho} m</td>
              <td>{product.largo} m</td>
              {product.espesorMM && <td>{product.espesorMM} mm</td>}
              {product.espesorIn && <td>{product.espesorIn} in</td>}
              {product.largoRollo && <td>{product.largoRollo} m</td>}
              {product.peso && <td>{product.peso} kg</td>}
              {product.peso && product.largoRollo && (
                <td>{product.peso * product.largoRollo} kg</td>
              )}
            </tr>
          </table>
        </div>
        <div>
          <h2 className="page-title">Precio al público</h2>
          {product.precioPublicoMetro && <h4>Por metro</h4>}
          {product.precioPz && <h4>Por pieza</h4>}
          <table class="styled-table">
            <thead>
              <tr>
                {product.precioPublicoMetro && <th>Por metro</th>}
                {product.precioPublicoRollo && <th>Por rollo</th>}
                {product.precioPublico1Rollo && <th>Por 1 rollo</th>}
                {product.precioPublico24Rollo && <th>Por 2-4 rollos</th>}
                {product.precioPublico5masRollo && <th>Por 5 más rollos</th>}
                {product.precioPz && <th>Por pieza</th>}
              </tr>
            </thead>
            <tr>
              {product.precioPublicoMetro && (
                <td>${product.precioPublicoMetro}</td>
              )}
              {product.precioPublicoRollo && (
                <td>${product.precioPublicoRollo}</td>
              )}
              {product.precioPublico1Rollo && (
                <td>${product.precioPublico1Rollo}</td>
              )}
              {product.precioPublico24Rollo && (
                <td>${product.precioPublico24Rollo}</td>
              )}
              {product.precioPublico5masRollo && (
                <td>${product.precioPublico5masRollo}</td>
              )}
              {product.precioPz && <th>${product.precioPz}</th>}
            </tr>
          </table>
        </div>
        {(product.precioHPKMetro || product.precioPzHPK) && (
          <div>
            <h2 className="page-title highlight">Precio a Hulempak</h2>
            {product.precioPublicoMetro && <h4>Por metro</h4>}
            {product.precioPz && <h4>Por pieza</h4>}
            <table class="styled-table">
              <thead>
                <tr>
                  {product.precioHPKMetro && <th>Por metro</th>}
                  {product.precioHPKRollo && <th>Por rollo</th>}
                  {product.precioPzHPK && <th>Por pieza</th>}
                </tr>
              </thead>
              <tr>
                {product.precioHPKMetro && <td>${product.precioHPKMetro}</td>}
                {product.precioHPKRollo && <td>${product.precioHPKRollo}</td>}
                {product.precioPzHPK && <th>${product.precioPzHPK}</th>}
              </tr>
            </table>
          </div>
        )}
        <div>
          <h2 className="page-title">Precio a filiales</h2>
          {product.precioPublicoMetro && <h4>Por metro</h4>}
          {product.precioPz && <h4>Por pieza</h4>}
          <table class="styled-table">
            <thead>
              <tr>
                {product.precioFilialesMetro && <th>Por metro</th>}
                {product.precioFilialesRollo && <th>Por rollo</th>}
                {product.precioPzFiliales && <th>Por pieza</th>}
              </tr>
            </thead>
            <tr>
              {product.precioFilialesMetro && (
                <td>${product.precioFilialesMetro}</td>
              )}
              {product.precioFilialesRollo && (
                <td>${product.precioFilialesRollo}</td>
              )}
              {product.precioPzFiliales && <th>${product.precioPzFiliales}</th>}
            </tr>
          </table>
        </div>
        <div>
          <h2 className="page-title">Precio a Mercado Libre</h2>
          {product.precioPublicoMetro && <h4>Por metro</h4>}
          {product.precioPz && <h4>Por pieza</h4>}
          <table class="styled-table">
            <thead>
              <tr>
                {product.precioMLMetro && <th>Por metro</th>}
                {product.precioMLRollo && <th>Por rollo</th>}
                {product.precioPzML && <th>Por pieza</th>}
              </tr>
            </thead>
            <tr>
              {product.precioMLMetro && <td>${product.precioMLMetro}</td>}
              {product.precioMLRollo && <td>${product.precioMLRollo}</td>}
              {product.precioPzML && <th>${product.precioPzML}</th>}
            </tr>
          </table>
        </div>
        <p className="details">{product.details}</p>
        <h4>Tags del producto:</h4>
        <div className="assigned-users">
          {product.assignedTagsList.map((tag) => (
            <div key={tag.id} className="div-tag">
              <p>{tag.tag}</p>
            </div>
          ))}
        </div>
        {masterClearance && (
          <button className="btn" onClick={handleDelete}>
            Borrar Documento
          </button>
        )}
      </div>
    </>
  )
}
