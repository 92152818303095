import { useCollection } from "../../hooks/useCollection"
import { useState } from "react"
import { Clearance } from "../../components/tools/Clearance"
import { useUserRoleAndSecurity } from "../../hooks/useUserRoleAndSecurity"

// components
import ProductFilter from "./ProductFilter"
import Error404 from "../error404/Error404"
import ProductDynamicTable from "../../components/ProductDynamicTable"
import ObjectList from "../../components/ObjectList"
import { Box } from "@mui/material"
// styles
import "./ProductDashboard.css"
import Section from "../../components/Section"

export default function ProductDashboard({ isMobile }) {
  const { documents, error } = useCollection("products")
  const [filter, setFilter] = useState("todos")
  const [view, setView] = useState("list")
  const [columns, setColumns] = useState([])

  const clearance = Clearance(4, 3)

  const changeFilter = (newFilter) => {
    setFilter(newFilter)
  }
  const changeView = (view) => {
    setView(view)
  }
  const newColumns = (columns) => {
    setColumns(columns)
  }

  // Step 1: Get the productLines from user role and security
  const { productLines } = useUserRoleAndSecurity()

  // Step 2: Define a filtering function for documents
  function filterDocuments(documents, filter) {
    return documents.filter((document) => {
      switch (filter) {
        case "todos":
          return true
        case "tapetes":
        case "cortina":
        case "placa":
        case "vialidades":
        case "especialidades":
          return document.category === filter
        default:
          return (
            document.name.toLowerCase().includes(filter.toLowerCase()) ||
            document.productCode.toLowerCase().includes(filter.toLowerCase()) ||
            document.assignedTagsList.some((tag) =>
              tag.tag.toLowerCase().includes(filter.toLowerCase())
            )
          )
      }
    })
  }

  // Step 3: Filter documents based on the filter
  const filteredDocuments = documents ? filterDocuments(documents, filter) : []

  // Step 4: Filter the filtered documents using the productLines
  const products = productLines
    ? filteredDocuments.filter((document) =>
        productLines.some(
          (productLine) => document.category === productLine.value
        )
      )
    : []

  console.log("Filtered products:", products)

  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Box
          sx={{
            marginTop: !isMobile ? 20 : 5,
            marginBottom: 20,
            padding:2
          }}
        >
          <div className="responsive">
            <h2 className="page-title">Product Dashboard</h2>
            {error && <p className="error">{error}</p>}
            {documents && (
              <ProductFilter
                changeFilter={changeFilter}
                changeView={changeView}
              />
            )}
            {products && view === "grid" && (
              <Section>
                <ObjectList type="products" objects={products} sortvar="name" />
              </Section>
            )}
            {products && view === "list" && (
              <ProductDynamicTable
                products={products}
                filters={columns}
                newColumns={newColumns}
                isMobile={isMobile}
              />
            )}
          </div>
        </Box>
      )}
    </>
  )
}
