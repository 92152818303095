import { useState, React, useEffect } from "react"
import { useFirestore } from "../../hooks/useFirestore"
import { useCustomerList } from "../../hooks/Customers/useCustomerList"
import SuccessModal from "../../components/SuccessModal"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import { Box } from "@mui/material"
import { Clearance } from "../../components/tools/Clearance"
import Error404 from "../error404/Error404"

const CreateCustomer = ({ isMobile, userData }) => {
  //Clientes
  const { addDocument, response } = useFirestore("clientes")
  //Empresas de clientes
  const { addDocument: addCustomerCompany, response: customerCompanyResponse } =
    useFirestore("empresasClientes")

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [telefono, setTelefono] = useState(null)
  const [empresa, setEmpresa] = useState(null)
  const [formError, setFormError] = useState(null)
  //modal
  const [showModal, setShowModal] = useState(false)

  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  //Get created customer companies
  const [assignedCompany, setAssignedCompany] = useState(null)
  const {
    customerCompanies: customersAvailable,
    eDB: mappedUserCompanies,
    companyCustomers,
  } = useCustomerList(empresa)
  //Crear nueva empresa de cliente
  const handleCreateCompany = async (inputValue) => {
    setIsLoading(true)
    setIsWaitingForResponse(true)
    let newCompany = {
      label: inputValue,
      value: inputValue,
      name: inputValue,
      owner: empresa.id,
      ownerPhoto: empresa.photoURL,
      createdBy: userData.id,
    }
    await addCustomerCompany(newCompany)
  }
  //Asign created company to the CreatableSelect after creation
  useEffect(() => {
    if (
      customerCompanyResponse.document &&
      customerCompanyResponse.document.id
    ) {
      setIsWaitingForResponse(false)
      const id = customerCompanyResponse.document.id

      // Find the empresa object with a matching ID
      const matchingEmpresa = customersAvailable?.find(
        (empresa) => empresa.id === id
      )

      if (matchingEmpresa) {
        // If a matching empresa is found, set it as the assignedCompany
        handleEmpresaCliente(matchingEmpresa)
      }
      setIsLoading(false)
    }
  }, [customerCompanyResponse, customersAvailable])

  const handleName = (e) => {
    setName(e)
  }
  const handleEmpresaCliente = (e) => {
    setAssignedCompany(e)
  }
  const handleEmail = (e) => {
    setEmail(e)
  }
  const handleTelefono = (e) => {
    setTelefono(e)
  }
  const handleClose = () => {
    setShowModal(false)
  }

  const handleCompanyChange = (option) => {
    setEmpresa(option)
    setAssignedCompany(null)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setFormError(null)

    const customer = {
      createdBy: userData.id,
      empresa,
      nombreCliente: name,
      empresaCliente: assignedCompany.id,
      empresaClienteLabel: assignedCompany.label,
      telefono,
      email,
    }

    await addDocument(customer)
    if (!response.error) {
      setShowModal(true)
      //history.push("/")
    }
  }
  const clearance = Clearance(3, 3)
  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Box sx={{ marginTop: !isMobile ? 20 : 5 }}>
          <div className="create-form">
            <h2 className="page-title">Crear Nuevo Cliente</h2>
            <form onSubmit={handleSubmit}>
              <label>
                <span>Empresa</span>
                <Select
                  onChange={(e) => handleCompanyChange(e)}
                  options={mappedUserCompanies}
                  placeholder="Empresa"
                  value={empresa}
                />
              </label>
              <label>
                <span>Nombre del Cliente:</span>
                <input
                  required
                  type="text"
                  onChange={(e) => handleName(e.target.value)}
                  value={name}
                />
              </label>
              <label>
                <span>Empresa del Cliente:</span>
                <CreatableSelect
                  autofocus="false"
                  onChange={(option) => {
                    handleEmpresaCliente(option)
                  }}
                  options={companyCustomers}
                  onCreateOption={handleCreateCompany}
                  placeholder="Empresa del Cliente"
                  value={assignedCompany}
                />
              </label>
              <label>
                <span>Email:</span>
                <input
                  required
                  type="email"
                  onChange={(e) => handleEmail(e.target.value)}
                  value={email}
                />
              </label>
              <label>
                <span>Teléfono:</span>
                <input
                  required
                  type="phone"
                  onChange={(e) => handleTelefono(e.target.value)}
                  value={telefono}
                />
              </label>
              <button className="btn">Crear Cliente</button>
              {formError && <p className="error">{formError}</p>}
            </form>
            {showModal && (
              <SuccessModal
                title="Cliente Creado"
                message=""
                onClose={handleClose}
                open={showModal}
              />
            )}
          </div>
        </Box>
      )}
    </>
  )
}

export default CreateCustomer
