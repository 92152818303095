import { useState } from "react"
import { useParams } from "react-router-dom"
import { useDocument } from "../../hooks/useDocument"
import { Link } from "react-router-dom"
import { Clearance } from "../../components/tools/Clearance"
//MUI
import { Grid, Box, Button } from "@mui/material"
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined"

// components & styles
import "./Shipments.css"
import ShipmentsComments from "./ShipmentsComments"
import ShipmentsSummary from "./ShipmentsSummary"
import EditShipments from "./EditShipments"

export default function Shipments({ userData }) {
  const { id } = useParams()
  const { document, error } = useDocument("shipments", id)
  const [edit, setEdit] = useState(false)

  const clearance = Clearance(2, 2)

  function getFirstSubstringAfterSlash(pathname) {
    const split_parts = pathname.split("/")
    const location = split_parts[1]
    return location
  }

  const pathname = window.location.pathname
  const location = getFirstSubstringAfterSlash(pathname)

  const onEdit = (editStatus) => {
    setEdit(editStatus)
  }

  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading...</div>
  }

  return (
    <>
      {!edit && (
        <Box sx={{ marginTop: 15 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Button
                component={Link}
                to="/shipments-dashboard"
                variant="outlined"
                startIcon={<ArrowBackIosNewOutlinedIcon />}
              >
                Regresar
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box sx={{ marginTop: 5 }}>
        {!edit && (
          <Grid container spacing={2}>
            <Grid item xs={11} md={7}>
              <ShipmentsSummary shipments={document} userData={userData} />
            </Grid>
            <Grid item xs={1} md={1}>
              {clearance && (
                <Button variant="outlined" onClick={() => setEdit(true)}>
                  Editar{" "}
                </Button>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <ShipmentsComments shipments={document} userData={userData} />
            </Grid>
          </Grid>
        )}
        {edit && clearance && (
          <Box>
            <EditShipments
              shipment={document}
              onEdit={onEdit}
              userData={userData}
            />
          </Box>
        )}
        <p className="no-print">{location}</p>
      </Box>
    </>
  )
}
