/**
 * CreateQuoteLogic
 *
 * This component is responsible for managing the logic of creating a quote. It handles form state,
 * customer and company data, and the submission of quote details. It utilizes various hooks for
 * data fetching and authentication, and manages the state of the quote creation process.
 *
 * Props:
 * - sendQuote (function): Function to send the created quote.
 * - cotReady (function): Callback to indicate readiness of the quote.
 * - editQuote (object): Object containing the quote details to be edited.
 *
 * Features:
 * - Creation and management of customer and company entities in the database.
 * - Form validation and error handling.
 * - Dynamic product list management in the quote form.
 * - Automatic generation of quote details based on user input and selected options.
 *
 * Usage:
 * ```jsx
 * <CreateQuoteLogic sendQuote={sendQuoteHandler} cotReady={cotReadyHandler} editQuote={quoteToEdit} />
 * ```
 */

import { useState, useEffect } from "react"
//Create new customers and customer companies
import { useFirestore } from "../../../hooks/useFirestore"
//Get reference of who created new documents in databases
//Who can access this functionalities
import { Clearance } from "../../../components/tools/Clearance"
//Visual representation of quote
import CreateQuoteView from "./CreateQuoteView"
//Limitation on customers that belong to currently assigned companies
import { useCustomerList } from "../../../hooks/Customers/useCustomerList"

//me dio hueva meter esto en la base de datos
//TODO:Poder crear unidades personalizadas en base de datos
const unidades = [
  { label: "Metros", value: "m" },
  { label: "Piezas", value: "pz" },
  { label: "Rollos", value: "r" },
]

const CreateQuoteLogic = ({ sendQuote, cotReady, editQuote, userData }) => {
  //*Function to create a new customer company in the database*/
  const { addDocument, response } = useFirestore("empresasClientes")
  //*Function to create a new customer company agent in the database*/
  const { addDocument: addCliente, response: responseCliente } =
    useFirestore("clientes")
  //*Get current user permits*/
  const clearance = Clearance(3, 3)

  //* Flags to prevent further actions */
  //fields are incomplete or incorrect
  const [formError, setFormError] = useState("")
  //data is being saved or in the process of saving in the database
  const [isLoading, setIsLoading] = useState(false)
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false)

  //* form field values when cotizacion is already in modification*/
  let cot = editQuote

  //*Initialize necessary info to fill the create quote form*/
  //TODO:failsafe when cot does not exist
  //Team to own the cotizacion object
  const [empresa, setEmpresa] = useState(cot.empresa)
  //Customer company
  const [assignedCompany, setAssignedCompany] = useState(
    cot
      ? {
          value: cot.empresaClienteID,
          label: cot.empresaCliente,
          id: cot.empresaClienteID,
        }
      : null
  )
  //Customer Company agent
  //TODO no se que vergas hice para elegir al agente de ventas
  const [companyAgent, setCompanyAgent] = useState(
    cot
      ? {
          value: cot.nombreCliente,
          label: cot.nombreCliente,
          id: cot.clienteId,
        }
      : null
  )
  const [nuevoCliente, setNuevoCliente] = useState(false)
  const [nuevoClienteCreado, setNuevoClienteCreado] = useState(false)
  const [nombreCliente, setNombreCliente] = useState(cot.nombreCliente)
  const [telefono, setTelefono] = useState(cot.telefono)
  const [email, setEmail] = useState(cot.email)
  //Just a reference for the sales person
  //TODO:Generate cotizacion number automatically
  const [cotizacion, setCotizacion] = useState(cot.cotizacion)
  const [fecha, setFecha] = useState(cot.fecha)
  const [productList, setProductList] = useState(
    cot.productList
      ? cot.productList
      : [{ producto: "", quantity: 0, index: 1, unidades: unidades[0] }]
  )
  const [details, setDetails] = useState(cot.details)
  //modal
  const [showModal, setShowModal] = useState(false)
  //form error backdrop
  const [openBackdrop, setOpenBackdrop] = useState(false)

  //Customer company data initialization
  //Get all the customers that are in the database(customerCompanies)
  //Get the teams or companies which the current user is assigned (eDB)
  //Get customers that belong to a selected company(companyCustomers), previously defined a selected company (empresa)
  //Get customers / company agents, that belong to the currently selected customer company
  const {
    customerCompanies: customersAvailable,
    eDB: mappedUserCompanies,
    companyCustomers,
    companyAgents,
  } = useCustomerList(empresa, assignedCompany)

  //*Form handlers*/
  const handleEmpresaChange = (e) => {
    setAssignedCompany(null)
    setEmpresa(e)
  }
  const handleEmpresaClienteChange = (e) => {
    let aComp = { ...e, value: e.id }
    setAssignedCompany(aComp)
    setNuevoCliente(false)
    setNuevoClienteCreado(false)
    setCompanyAgent(null)
    setNombreCliente("")
    setEmail("")
    setTelefono("")
  }

  const handleCompanyAgentChange = (e) => {
    let cAgent = { ...e, value: e.id }
    setCompanyAgent(cAgent)
    setNombreCliente(cAgent.nombreCliente)
    setEmail(cAgent.email)
    setTelefono(cAgent.telefono)
  }
  const handleNuevoCliente = () => {
    setNuevoCliente(!nuevoCliente)
  }
  const handleCotizacionChange = (e) => {
    setCotizacion(e)
  }
  const handleFechaChange = (e) => {
    setFecha(e)
  }

  const handleNombreClienteChange = (e) => {
    setNombreCliente(e)
  }
  const handleTelefonoChange = (e) => {
    setTelefono(e)
  }
  const handleEmailChange = (e) => {
    setEmail(e)
  }
  const hanldeDetailsChange = (e) => {
    setDetails(e.target.value)
  }

  //Product inputs

  const handleProductChange = (e, index) => {
    const updatedList = [...productList]
    updatedList[index].producto = e.target.value
    setProductList(updatedList)
  }

  const handleQuantityChange = (e, index) => {
    const updatedList = [...productList]
    updatedList[index].quantity = parseFloat(e.target.value)
    updatedList[index].costoTotal =
      updatedList[index].quantity * updatedList[index].precio // Calculate costoTotal
    setProductList(updatedList)
  }

  const handlePriceChange = (e, index) => {
    const updatedList = [...productList]
    updatedList[index].precio = parseFloat(e.target.value)
    updatedList[index].costoTotal =
      updatedList[index].quantity * updatedList[index].precio // Calculate costoTotal
    setProductList(updatedList)
  }

  const handleUnitChange = (e, index) => {
    const updatedList = [...productList]
    updatedList[index].unidades = e
    setProductList(updatedList)
  }

  //add product logic

  const addProductField = () => {
    const counter = productList.length + 1
    const updatedList = [...productList, { index: counter, quantity: 0 }]
    setProductList(updatedList)
  }

  const removeProductField = (index) => {
    const updatedList = [...productList]
    updatedList.splice(index, 1)
    setProductList(updatedList)
  }

  const handleClose = () => {
    setShowModal(false)
    setOpenBackdrop(false)
  }

  //*Company Creation*/
  //Unknown company is created at the CreatableSelect customer company
  const handleCreateCompany = async (inputValue) => {
    setIsLoading(true)
    setIsWaitingForResponse(true)
    let newCompany = {
      label: inputValue,
      value: inputValue,
      name: inputValue,
      owner: empresa.id,
      ownerPhoto: empresa.photoURL,
      createdBy: userData.id,
    }
    await addDocument(newCompany)
  }

  //Asign created company to the CreatableSelect
  useEffect(() => {
    if (response.document && response.document.id) {
      setIsWaitingForResponse(false)
      const id = response.document.id

      // Find the empresa object with a matching ID
      const matchingEmpresa = customersAvailable?.find(
        (empresa) => empresa.id === id
      )

      if (matchingEmpresa) {
        // If a matching empresa is found, set it as the assignedCompany
        handleEmpresaClienteChange(matchingEmpresa)
      }
      setIsLoading(false)
    }
  }, [response, customersAvailable])

  //company Agents refresh
  //*Company Agent Creation*/
  //Unknown afent is created
  const handleCreateAgent = async () => {
    if (nombreCliente.length < 1) {
      setFormError("Nombre del cliente requerido")
      setOpenBackdrop(true)
      return
    }
    if (email.length < 1) {
      setFormError("Email del cliente requerido")
      setOpenBackdrop(true)
      return
    }
    if (telefono.length < 1) {
      setFormError("Teléfono del cliente requerido")
      setOpenBackdrop(true)
      return
    }
    setIsLoading(true)
    setIsWaitingForResponse(true)
    let newAgent = {
      empresa: empresa,
      empresaCliente: assignedCompany.id,
      empresaClienteLabel: assignedCompany.label,
      nombreCliente: nombreCliente,
      telefono: telefono,
      email: email,
      createdBy: userData.id,
    }
    setNuevoClienteCreado(true)
    await addCliente(newAgent)
    console.log(responseCliente)
  }

  //*Get new customer company agent id
  useEffect(() => {
    if (responseCliente.document && responseCliente.document.id) {
      setIsWaitingForResponse(false)
      const id = responseCliente.document.id

      // Find the customer company agent object with a matching ID
      const matchingAgent = companyAgents?.find((a) => a.id === id)

      if (matchingAgent) {
        // If a matching empresa is found, set it as the assignedCompany
        handleCompanyAgentChange(matchingAgent)
      }
      setIsLoading(false)
    }
  }, [responseCliente, companyAgents])

  //*Quote document creation*/
  const handleSubmit = async (e) => {
    e.preventDefault()

    //Data validation
    if (!empresa) {
      setFormError("Empresa no seleccionada")
      setOpenBackdrop(true)
      return
    }
    if (!cotizacion) {
      setFormError("Número de cotización incompleto")
      setOpenBackdrop(true)
      return
    }
    if (!fecha) {
      setFormError("Fecha incompleta")
      setOpenBackdrop(true)
      return
    }
    if (!nombreCliente) {
      setFormError("Cliente vacío")
      setOpenBackdrop(true)
      return
    }
    if (productList[productList.length - 1].producto === "") {
      setFormError("Producto incompleto. Debes poner el nombre del producto")
      setOpenBackdrop(true)
      return
    }
    if (!productList[productList.length - 1].quantity) {
      setFormError("Producto incompleto. Debes poner la cantidad del producto")
      setOpenBackdrop(true)
      return
    }
    if (!productList[productList.length - 1].unidades) {
      setFormError("Producto incompleto. Debes poner las unidades del producto")
      setOpenBackdrop(true)
      return
    }
    if (!productList[productList.length - 1].precio) {
      setFormError(
        "Producto incompleto. Debes poner precio unitario al producto"
      )
      setOpenBackdrop(true)
      return
    }

    if (!details) {
      setFormError(
        "Comentarios vacíos. Debes poner la fecha de vigencia de la cotización o detalles necesarios"
      )
      setOpenBackdrop(true)
      return
    }

    setFormError(null)
    const totalCostSum = productList.reduce(
      (sum, product) => sum + (product.costoTotal || 0),
      0
    )
    cot = {
      createdBy: userData ? userData.id : "",
      empresa,
      cotizacion,
      fecha: fecha,
      clienteId: companyAgent.id,
      empresaClienteID: assignedCompany.id || "NA",
      empresaCliente: assignedCompany.label || "NA",
      nombreCliente,
      telefono: telefono || "NA",
      email: email || "NA",
      productList,
      details,
      totalCost: totalCostSum,
    }
    console.log("cotizacion creada con ID:", cot)
    sendQuote(cot)
    cotReady(true)
  }

  return (
    <CreateQuoteView
      // Pass all the necessary props to the view component
      clearance={clearance}
      formError={formError}
      isLoading={isLoading}
      productList={productList}
      showModal={showModal}
      openBackdrop={openBackdrop}
      empresa={empresa}
      assignedCompany={assignedCompany}
      cotizacion={cotizacion}
      fecha={fecha}
      nuevoCliente={nuevoCliente}
      nombreCliente={nombreCliente}
      telefono={telefono}
      email={email}
      details={details}
      mappedUserCompanies={mappedUserCompanies}
      companyCustomers={companyCustomers}
      companyAgents={companyAgents}
      //TODO: Que vergas hice para company agents?
      companyAgent={companyAgent}
      nuevoClienteCreado={nuevoClienteCreado}
      handleCreateAgent={handleCreateAgent}
      unidades={unidades}
      handleCreateCompany={handleCreateCompany}
      handleClose={handleClose}
      handleEmpresaChange={handleEmpresaChange}
      handleEmpresaClienteChange={handleEmpresaClienteChange}
      handleCotizacionChange={handleCotizacionChange}
      handleFechaChange={handleFechaChange}
      handleNombreClienteChange={handleNombreClienteChange}
      //TODO: Aqui está la sugerencia de cliente
      handleCompanyAgentChange={handleCompanyAgentChange}
      handleNuevoCliente={handleNuevoCliente}
      handleTelefonoChange={handleTelefonoChange}
      handleEmailChange={handleEmailChange}
      hanldeDetailsChange={hanldeDetailsChange}
      handleProductChange={handleProductChange}
      handleQuantityChange={handleQuantityChange}
      handlePriceChange={handlePriceChange}
      handleUnitChange={handleUnitChange}
      addProductField={addProductField}
      removeProductField={removeProductField}
      handleSubmit={handleSubmit}
    />
  )
}

export default CreateQuoteLogic
