import { useState } from "react"
import { HexColorPicker } from "react-colorful"
import { Clearance } from "../../components/tools/Clearance"
import Error404 from "../error404/Error404"

import { useFirestore } from "../../hooks/useFirestore"
// styles
import { Box } from "@mui/material"
import SuccessModal from "../../components/SuccessModal"

export default function Create() {
  const { addDocument, response } = useFirestore("empresas")
  const clearance = Clearance(1, 1)

  // form field values
  const [name, setName] = useState("")
  const [code, setCode] = useState("")
  const [color, setColor] = useState("#aabbcc")
  const productLines = [{ value: "dummy", label: "Dummy" }]
  const [formError, setFormError] = useState(null)
  //modal
  const [showModal, setShowModal] = useState(false)

  const handleName = (e) => {
    setName(e)
  }
  const handleCode = (e) => {
    setCode(e)
  }
  const handleColor = (e) => {
    setColor(e)
  }
  const handleClose = () => {
    setShowModal(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    setFormError(null)

    const empresa = {
      name,
      label: name,
      code,
      color,
      value: code,
      productLines: productLines,
    }

    await addDocument(empresa)
    if (!response.error) {
      setShowModal(true)
      //history.push("/")
    }
  }

  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Box sx={{ marginTop: 20 }}>
          <div className="create-form">
            <h2 className="page-title">Crear Nueva Empresa</h2>
            <form onSubmit={handleSubmit}>
              <label>
                <span>Nombre de la Empresa:</span>
                <input
                  required
                  type="text"
                  onChange={(e) => handleName(e.target.value)}
                  value={name}
                />
              </label>
              <label>
                <span>Código Empresa:</span>
                <input
                  required
                  type="text"
                  onChange={(e) => handleCode(e.target.value)}
                  value={code}
                />
              </label>
              <label>
                <span>Color Primario de la Empresa:</span>
                <HexColorPicker
                  color={color}
                  onChange={(e) => handleColor(e)}
                />
                <input
                  required
                  type="text"
                  onChange={(e) => handleColor(e.target.value)}
                  value={color}
                />
              </label>
              <button className="btn">Crear Empresa</button>
              {formError && <p className="error">{formError}</p>}
            </form>
            {showModal && (
              <SuccessModal
                title="Empresa Creada"
                message="Revisa que los datos sean correctos en el dashboard de empresas"
                onClose={handleClose}
                open={showModal}
              />
            )}
          </div>
        </Box>
      )}
    </>
  )
}
