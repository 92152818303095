import React, { useEffect, useState } from "react"
import { colorStyles } from "./selectStyles"

import Select from "react-select"
import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const ChangeStage = ({ stages, newStage }) => {
  const [stage, setStage] = useState(null)
  const handleStageChange = (option) => {
    setStage(option)
    newStage(option)
  }

  useEffect(() => {
    // This cleanup function will be called when the component is unmounted
    return () => {
      newStage(null) // Reset the stage when unmounting
    }
  }, [])

  return (
    <div style={{ display: "flex" }}>
      <Select
        onChange={(e) => handleStageChange(e)}
        options={stages}
        placeholder="Etapa"
        value={stage}
        styles={colorStyles}
      />
      <IconButton
        aria-label="delete"
        onClick={() => {
          setStage(null)
          newStage(null)
        }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  )
}

export default ChangeStage
