import React from "react"
import { Button } from "@mui/material"
import SwapVertIcon from "@mui/icons-material/SwapVert"

const CustomButton = ({ children }) => {
  return (
    <Button
      variant="contained"
      sx={{ fontSize: "0.9em" }}
      endIcon={<SwapVertIcon />}
    >
      {children}
    </Button>
  )
}

export default CustomButton
