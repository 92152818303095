import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import img from "../../assets/img/error404.png"

const Error404 = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)")
    setIsMobile(mediaQuery.matches)
  }, [])
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={"column"}
      sx={{ marginTop: !isMobile ? 15 : 5 }}
    >
      <h1 style={{ margin: "40px" }}>Permisos insuficientes</h1>
      <img src={img} alt="error404" width={"400px"} />
      <p style={{ margin: "40px" }}>
        No tienes los permisos suficientes para ver esta página. Contacta al
        administrador si crees que es un error.
      </p>
    </Box>
  )
}

export default Error404
