import React, { useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Rating from "@mui/material/Rating"
import LockIcon from "@mui/icons-material/Lock"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Typography from "@mui/material/Typography"
import ListItem from "@mui/material/ListItem"

const labels = ["NO_ACCESS", "NO_ACCESS", "READ", "UPDATE", "CREATE", "DELETE"]

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#ffc300",
  },
  "& .MuiRating-iconHover": {
    color: "#ffd60a",
  },
})

const SecuritySelector = ({
  isMobile,
  module,
  companyId,
  onPermissionChange,
}) => {

  const [value, setValue] = useState(labels?.includes(module.security) || 0)

  useEffect(() => {
    if (module) {
      setValue(module.security)
    }
  }, [module])

  const handleValueChange = (newValue) => {
    //failsafe value = null in security selector
    if (newValue != null) {
      setValue(newValue)
      module.security = labels[newValue]
      const newPermission = { companyId, module }
      onPermissionChange(newPermission)
    } else {
      setValue(1)
      module.security = labels[1]
      const newPermission = { companyId, module }
      onPermissionChange(newPermission)
    }
  }

  return (
    <ListItem sx={{ padding: 0 }}>
      <Box
        sx={{
          padding: 0,
          margin: 0,
          display: "flex",
          alignItems: "center",
          maxHeight: !isMobile ? "3rem" : "auto",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        <Typography
          variant="overline"
          component="div"
          fontSize="1rem"
          width="20vw"
        >
          Módulo de {module.label}
        </Typography>
        <StyledRating
          size="medium"
          name="customized-color"
          defaultValue={module.security}
          value={module?.security}
          getLabelText={(value) => `${labels[value]}}`}
          onChange={(event, newValue) => {
            handleValueChange(newValue)
          }}
          precision={1}
          icon={<LockIcon fontSize="inherit" />}
          emptyIcon={<LockOutlinedIcon fontSize="inherit" />}
        />
        {value !== null && (
          <Box sx={{ ml: 2, fontSize: ".8rem", color: "#6c757d" }}>
            {labels[value]}
          </Box>
        )}
      </Box>
    </ListItem>
  )
}

export default SecuritySelector
