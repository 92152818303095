export const stagesData = [
  { value: "noiniciado", label: "No Iniciado", color: "#454545" },
  { value: "planeado", label: "Planeado", color: "#009FBD" },
  { value: "endesarrollo", label: "En Desarrollo", color: "#EBB02D" },
  { value: "enrevision", label: "En Revisión", color: "#FC2947" },
  { value: "terminado", label: "Terminado", color: "#94AF9F" },
  { value: "backlog", label: "Backlog", color: "#539165" },
]

export const priorityData = [
  { value: "nr", label: "Sin prioridad", color: "#454545" },
  { value: "low", label: "Baja", color: "#539165" },
  { value: "medium", label: "Media", color: "#EBB02D" },
  { value: "high", label: "Alta🔥 ", color: "#FE0000" },
  { value: "urgent", label: "Urgente 🔥🔥", color: "#7E1717" },
]
