import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import theme from "./context/Theme"
import { ThemeProvider } from "@mui/material/styles"

//context
import { AuthContextProvider } from "./context/AuthContext"
import { UserDataContextProvider } from "./context/UserDataContext"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <AuthContextProvider>
    <UserDataContextProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </UserDataContextProvider>
  </AuthContextProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
