import { useState, useEffect, useContext } from "react"
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom"
import { useAuthContext } from "./hooks/useAuthContext"
import { UserDataContext } from "./context/UserDataContext"

//styles
import "./App.css"

//components
import PersistantDrawer from "./components/persistantDrawer/PersistantDrawer"
import CustomAppBar from "./components/CustomAppBar"
//pages

import Dashboard from "./pages/dashboard/Dashboard"
import Shipments from "./pages/shipments/Shipments"
import ShipmentsDashboard from "./pages/shipments-dashboard/ShipmentsDashboard"
import ProductDashboard from "./pages/product-dashboard/ProductDashboard"
import Create from "./pages/create/Create"
import Login from "./pages/login/Login"
import Signup from "./pages/signup/Signup"
import Project from "./pages/project/Project"
import Product from "./pages/product/Product"
import NewProduct from "./pages/newproduct/NewProduct"
import BottomMenu from "./components/BottomMenu"
import Charts from "./pages/charts/Charts"
import EditProduct from "./pages/edit/EditProduct"
import CreateShipments from "./pages/createshipment/CreateShipments"
import PrintQuote from "./pages/quotes/PrintQuote"
import Quote from "./pages/quotes/Quote"
import CreateCompany from "./pages/company/CreateCompany"
import QuoteDashboard from "./pages/quote-dashboard/QuoteDashboard"
import CreateSupplier from "./pages/shipments/CreateSupplier"
import CreatePort from "./pages/shipments/CreatePort"
import UsersDashboard from "./pages/users/UsersDashboard"
import UserProfile from "./pages/user-profile/UserProfile"
import CompanyDashboard from "./pages/company/CompanyDashboard"
import Error404 from "./pages/error404/Error404"
import Landing from "./pages/landing/Landing"
import NewLine from "./pages/newproduct/NewLine"
import MobileMenu from "./pages/mobilemenu/MobileMenu"
import UserRolesDescription from "./pages/users/UserRolesDescription"
import WarehouseDashboard from "./pages/warehouse/WarehouseDashboard"
import CreateWarehouse from "./pages/warehouse/CreateWarehouse"
import Warehouse from "./pages/warehouse/Warehouse"
import CreateSubWarehouse from "./pages/warehouse/CreateSubWarehouse"
import SubWarehouse from "./pages/warehouse/SubWarehouse"
import CustomersDashboard from "./pages/customers-dashboard/CustomersDashboard"
import Customer from "./pages/customers/Customer"
import CreateCustomer from "./pages/customers/CreateCustomer"
import Calculators from "./pages/calculators/Calculators"
import MasterDashboard from "./pages/master/MasterDashboard"
import EditUser from "./pages/users/EditUser"
import CreateModule from "./pages/modules/CreateModule"
import Tests from "./pages/tests/Tests"


function App() {
  const { user, authIsReady } = useAuthContext()
  const { userData, userCompanies } = useContext(UserDataContext)

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)")
    setIsMobile(mediaQuery.matches)
  }, [])

  return (
    <div className={`App ${isMobile ? "mobile" : ""}`}>
      {authIsReady && (
        <BrowserRouter>
          {!isMobile && user && (
            <PersistantDrawer isMobile={isMobile} userData={userData} />
          )}
          {!isMobile && !user && <CustomAppBar />}

          <Switch>
            <Route path="/landing">
              {!user && <Redirect to="/login" />}
              {user && (
                <Landing
                  isMobile={isMobile}
                  user={user.uid}
                  userData={userData}
                />
              )}
            </Route>
            <div className="container">
              <Route exact path="/">
                {!user && <Redirect to="/login" />}
                {user && (
                  <Dashboard
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/mobile-dashboard">
                {!user && <Redirect to="/login" />}
                {user && (
                  <MobileMenu
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/create-module">
                {!user && <Redirect to="/login" />}
                {user && (
                  <CreateModule
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/product-dashboard">
                {!user && <Redirect to="/login" />}
                {user && (
                  <ProductDashboard
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/shipments-dashboard">
                {!user && <Redirect to="/login" />}
                {user && (
                  <ShipmentsDashboard
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/create">
                {!user && <Redirect to="/login" />}
                {user && (
                  <Create
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/createshipments">
                {!user && <Redirect to="/login" />}
                {user && (
                  <CreateShipments
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/newproduct">
                {!user && <Redirect to="/login" />}
                {user && (
                  <NewProduct
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/projects/:id">
                {!user && <Redirect to="/login" />}
                {user && (
                  <Project
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/products/:id">
                {!user && <Redirect to="/login" />}
                {user && (
                  <Product
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/new-product-line">
                {!user && <Redirect to="/login" />}
                {user && (
                  <NewLine
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/shipments/:id">
                {!user && <Redirect to="/login" />}
                {user && (
                  <Shipments
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/create-suppliers">
                {!user && <Redirect to="/login" />}
                {user && (
                  <CreateSupplier
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/create-ports">
                {!user && <Redirect to="/login" />}
                {user && (
                  <CreatePort
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/login">
                {!user && <Login />}
                {user && <Redirect to="/profile" />}
              </Route>
              <Route path="/signup">
                {!user && <Signup />}
                {user && <Redirect to="/profile" />}
              </Route>
              <Route path="/charts">
                {!user && <Redirect to="/login" />}
                {user && (
                  <Charts
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/edit">
                {!user && <Redirect to="/login" />}
                {user && (
                  <EditProduct
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/cotizaciones-dashboard">
                {!user && <Redirect to="/login" />}
                {user && (
                  <QuoteDashboard
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/customers-dashboard">
                {!user && <Redirect to="/login" />}
                {user && (
                  <CustomersDashboard
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/customer/:id">
                {!user && <Redirect to="/login" />}
                {user && (
                  <Customer
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/create-customer">
                {!user && <Redirect to="/login" />}
                {user && (
                  <CreateCustomer
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/cotizaciones/:id">
                {!user && <Redirect to="/login" />}
                {user && (
                  <Quote
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/create-quote">
                {!user && <Redirect to="/login" />}
                {user && (
                  <PrintQuote
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/create-company">
                {!user && <Redirect to="/login" />}
                {user && (
                  <CreateCompany
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/company-dashboard">
                {!user && <Redirect to="/login" />}
                {user && (
                  <CompanyDashboard
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/users-dashboard">
                {!user && <Redirect to="/login" />}
                {user && (
                  <UsersDashboard
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/edit-user/:id">
                {!user && <Redirect to="/login" />}
                {user && (
                  <EditUser
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/users-roles">
                {!user && <Redirect to="/login" />}
                {user && (
                  <UserRolesDescription
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/profile">
                {!user && <Redirect to="/login" />}
                {user && (
                  <UserProfile
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/almacenes-dashboard">
                {!user && <Redirect to="/login" />}
                {user && (
                  <WarehouseDashboard
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/almacenes/:id">
                {!user && <Redirect to="/login" />}
                {user && (
                  <Warehouse
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/subalmacenes/:id">
                {!user && <Redirect to="/login" />}
                {user && (
                  <SubWarehouse
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/create-warehouse">
                {!user && <Redirect to="/login" />}
                {user && (
                  <CreateWarehouse
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/create-subwarehouse">
                {!user && <Redirect to="/login" />}
                {user && (
                  <CreateSubWarehouse
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/cotizadores">
                {!user && <Redirect to="/login" />}
                {user && (
                  <Calculators
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/master">
                {!user && <Redirect to="/login" />}
                {user && (
                  <MasterDashboard
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                    userCompanies={userCompanies}
                  />
                )}
              </Route>
              <Route path="/error404">
                {!user && <Redirect to="/login" />}
                {user && (
                  <Error404
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              <Route path="/test">
                {!user && <Redirect to="/login" />}
                {user && (
                  <Tests
                    isMobile={isMobile}
                    user={user.uid}
                    userData={userData}
                  />
                )}
              </Route>
              
            </div>
          </Switch>

          {isMobile && user && (
            <BottomMenu userData={userData} isMobile={isMobile} />
          )}
        </BrowserRouter>
      )}
    </div>
  )
}

export default App
