import { useCollection } from "../../hooks/useCollection"
import { useState } from "react"

// styles
import "../product-dashboard/ProductDashboard.css"
import ProductEditableTable from "../../components/ProductEditableTable"
import { Box } from "@mui/material"
import { Clearance } from "../../components/tools/Clearance"
import Error404 from "../error404/Error404"

export default function EditProduct() {
  const { documents, error } = useCollection("products")
  const [filter, setFilter] = useState("todos")
  const [view, setView] = useState("list")


  const clearance = Clearance(2, 2)

  const changeFilter = (newFilter) => {
    setFilter(newFilter)
  }
  const changeView = (view) => {
    setView(view)
  }

  const products = documents
    ? documents.filter((document) => {
        switch (filter) {
          case "todos":
            return true
          case "tapetes":
          case "cortina":
          case "placa":
          case "vialidades":
          case "especialidades":
            return document.category === filter
          default:
            return (
              document.name.toLowerCase().includes(filter.toLowerCase()) ||
              document.productCode
                .toLowerCase()
                .includes(filter.toLowerCase()) ||
              document.assignedTagsList
                .map((tag) =>
                  tag.tag.toLowerCase().includes(filter.toLowerCase())
                )
                .includes(true)
            )
        }
      })
    : null

  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Box sx={{ marginTop: 20 }}>
          <div className="responsive">
            <h2 className="page-title">Editar Productos</h2>
            {error && <p className="error">{error}</p>}
            {/*documents && <ProductFilter changeFilter={changeFilter} changeView={changeView} />*/}
            {products && view === "list" && (
              <ProductEditableTable products={products} />
            )}
          </div>
        </Box>
      )}
    </>
  )
}
