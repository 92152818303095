import React from "react"
import { styled } from "@mui/material/styles"
import Badge from "@mui/material/Badge"
import Avatar from "@mui/material/Avatar"
import Stack from "@mui/material/Stack"
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined"
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined"



const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}))

const BadgeAvatar = ({img, isMobile}) => {
  return (
    <Stack direction="row" spacing={2}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        
        badgeContent={
          <SmallAvatar>
            {!isMobile ? <KeyboardArrowDownOutlinedIcon />: <KeyboardArrowUpOutlinedIcon/>}
          </SmallAvatar>
        }
      >
        <Avatar alt="Remy Sharp" src={img} sx={{width: '48px', height:'48px'}}/>
      </Badge>
    </Stack>
  )
}
export default BadgeAvatar