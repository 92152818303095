import React, { useEffect, useState } from "react"
import { colorStyles } from "./selectStyles"

import Select from "react-select"
import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

const ChangePriority = ({ priorities, newPriority }) => {
  const [priority, setPriority] = useState(null)
  const handleStageChange = (option) => {
    setPriority(option)
    newPriority(option)
  }

  useEffect(() => {
    // This cleanup function will be called when the component is unmounted
    return () => {
      newPriority(null) // Reset the stage when unmounting
    }
  }, [])

  return (
    <div style={{ display: "flex" }}>
      <Select
        onChange={(e) => handleStageChange(e)}
        options={priorities}
        placeholder="Prioridad"
        value={priority}
        styles={colorStyles}
      />
      <IconButton
        aria-label="delete"
        onClick={() => {
          setPriority(null)
          newPriority(null)
        }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  )
}

export default ChangePriority
