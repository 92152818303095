import React from "react"

import { Button, Grid } from "@mui/material"
import GridViewIcon from "@mui/icons-material/GridView"
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline"

const ChangeView = ({ handleViewChange, currentView, select, stageChange }) => {
  return (
    <>
      <Grid item xs={4} md={1}>
        <Button
          key={"grid"}
          onClick={() => handleViewChange("grid")}
          variant="contained"
          sx={{
            background: currentView === "grid" ? "#14213d" : "#fff",
            color: currentView === "grid" ? "#fff" : "#14213d",
          }}
          startIcon={
            <GridViewIcon color={currentView === "grid" ? "#fff" : "#14213d"} />
          }
        >
          Grid
        </Button>
      </Grid>
      <Grid item xs={4} md={1}>
        <Button
          key={"list"}
          onClick={() => handleViewChange("list")}
          variant="contained"
          sx={{
            background: currentView === "list" ? "#14213d" : "#fff",
            color: currentView === "list" ? "#fff" : "#14213d",
          }}
          startIcon={
            <ViewHeadlineIcon
              color={currentView === "list" ? "#fff" : "#14213d"}
            />
          }
        >
          Lista
        </Button>
      </Grid>
    </>
  )
}

export default ChangeView
