export const stagesData = [
  { value: "pedido", label: "Pedido China", color: "#454545" },
  { value: "buque", label: "Esperando Buque", color: "#0C356A" },
  { value: "mar", label: "En Mar", color: "#009FBD" },
  { value: "llegando", label: "14 a Puerto", color: "#EBB02D" },
  { value: "puerto", label: "En Puerto", color: "#FC2947" },
  { value: "pantaco", label: "En Pantaco", color: "#F94C10" },
  { value: "enviado", label: "En Tierra", color: "#94AF9F" },
  { value: "recibido", label: "Recibido", color: "#539165" },
]


