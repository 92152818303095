import React, { useEffect, useState } from "react"
import { useCollection } from "../../hooks/useCollection"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"

const ShipmentsProducts = () => {
  const { documents, error } = useCollection("shipments")
  const [productList, setProductList] = useState([])
  const [combinedData, setCombinedData] = useState([])

  useEffect(() => {
    if (documents) {
      const products = documents.map((doc) => {
        return {
          id: doc.id,
          name: doc.name,
          eta: new Date(doc.dueDate.seconds * 1000),
          link: doc.id,
          productList: doc.productList,
        }
      })
      setProductList(products)
      if(error){
        console.log("shipments products error: ", error)
      }
    }
  }, [documents, error])

  useEffect(() => {
    const combinedMap = new Map()
    productList.forEach((item) => {
      item.productList.forEach((prod) => {
        const key = prod.productCode
        if (combinedMap.has(key)) {
          const existingItem = combinedMap.get(key)
          existingItem.quantity += prod.quantity
          existingItem.names.push(item.name)
          existingItem.quantities.push(prod.quantity)
          existingItem.etas.push(item.eta)
        } else {
          combinedMap.set(key, {
            productCode: prod.productCode,
            productName: prod.name,
            quantity: prod.quantity,
            names: [item.name],
            quantities: [prod.quantity],
            etas: [item.eta],
          })
        }
      })
    })

    const combinedArray = Array.from(combinedMap.values())
    //combinedArray.sort((a, b) => a.productCode.localeCompare(b.productCode)) // Sort by productCode

    setCombinedData(combinedArray)
  }, [productList])

  const columns = [
    { field: "productCode", headerName: "Código", width: 200 },
    { field: "productName", headerName: "Nombre", width: 300 },
    { field: "quantity", headerName: "Total", width: 90 },
    {
      field: "names",
      headerName: "Importaciones",
      width: 250,
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {params.value.map((name, index) => (
            <div key={index}>{name}</div>
          ))}
        </div>
      ),
    },
    {
      field: "quantities",
      headerName: "Cantidades",
      width: 200,
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {params.value.map((quantity, index) => (
            <div key={index}>{quantity}</div>
          ))}
        </div>
      ),
    },
    {
      field: "etas",
      headerName: "ETAs",
      width: 200,
      renderCell: (params) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {params.value.map((eta, index) => (
            <div key={index}>
              {eta.toLocaleDateString("es-MX", {
                day: "2-digit",
                month: "short",
              })}
            </div>
          ))}
        </div>
      ),
    },
  ]

  const rows = combinedData.map((item, index) => {
    return {
      id: index,
      productCode: item.productCode,
      productName: item.productName,
      quantity: item.quantity,
      names: item.names,
      quantities: item.quantities,
      etas: item.etas,
    }
  })

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        className="datagrid"
        slots={{ toolbar: GridToolbar }}
        getRowHeight={() => "auto"}
        rows={rows}
        columns={columns}
        pageSize={20}
        autoHeight
      />
    </div>
  )
}

export default ShipmentsProducts
