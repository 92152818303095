import React from "react"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { Button, Grid } from "@mui/material"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
}

function SuccessModal(props) {
  const { open, onClose, title, message, button, onOK } = props

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="no-print"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {message}
        </Typography>
        {button && (
          <>
            <Grid container spacing={2} sx={{ marginTop: "20px" }}>
              <Grid item md={6}>
                <Button
                  color="success"
                  variant="outlined"
                  onClick={() => onOK(true)}
                >
                  OK
                </Button>
              </Grid>
              <Grid item md={6}>
                <Button color="error" variant="outlined" onClick={onClose}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  )
}

export default SuccessModal
