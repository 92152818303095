export const PCORTES = 24.0
export const PHERRAJES = 120.0
export const PLAINAS = 32.0
export const PTORNILLOS = 6.0

export const ANCHOS = [
  { label: "2x20", value: "2x20" },
  { label: "3x30", value: "3x30" },
  { label: "4x40", value: "4x40" },
]

export const HW2X20 = [
  { label: "Lisa Transparente", value: "HW2X20" },
  { label: "Costilla", value: "HW2X20COS" },
  { label: "Ambar", value: "HW2X20AMB" },
  { label: "Costilla Polar", value: "HW2X20COS-POL" },
  { label: "Antiestática", value: "HW2X20EST" },
  { label: "Anti Insectos", value: "HW2X20INS" },
  { label: "Roja Transparente", value: "HW2X20TR" },
  { label: "Color Sólido", value: "HW2X20CLR" },
]

export const HW3X30 = [
  { label: "Lisa Transparente", value: "HW3X30" },
  { label: "Costilla", value: "HW3X30COS" },
  { label: "Ambar", value: "HW3X30AMB" },
  { label: "Costilla Polar", value: "HW3X30COS-POL" },
  { label: "Antiestática", value: "HW3X30EST" },
  { label: "Anti Insectos", value: "HW3X30INS" },
]

export const HW4X40 = [
  { label: "Lisa Transparente", value: "HW4X40" },
  { label: "Costilla", value: "HW4X40COS" },
]
