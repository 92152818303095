import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useCollection } from "../../hooks/useCollection"
import { useUserRoleAndSecurity } from "../../hooks/useUserRoleAndSecurity"
import { timestamp } from "../../firebase/config"
import Error404 from "../../pages/error404/Error404"
import { Box } from "@mui/material"
import CustomButton from "./CustomButton"
import SortableTableFilter from "./SortableTableFilter"


const SortableTable = ({
  isMobile,
  collection,
  fields,
  texts,
  path,
  clearance,
}) => {
  //HOOKS
  const { documents } = useCollection(collection)
  const { currentCompanyList, errorDoc } = useUserRoleAndSecurity()
  //console.log("objects: ", documents)

  //useState Vars
  const [filter, setFilter] = useState("todos")
  const [searchTerm, setSearchTerm] = useState("")
  const [filteredCollection, setFilteredCollection] = useState(null)
  const [sortState, setSortState] = useState({
    column: null,
    direction: "asc",
  })

  //Functions
  const formatMoney = (value) => {
    const number = Number(value)
    return isNaN(number)
      ? ""
      : number.toLocaleString("es-MX", {
          style: "currency",
          currency: "MXN",
          maximumFractionDigits: "2",
        })
  }
  const changeFilter = (newFilter) => {
    setSearchTerm("")
    setFilter(newFilter)
  }

  const handleSearch = (e) => {
    setFilter("todos")
    setSearchTerm(e.target.value)
  }

  const handleSort = (property) => {
    const isSameColumn = sortState.column === property
    const direction = isSameColumn
      ? sortState.direction === "asc"
        ? "desc"
        : "asc"
      : "asc"

    const sortedList = [...documents].sort((a, b) => {
      const valueA = getProperty(a, property)
      const valueB = getProperty(b, property)
      if (valueA < valueB) return direction === "asc" ? -1 : 1
      if (valueA > valueB) return direction === "asc" ? 1 : -1
      return 0
    })

    setFilteredCollection(sortedList)
    setSortState({ column: property, direction })
  }

  const getProperty = (object, propertyPath) => {
    if (!object || !propertyPath) return ""

    return propertyPath.split(".").reduce((acc, part) => {
      if (acc && acc.hasOwnProperty(part)) {
        return acc[part]
      }
      return ""
    }, object)
  }

  const docs = filteredCollection
    ? filteredCollection
        .filter((document) => {
          switch (filter) {
            case "todos":
              return true
            default:
              return document.empresa.name === filter
          }
        })
        .filter((document) => {
          if (!searchTerm) {
            return true
          }
          return fields.some(
            (f) =>
              typeof document[f.value] === "string" &&
              document[f.value].toLowerCase().includes(searchTerm.toLowerCase())
          )
        })

        .sort(
          (a, b) =>
            timestamp.fromDate(new Date(b.createdAt)) -
            timestamp.fromDate(new Date(a.createdAt))
        )
    : null

  useEffect(() => {
    if (documents && currentCompanyList) {
      const filteredObjects = documents.filter((document) => {
        return currentCompanyList.some(
          (company) => company.value === document.empresa.value
        )
      })
      setFilteredCollection(filteredObjects)
    } else {
      console.error("No documents to show: ", errorDoc)
    }
  }, [documents, currentCompanyList, errorDoc])

  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Box>
          <h2 className="page-title">{texts.title}</h2>
          <Box>
            {filteredCollection && (
              <SortableTableFilter changeFilter={changeFilter} />
            )}
          </Box>
          <Box>
            <label>
              Búsqueda Avanzada
              <input
                type="text"
                onChange={handleSearch}
                value={searchTerm}
              ></input>
            </label>
          </Box>
          {docs ? (
            <Box
              sx={{ maxWidth: "100%", overflowX: "scroll", marginBottom: 5 }}
            >
              <table className="styled-table" style={{ width: "100%" }}>
                <thead>
                  <tr key="header">
                    {fields?.map((f) => (
                      <td
                        key={f.value}
                        width={f.width}
                        onClick={() => handleSort(f.value)}
                      >
                        <CustomButton>{f.label}</CustomButton>
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {docs.map((doc) => (
                    <tr key={doc.id}>
                      {fields
                        .filter((f) => f.link)
                        .map((f) => (
                          <td
                            style={{ fontWeight: "bold" }}
                            key={`${doc.id}-${f.value}`}
                          >
                            <Link to={`/${path}/${doc.id}`}>
                              {getProperty(doc, f.value)}
                            </Link>
                          </td>
                        ))}

                      {fields
                        .filter((f) => !f.link)
                        .map((f) => (
                          <td key={`${doc.id}-${f.value}`}>
                            {f.type === "money"
                              ? formatMoney(getProperty(doc, f.value))
                              : getProperty(doc, f.value)}
                          </td>
                        ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          ) : (
            <p>{texts.error}</p>
          )}
        </Box>
      )}
    </>
  )
}

export default SortableTable
