import { ListItem } from '@mui/material'
import React from 'react'

const LiItem = ({ children }) => {
    return (
      <ListItem>
        <label style={{ margin: 0 }}>{children}</label>
      </ListItem>
    )
  }

export default LiItem