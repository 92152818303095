import React, { useEffect, useState } from "react"
import { useFirestore } from "../hooks/useFirestore"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import DialogTitle from "@mui/material/DialogTitle"
import Dialog from "@mui/material/Dialog"

const PopUpMenu = ({ open, handleOpen, userData, currentCo }) => {
  // Adjusted to use handleClose from props
  const [availableCompanies, setAvailableCompanies] = useState([])
  const [activeCompany, setActiveCompany] = useState(
    userData?.activeCompany || null
  )
  const { updateDocument, response } = useFirestore("users")

  useEffect(() => {
    if (userData && userData.companiesList) {
      const aC = userData.companiesList.map((cl) => ({
        id: cl.id,
        label: cl.label,
        key: cl.key,
      }))
      setAvailableCompanies(aC)
      if (userData.activeCompany == null) {
        setActiveCompany(aC.length > 0 ? aC[0] : null) // Check for length before assigning
        currentCo(aC.length > 0 ? aC[0] : null)
        handleUpdateUser(aC.length > 0 ? aC[0] : null)
      } else {
        setActiveCompany(userData.activeCompany) // Check for length before assigning
        currentCo(userData.activeCompany)
      }
    }
  }, [userData])

  const handleUpdateUser = async (ac) => {
    await updateDocument(userData.id, { activeCompany: ac })
    if (response.error) {
      console.log("Unsuccessfully updated active company in db, error: ", response.error)
    }
  }

  const handleListItemClick = (ac) => {
    handleOpen() // Use handleClose from props
    setActiveCompany(ac)
    currentCo(ac)
    handleUpdateUser(ac)
  }

  //console.log("Active company: ", activeCompany)

  return (
    <Dialog onClose={handleOpen} open={open}>
      <DialogTitle>Empresas Disponibles</DialogTitle>
      <List sx={{ pt: 0, maxHeight: "250px", overflowY: "scroll" }}>
        {availableCompanies.map((ac) => (
          <ListItem disableGutters key={ac.id}>
            <ListItemButton onClick={() => handleListItemClick(ac)}>
              <ListItemText primary={ac.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

export default PopUpMenu
