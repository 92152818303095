import React, { useState } from "react"

import UserSummary from "./UserSummary"
import EditUser from "./EditUser"
import { Box, Grid } from "@mui/material"

const UserProfile = ({ isMobile, userData }) => {
  const [edit, setEdit] = useState(false)

  const onEdit = (e) => {
    setEdit(e)
  }

  return (
    <>
      {userData && (
        <Box
          sx={{
            marginTop: !isMobile ? 20 : 5,
            marginBottom: !isMobile ? 5 : 10,
            padding: 2,
          }}
        >
          <Grid container spacing={2}>
            {!edit && (
              <Grid item xs={12} md={12}>
                <UserSummary
                  onEdit={onEdit}
                  user={userData}
                  isMobile={isMobile}
                  userData={userData}
                />
              </Grid>
            )}
            {edit && (
              <Grid item xs={12} md={12}>
                <EditUser onEdit={onEdit} user={userData} isMobile={isMobile} />
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  )
}
export default UserProfile
