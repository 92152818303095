import { useEffect, useState } from "react"
import { useAuthContext } from "./useAuthContext"
import { useDocument } from "./useDocument"
import { useCollection } from "./useCollection"

export const useUserRoleAndSecurity = () => {
  const { user } = useAuthContext()
  const currentUser = user ? user.uid : null
  const { document: documentCurrentUser, error: errorDoc } = useDocument(
    "users",
    currentUser
  )
  const { documents: companyDocuments } = useCollection("empresas")

  const [productLines, setProductLines] = useState(null)

  const [currentRole, setCurrentRole] = useState(null)
  const [currentSecurity, setCurrentSecurity] = useState(null)
  const [currentCompanyList, setCurrentCompanyList] = useState(null)

  useEffect(() => {
    if (documentCurrentUser) {
      setCurrentRole(documentCurrentUser.role)
      setCurrentSecurity(documentCurrentUser.security)
      setCurrentCompanyList(documentCurrentUser.companiesList)
    }
  }, [documentCurrentUser])

  useEffect(() => {
    if (companyDocuments && currentCompanyList) {
      const matchingCompanyIds = currentCompanyList.map((company) => company.id)

      const filteredCompanyDocuments = companyDocuments.filter((cd) =>
        matchingCompanyIds.includes(cd.id)
      )

      const productLines = [].concat(
        ...filteredCompanyDocuments.map((cd) => cd.productLines)
      )

      const uniqueProductLines = productLines.filter(
        (item, index, array) => array.indexOf(item) === index
      )

      setProductLines(uniqueProductLines)
    }
  }, [companyDocuments, currentCompanyList])

  return {
    currentRole,
    currentSecurity,
    currentCompanyList,
    productLines,
    errorDoc,
  }
}
