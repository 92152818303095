import { useState, useEffect } from "react"
import { useCollection } from "../../hooks/useCollection"
import { timestamp } from "../../firebase/config"
import { useFirestore } from "../../hooks/useFirestore"
import Select from "react-select"
// styles
import "./CreateShipments.css"
import { colorStyles } from "../../components/selectStyles"
import DeleteIcon from "@mui/icons-material/Delete"
import IconButton from "@mui/material/IconButton"
import { Box, Grid } from "@mui/material"
import SuccessModal from "../../components/SuccessModal"

const categories = [
  { value: "pedido", label: "Pedido China", color: "#454545" },
  { value: "buque", label: "Esperando Buque", color: "#0C356A" },
  { value: "mar", label: "En Mar", color: "#009FBD" },
  { value: "llegando", label: "14 a Puerto", color: "#EBB02D" },
  { value: "puerto", label: "En Puerto", color: "#FC2947" },
  { value: "pantaco", label: "En Pantaco", color: "#F94C10" },
  { value: "enviado", label: "En Tierra", color: "#94AF9F" },
  { value: "recibido", label: "Recibido", color: "#539165" },
]
const contenedor = [
  { value: "LCL", label: "LCL" },
  { value: "FCL-20", label: "FCL 20" },
  { value: "FCL-40", label: "FCL 40" },
]
const modality = [
  { value: "ferro-camion", label: "Ferro Camión" },
  { value: "barco-camion", label: "Barco Camión" },
]

export default function CreateShipments({userData}) {
  const { addDocument, response } = useFirestore("shipments")
  const { documents } = useCollection("users")
  const { documents: productsFromDB } = useCollection("products")
  const { documents: portsFromDB } = useCollection("ports")
  const { documents: suppliersFromDB } = useCollection("suppliers")

  const [users, setUsers] = useState([])

  // form field values
  const [name, setName] = useState("")
  const [details, setDetails] = useState("")
  const [dueDate, setDueDate] = useState(null)
  const [category, setCategory] = useState("")

  const [etd, setEtd] = useState(null)
  const [supplier, setSupplier] = useState(null)
  const [outboundPort, setOutboundPort] = useState(null)
  const [inboundPort, setInboundPort] = useState(null)
  const [mode, setMode] = useState(null)
  const [container, setContainer] = useState(null)
  //from db
  const [productDB, setProductDB] = useState("")
  const [portDB, setPorttDB] = useState("")
  const [supplierDB, setSupplierDB] = useState("")
  const [assignedUsers, setAssignedUsers] = useState([])

  const [formError, setFormError] = useState(null)
  const [productList, setProductList] = useState([{ product: null }])
  //modal
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (productsFromDB) {
      const productDB = productsFromDB.map((product) => {
        return {
          id: product.id,
          productCode: product.productCode,
          value: product.id,
          name: product.name,
          label: product.name,
        }
      })
      setProductDB(productDB)
    }
  }, [productsFromDB])
  useEffect(() => {
    if (portsFromDB) {
      const data = portsFromDB.map((port) => {
        return {
          id: port.id,
          value: port.value,
          label: port.label,
        }
      })
      setPorttDB(data)
    }
  }, [portsFromDB])

  useEffect(() => {
    if (suppliersFromDB) {
      // Create a new supplier object
      const newSupplier = {
        id: "1",
        value: "nd",
        label: "no definido",
      }

      // Map existing suppliers and append the new supplier
      const updatedData = suppliersFromDB.map((supplier) => ({
        id: supplier.id,
        value: supplier.value,
        label: supplier.label,
      }))
      updatedData.push(newSupplier)

      setSupplierDB(updatedData)
    }
  }, [suppliersFromDB])

  useEffect(() => {
    if (documents) {
      const options = documents.map((user) => {
        return { value: user, label: user.displayName }
      })
      setUsers(options)
    }
  }, [documents])

  const handleEtdChange = (e) => {
    setEtd(e)
  }
  const handleModalityChange = (e) => {
    setMode(e)
  }
  const handleContainerChange = (e) => {
    setContainer(e)
  }
  const handleSupplierChange = (e) => {
    setSupplier(e)
  }
  const handleOutboundPortChange = (e) => {
    setOutboundPort(e)
  }
  const handleInboundPortChange = (e) => {
    setInboundPort(e)
  }

  const handleProductChange = (option, index) => {
    const updatedList = [...productList]
    updatedList[index] = {
      ...option,
      quantity: updatedList[index] ? updatedList[index].quantity : 0,
    }
    setProductList(updatedList)
  }

  const handleQuantityChange = (e, index) => {
    const updatedList = [...productList]
    updatedList[index].quantity = parseInt(e.target.value)
    setProductList(updatedList)
  }

  const addProductField = () => {
    const updatedList = [...productList, { index: null, quantity: 0 }]
    setProductList(updatedList)
  }

  const removeProductField = (index) => {
    const updatedList = [...productList]
    updatedList.splice(index, 1)
    setProductList(updatedList)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    //avoid error for Select components
    setFormError(null)

    if (!category) {
      setFormError("Por favor, selecciona la etapa del contenedor")
      return
    }
    if (!supplier) {
      //override
      setFormError("Por favor, selecciona proveedor o selecciona no definido")
      return
    }
    //empty array is a value
    if (assignedUsers.length < 1) {
      setFormError("Por favor asigna la importacion a al menos una persona")
      return
    }
    //creator of the project
    const createdBy = {
      displayName: userData.displayName,
      photoURL: userData.photoURL,
      id: userData.id,
    }
    //Transforming user(s) assigned to the project to something less complex
    const assignedUsersList = assignedUsers.map((u) => {
      return {
        displayName: u.value.displayName,
        photoURL: u.value.photoURL,
        id: u.value.id,
      }
    })

    const shipments = {
      name,
      details,
      category,
      dueDate: timestamp.fromDate(new Date(dueDate)),
      lastMod: timestamp.fromDate(new Date()),
      comments: [],
      createdBy,
      productList,
      assignedUsersList,
      inboundPort,
      outboundPort,
      modality: mode,
      etd: timestamp.fromDate(new Date(etd)),
      supplier: supplier,
      container: container,
    }

    await addDocument(shipments)
    if (!response.error) {
      setShowModal(true)
    }
  }

  return (
    <Box sx={{ marginTop: 20 }}>
      <div className="container">
        <h2 className="page-title">Create a new Shipment</h2>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <label>
                <span>ID del contenedor:</span>
                <input
                  required
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </label>
            </Grid>

            <Grid item xs={12} md={4}>
              <label>
                <span>ETD:</span>
                <input
                  required
                  type="date"
                  onChange={(e) => handleEtdChange(e.target.value)}
                  value={etd}
                />
              </label>
            </Grid>

            <Grid item xs={12} md={4}>
              {" "}
              <label>
                Proveedor:
                <Select
                  onChange={(e) => handleSupplierChange(e)}
                  options={supplierDB}
                  placeholder="Seleccionar Proveedor"
                  value={supplier}
                />
              </label>
            </Grid>
            <Grid item xs={12} md={4}>
              <label>
                Puerto de Salida:
                <Select
                  onChange={(e) => handleOutboundPortChange(e)}
                  options={portDB}
                  placeholder="Seleccionar Puerto de Salida"
                  value={outboundPort}
                />
              </label>
            </Grid>
            <Grid item xs={12} md={4}>
              <label>
                Tipo de Contenedor:
                <Select
                  onChange={(e) => handleContainerChange(e)}
                  options={contenedor}
                  placeholder="Seleccionar Contenedor"
                  value={container}
                />
              </label>
            </Grid>
            <Grid item xs={12} md={4}>
              <label>
                <span>Estado del contenedor:</span>
                <Select
                  onChange={(option) => setCategory(option)}
                  options={categories}
                  styles={colorStyles}
                />
              </label>
            </Grid>
          </Grid>

          {/*Packing List*/}
          <h3 className="page-title">Packing List</h3>

          <Box sx={{ marginTop: 5, marginBottom: 5 }}>
            <Grid container spacing={2}>
              {productList.map((row, index) => (
                <>
                  <Grid md={5} sm={12} sx={{ padding: "10px" }}>
                    <Select
                      onChange={(option) => handleProductChange(option, index)}
                      options={productDB}
                      placeholder="Seleccionar producto"
                    />
                  </Grid>
                  <Grid md={5} sm={12} sx={{ padding: "10px" }}>
                    <input
                      required
                      type="number"
                      min="1"
                      onChange={(e) => handleQuantityChange(e, index)}
                      value={row.quantity}
                    />
                  </Grid>
                  {index > 0 && (
                    <Grid md={2} sm={1} sx={{ padding: "10px" }}>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => removeProductField(index)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
            <button className="btn" onClick={addProductField}>
              Agregar otro producto
            </button>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <label>
                Puerto de Llegada:
                <Select
                  onChange={(e) => handleInboundPortChange(e)}
                  options={portDB}
                  placeholder="Seleccionar Puerto de Llegada"
                  value={inboundPort}
                />
              </label>
            </Grid>
            <Grid item xs={12} md={4}>
              <label>
                <span>ETA:</span>
                <input
                  required
                  type="date"
                  onChange={(e) => setDueDate(e.target.value)}
                  value={dueDate}
                />
              </label>
            </Grid>
            <Grid item xs={12} md={4}>
              <label>
                Modalidad de transporte:
                <Select
                  onChange={(e) => handleModalityChange(e)}
                  options={modality}
                  placeholder="Seleccionar Modalidad"
                  value={mode}
                />
              </label>
            </Grid>
            <Grid item xs={12} md={12}>
              <label>
                <span>Responsable:</span>
                <Select
                  onChange={(option) => setAssignedUsers(option)}
                  options={users}
                  isMulti
                />
              </label>
            </Grid>
            <Grid item xs={12} md={12}>
              <label>
                <span>Detalles del contenedor (opcional):</span>
                <textarea
                  type="text"
                  onChange={(e) => setDetails(e.target.value)}
                  value={details}
                />
              </label>
            </Grid>
          </Grid>

          <button className="btn">Crear Importacion</button>
          {showModal && (
            <SuccessModal
              title="Importación Creada"
              message="Revisa que se haya creado con éxito en la pantalla de importaciones"
              onClose={handleClose}
              open={showModal}
            />
          )}

          {formError && <p className="error">{formError}</p>}
        </form>
      </div>
    </Box>
  )
}
