import { useState, useEffect } from "react"
import firebase from "firebase/app"
import "firebase/auth" // Make sure to import the authentication module

export const useUpdateUser = () => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    // Set up a listener to watch for changes in the user's authentication state
    const unsubscribe = firebase.auth().onAuthStateChanged((currentUser) => {
      setUser(currentUser)
    })
    // Clean up the listener when the component unmounts
    return () => unsubscribe()
  }, [])

  const updateProfile = async (newProfileData) => {
    if (user) {
      try {
        // Update the user's profile with new data
        await user.updateProfile(newProfileData)
        setUser({ ...user, ...newProfileData }) // Update the local state with new data
        console.log("uuu setUser: ", user)
      } catch (error) {
        console.error("Error updating user profile:", error)
      }
    }
  }

  return { user, updateProfile }
}
