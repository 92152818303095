import React from "react"
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"


const CompanySummary = ({ e, onEdit }) => {
  return (
      <ListItem
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="edit"
            onClick={() => {
              onEdit(true, e)
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <Avatar alt={e.label} src={e.photoURL} />
        </ListItemAvatar>
        <ListItemText primary={e.name} secondary={`Módulos: ${e.modules.length}`} />
      </ListItem>
  )
}

export default CompanySummary
