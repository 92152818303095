import Avatar from "../../components/Avatar"
import { useFirestore } from "../../hooks/useFirestore"
import { timestamp } from "../../firebase/config"
import { useHistory } from "react-router-dom"
import Select from "react-select"
import { stagesData } from "../shipments-dashboard/StagesData"
import { useState, useEffect } from "react"
import { Clearance } from "../../components/tools/Clearance"
//styles
import { colorStyles } from "../../components/selectStyles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Grid, Box } from "@mui/material"

const stages = stagesData

const options = [
  { value: "no", label: "No", color: "#C70039" },
  { value: "pendiente", label: "Pendiente", color: "#FD8D14" },
  { value: "si", label: "Si", color: "#17594A" },
]
function formatDate(date) {
  let d = date.toDate()
  var year = d.toLocaleString("default", { year: "numeric" })
  var month = d.toLocaleString("default", { month: "2-digit" })
  var day = d.toLocaleString("default", { day: "2-digit" })
  var fecha = ""

  fecha = year + "-" + month + "-" + day

  return fecha.toString()
}

export default function ShipmentsSummary({ shipments, userData }) {
  //oportunidad de actualizar estado en lugar de borrar el proyecto
  const { deleteDocument } = useFirestore("shipments")
  const history = useHistory()
  const { updateDocument, response } = useFirestore("shipments")
  const [stage, setStage] = useState(shipments.category)

  const [lastM, setLastM] = useState(shipments.lastMod)
  const [telex, setTelex] = useState(shipments.telex)
  const [docs, setDocs] = useState(shipments.docs)
  const [impuestos, setImpuestos] = useState(shipments.impuestos)
  const [cartas, setCartas] = useState(shipments.cartas)
  const [pedimento, setPedimento] = useState(shipments.pedimento)
  const [gondola, setGondola] = useState(shipments.gondola)
  const [fueraTerminal, setFueraTerminal] = useState(shipments.fueraTerminal)
  const [almacenajes, setAlmacenajes] = useState(
    shipments.almacenajes ? formatDate(shipments.almacenajes) : null
  )
  const [demoras, setDemoras] = useState(
    shipments.demoras ? formatDate(shipments.demoras) : null
  )

  const clearance = Clearance(2, 2)
  const masterClearance = Clearance(1,1)

  const handleTelexChange = (e) => {
    setTelex(e)
    setLastM(timestamp.fromDate(new Date()))
  }
  const handleDocsChange = (e) => {
    setDocs(e)
    setLastM(timestamp.fromDate(new Date()))
  }
  const handleImpuestosChange = (e) => {
    setImpuestos(e)
    setLastM(timestamp.fromDate(new Date()))
  }
  const handleCartasChange = (e) => {
    setCartas(e)
    setLastM(timestamp.fromDate(new Date()))
  }
  const handlePedimentoChange = (e) => {
    setPedimento(e)
    setLastM(timestamp.fromDate(new Date()))
  }
  const handleGondolaChange = (e) => {
    setGondola(e)
    setLastM(timestamp.fromDate(new Date()))
  }
  const handleFueraTerminalChange = (e) => {
    setFueraTerminal(e)
    setLastM(timestamp.fromDate(new Date()))
  }
  const handleAlmacenajesChange = (e) => {
    setAlmacenajes(e)
    setLastM(timestamp.fromDate(new Date()))
  }
  const handleDemorasChange = (e) => {
    setDemoras(e)
    setLastM(timestamp.fromDate(new Date()))
  }

  const handleChange = (option) => {
    setStage(option)
    setLastM(timestamp.fromDate(new Date()))
  }
  useEffect(() => {
    console.log("Previo a enviar: ", {
      lastM,
      stage,
      telex,
      docs,
      impuestos,
      cartas,
      pedimento,
      gondola,
      fueraTerminal,
      almacenajes,
      demoras,
    })
    handleUpdate({
      lastMod: lastM || "",
      category: stage ? stage : "",
      telex: telex ? telex : "",
      docs: docs ? docs : "",
      impuestos: impuestos ? impuestos : "",
      cartas: cartas ? cartas : "",
      pedimento: pedimento ? pedimento : "",
      gondola: gondola ? gondola : "",
      fueraTerminal: fueraTerminal ? fueraTerminal : "",
      almacenajes: almacenajes
        ? timestamp.fromDate(new Date(almacenajes))
        : null,
      demoras: demoras ? timestamp.fromDate(new Date(demoras)) : null,
    })
  }, [
    lastM,
    stage,
    telex,
    docs,
    almacenajes,
    demoras,
    impuestos,
    cartas,
    pedimento,
    gondola,
    fueraTerminal,
  ])

  const handleUpdate = async (updatedData) => {
    console.log("upodated Data: ", updatedData)
    await updateDocument(shipments.id, updatedData)
    console.log("db response: ", response)
    if (!response.error) {
      // Handle successful update if needed
    }
  }

  const handleDelete = () => {
    deleteDocument(shipments.id)
    history.push("/shipments-dashboard")
  }
  return (
    <div>
      <div className="project-summary">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <h1>{shipments.name ? shipments.name : "No especificado"}</h1>
            <p>
              Última Modificación:{" "}
              {shipments.lastMod
                ? shipments.lastMod.toDate().toLocaleDateString("es-mx")
                : ""}
            </p>
          </Grid>
          <Grid item xs={12} md={12}>
            <h2>
              ETA:{" "}
              {shipments.dueDate
                ? shipments.dueDate.toDate().toLocaleDateString("es-mx")
                : "No especificado"}
            </h2>
          </Grid>
          <Grid item xs={12} md={6}>
            <label>
              <span>Etapa:</span>
              {clearance && (
                <Select
                  onChange={(option) => handleChange(option)}
                  options={stages}
                  value={stage}
                  styles={colorStyles}
                />
              )}
              {!clearance && (
                <p style={{ color: stage.color }}>{stage.label}</p>
              )}
            </label>
          </Grid>
          <Grid item xs={12} md={12}>
            <p>
              Proveedor:{" "}
              {shipments.supplier
                ? shipments.supplier.label
                : "No especificado"}
            </p>
          </Grid>
          <Grid item xs={12} md={12}>
            <p>
              Tipo de contenedor:{" "}
              {shipments.container
                ? shipments.container.label
                : "No especificado"}
            </p>
          </Grid>
          <Grid item xs={12} md={12}>
            <p>
              Puerto de Salida:{" "}
              {shipments.outboundPort
                ? shipments.outboundPort.label
                : "No especificado"}
            </p>
          </Grid>
          <Grid item xs={12} md={12}>
            <p>
              ETD:{" "}
              {shipments.etd
                ? shipments.etd.toDate().toLocaleDateString("es-mx")
                : "No especificado"}
            </p>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 5, marginBottom: 5 }}>
          <h4>Packing List</h4>
          <TableContainer component={Paper} sx={{ marginTop: 5 }}>
            <Table sx={{ minWidth: 650 }} aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Producto</TableCell>
                  <TableCell>Cantidad</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {shipments.productList.map((ship) => (
                  <TableRow
                    key={ship.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {ship.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {ship.quantity}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <p>
              Puerto de Llegada:{" "}
              {shipments.inboundPort
                ? shipments.inboundPort.label
                : "No especificado"}
            </p>
          </Grid>
          <Grid item xs={12} md={12}>
            <p>
              Transporte Terrestre:{" "}
              {shipments.modality
                ? shipments.modality.label
                : "No especificado"}
            </p>
          </Grid>
          <Grid item xs={12} md={12}>
            <p>Información extra:</p>
            <p className="details">
              {shipments.details ? shipments.details : ""}
            </p>
          </Grid>
          {clearance && (
            <>
              <Grid item xs={12} md={4}>
                <label>
                  Almacenajes:
                  <input
                    type="date"
                    value={almacenajes}
                    onChange={(e) => handleAlmacenajesChange(e.target.value)}
                  />
                </label>
              </Grid>
              <Grid item xs={12} md={4}>
                <label>
                  Demoras:
                  <input
                    type="date"
                    value={demoras}
                    onChange={(e) => handleDemorasChange(e.target.value)}
                  />
                </label>
              </Grid>
              <Grid item xs={12} md={8}>
                <label>
                  Telex Enviado:
                  <Select
                    options={options}
                    styles={colorStyles}
                    onChange={handleTelexChange}
                    value={telex}
                  />
                </label>
              </Grid>
              <Grid item xs={12} md={8}>
                <label>
                  Documentación Enviada:
                  <Select
                    options={options}
                    styles={colorStyles}
                    onChange={handleDocsChange}
                    value={docs}
                  />
                </label>
              </Grid>
              <Grid item xs={12} md={8}>
                <label>
                  Solicitud de Impuestos Enviados:
                  <Select
                    options={options}
                    styles={colorStyles}
                    onChange={handleImpuestosChange}
                    value={impuestos}
                  />
                </label>
              </Grid>
              <Grid item xs={12} md={8}>
                <label>
                  Cartas Enviados:
                  <Select
                    options={options}
                    styles={colorStyles}
                    onChange={handleCartasChange}
                    value={cartas}
                  />
                </label>
              </Grid>
              <Grid item xs={12} md={8}>
                <label>
                  Pedimento Emitido:
                  <Select
                    options={options}
                    styles={colorStyles}
                    onChange={handlePedimentoChange}
                    value={pedimento}
                  />
                </label>
              </Grid>
              <Grid item xs={12} md={8}>
                <label>
                  Cargado a Góndola:
                  <Select
                    options={options}
                    styles={colorStyles}
                    onChange={handleGondolaChange}
                    value={gondola}
                  />
                </label>
              </Grid>
              <Grid item xs={12} md={8}>
                <label>
                  Fuera de Terminal:
                  <Select
                    options={options}
                    styles={colorStyles}
                    onChange={handleFueraTerminalChange}
                    value={fueraTerminal}
                  />
                </label>
              </Grid>
            </>
          )}

          <Grid item xs={12} md={12}>
            <h4>Importación asignada a:</h4>
            <div className="assigned-users">
              {shipments.assignedUsersList.map((user) => (
                <div key={user.id}>
                  <Avatar src={user.photoURL} />
                </div>
              ))}
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            {userData.id === shipments.createdBy.id && clearance && (
              <button className="btn" onClick={handleDelete}>
                Borrar Documento
              </button>
            )}
            {masterClearance && (
              <button className="btn" onClick={handleDelete}>
                Borrar Documento
              </button>
            )}

          </Grid>
        </Grid>
      </div>
    </div>
  )
}
