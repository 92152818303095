import { useState } from "react"
import Avatar from "../../components/Avatar"
import { timestamp } from "../../firebase/config"
import { useFirestore } from "../../hooks/useFirestore"
import formatDistanceToNow from "date-fns/formatDistanceToNow"
import { Clearance } from "../../components/tools/Clearance"

export default function ProductComments({ product, userData }) {
  const { updateDocument, response } = useFirestore("products")
  const [newComment, setNewComment] = useState("")

  const clearance = Clearance(2, 2)

  const handleSubmit = async (e) => {
    e.preventDefault()

    const commentToAdd = {
      displayName: userData.displayName,
      photoURL: userData.photoURL,
      content: newComment,
      createdAt: timestamp.fromDate(new Date()),
      id: Math.random(),
    }

    await updateDocument(product.id, {
      comments: [...product.comments, commentToAdd],
    })
    if (!response.error) {
      setNewComment("")
    }
  }

  return (
    <div className="product-comments">
      <h4>Product Comments</h4>
      <ul>
        {product.comments.length > 0 &&
          product.comments.map((comment) => (
            <li key={comment.id}>
              <div className="comment-author">
                <Avatar src={comment.photoURL} />
                <p>{comment.displayName}</p>
              </div>
              <div className="comment-date">
                <p>
                  {formatDistanceToNow(comment.createdAt.toDate(), {
                    addSuffix: true,
                  })}
                </p>
              </div>
              <div className="comment-content">
                <p>{comment.content}</p>
              </div>
            </li>
          ))}
      </ul>

      {clearance && (
        <form className="add-comment" onSubmit={handleSubmit}>
          <label>
            <span>Add new comment:</span>
            <textarea
              onChange={(e) => setNewComment(e.target.value)}
              value={newComment}
            ></textarea>
          </label>
          <button className="btn">Add Comment</button>
        </form>
      )}
    </div>
  )
}
