import React, { useEffect, useState } from "react"
import { Box, Button, Divider, MenuItem, TextField } from "@mui/material"
import { ANCHOS, HW2X20, HW3X30, HW4X40 } from "./CH_Logic"

const CH_Form = () => {
  const [materials, setMaterials] = useState([])
  const [pvc, setPvc] = useState(null)
  const [anchoMaterial, setAnchoMaterial] = useState(null)
  const [ancho, setAncho] = useState(null)
  const [largo, setLargo] = useState(null)

  const handleAnchoMaterialChange = (event) => {
    const selectedOption = ANCHOS.find(
      (option) => option.value === event.target.value
    )
    setAnchoMaterial(selectedOption)
  }

  const handlePVCChange = (event) => {
    const selectedOption = materials.find(
      (option) => option.value === event.target.value
    )
    setPvc(selectedOption)
  }

  const handleAnchoChange = (e) => {
    setAncho(e.target.value)
  }

  const handleLargoChange = (e) => {
    setLargo(e.target.value)
  }

  useEffect(() => {
    if (anchoMaterial) {
      switch (anchoMaterial.value) {
        case "2x20":
          setMaterials(HW2X20)
          break
        case "3x30":
          setMaterials(HW3X30)
          break
        case "4x40":
          setMaterials(HW4X40)
          break
        default:
          setMaterials([])
          break
      }
    } else {
      setMaterials([])
    }
  }, [anchoMaterial])

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
        marginTop: 5,
        marginBottom: 5,
      }}
      noValidate
      autoComplete="off"
    >
      <h3>Cortinas Hawaianas Armadas</h3>
      <TextField
        id="select-ancho"
        select
        value={anchoMaterial ? anchoMaterial.value : ""}
        helperText="Selecciona el ancho de la tira"
        onChange={handleAnchoMaterialChange}
      >
        {ANCHOS.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id="select-material"
        select
        value={pvc ? pvc.value : ""}
        helperText="Selecciona el material"
        onChange={handlePVCChange}
        disabled={!anchoMaterial} // Disable the Material selection if ancho is not set
      >
        {materials.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id="Ancho"
        value={ancho}
        onChange={handleAnchoChange}
        helperText="Ancho de la cortina en metros"
        disabled={!pvc}
      ></TextField>
      <TextField
        id="Largo"
        value={largo}
        onChange={handleLargoChange}
        helperText="Largo de la cortina en metros"
        disabled={!ancho}
      ></TextField>
      <Button variant="contained" >Calcular</Button>
    </Box>
  )
}

export default CH_Form
