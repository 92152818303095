import { React, forwardRef, useState } from "react"
import { NavLink as NavLinkBase } from "react-router-dom"
import { Clearance } from "../tools/Clearance"
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"

//MUI ICONS
import { ExpandLess } from "@material-ui/icons"
import { ExpandMore } from "@material-ui/icons"
import AddIcon from "@mui/icons-material/Add"
import SearchIcon from "@mui/icons-material/Search"
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined"
const PDQuotesModule = ({ handleDrawerClose }) => {
  const NavLink = forwardRef((props, ref) => (
    <NavLinkBase ref={ref} {...props} className={props.activeClassName} />
  ))
  const [open, setOpen] = useState(false)
  const clearance = Clearance(3,3)

  const handleOpen = () => {
    setOpen(!open)
  }
  return (
    <ListItem
      key={"cotizaciones"}
      disablePadding
      sx={{ display: "flex", flexDirection: "column", width: "100%" }}
    >
      <ListItemButton onClick={handleOpen} sx={{ width: "100%" }}>
        <ListItemIcon>
          <ArticleOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Cotizaciones" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">
          <ListItem
            key={"ver-cotizaciones"}
            component={NavLink}
            to="/cotizaciones-dashboard"
            onClick={() => {
              handleDrawerClose()
              handleOpen()
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText secondary="Ver Cotizaciones" />
            </ListItemButton>
          </ListItem>
          {clearance && (
            <ListItem
              key={"crear-cotizaciones"}
              component={NavLink}
              to="/create-quote"
              onClick={() => {
                handleDrawerClose()
                handleOpen()
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AddIcon />
                </ListItemIcon>
                <ListItemText secondary="Crear Cotización" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Collapse>
    </ListItem>
  )
}

export default PDQuotesModule
