import { useEffect, useState } from "react"
import { Grid } from "@mui/material"
import { useUserRoleAndSecurity } from "../../hooks/useUserRoleAndSecurity"

import Select from "react-select"

//styles
import "./ProductFilter.css"
import ChangeView from "../../components/ChangeView"

export default function ProductFilter({ changeFilter, changeView }) {
  const [currentView, setCurrentView] = useState("list")
  const [selectValue, setSelectValue] = useState("")
  const [clear, setClear] = useState(false)
  const [categories, setCategories] = useState(null)

  const { productLines } = useUserRoleAndSecurity()


  useEffect(() => {
    if (productLines) {
      const lineCollection = productLines.map((line) => {
        return { value: line.value, label: line.label }
      })

      // Add the hardcoded item at the beginning
      lineCollection.unshift({ value: "todos", label: "Todos" })

      setCategories(lineCollection)
    } else {
      console.log("No product lines available: ")
    }
  }, [productLines])

  const handleClick = (newFilter) => {
    //clear select component
    if (clear) {
      setSelectValue(null)
    } else {
      setSelectValue(newFilter.value)
    }
    changeFilter(newFilter)
    setClear(false)
  }
  const handleViewChange = (view) => {
    setCurrentView(view)
    changeView(view)
  }

  return (
    <div className="product-filter">
      <nav>
        <Grid
          container
          spacing={2}
          sx={{
            background: "#f7f7f7",
            padding: "16px",
            paddingTop: "0px",
          }}
        >
          <ChangeView
            handleViewChange={handleViewChange}
            currentView={currentView}
          />

          <Grid item md={4} />
          <Grid item md={3} xs={12}>
            <Select
              onChange={(option) => {
                handleClick(option.value)
                setSelectValue(option)
              }}
              options={categories}
              value={selectValue}
              placeholder="Filtrar por linea"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <input
              type="text"
              onChange={(e) => {
                handleClick(e.target.value)
                setClear(true)
              }}
              placeholder="Búsqueda por nombre"
            ></input>
          </Grid>
        </Grid>
      </nav>
    </div>
  )
}
