import { useState, useEffect } from "react"

//hooks
import { useCollection } from "../../hooks/useCollection"
import { useUserRoleAndSecurity } from "../../hooks/useUserRoleAndSecurity"
import { useFirestore } from "../../hooks/useFirestore"

//components
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import SuccessModal from "../../components/SuccessModal"
import Error404 from "../error404/Error404"
import { Clearance } from "../../components/tools/Clearance"

//MUI
import { Box } from "@mui/material"
import Switch from "@mui/material/Switch"

// styles
import "./NewProduct.css"

export default function NewProduct({userData}) {
  const { addDocument, response } = useFirestore("products")
  const { productLines } = useUserRoleAndSecurity()
  const { addTag } = useFirestore("tags")

  //tags for products
  const { documents: tag_documents } = useCollection("tags")
  const [tags, setTags] = useState([])

  // form field values
  const [name, setName] = useState("")
  const [productCode, setProductCode] = useState("")
  const [productColor, setProductColor] = useState("")
  const [ancho, setAncho] = useState("")
  const [largo, setLargo] = useState("")
  const [peso, setPeso] = useState("")

  const [checkedMetros, setCheckedMetros] = useState(true)

  //venta por metro
  const [largoRollo, setLargoRollo] = useState("")
  const [precioPublicoMetro, setPrecioPublicoMetro] = useState("")
  const [precioPublicoRollo, setPrecioPublicoRollo] = useState("")
  const [precioFilialesMetro, setPrecioFilialesMetro] = useState("")
  const [precioFilialesRollo, setPrecioFilialesRollo] = useState("")
  const [precioMLMetro, setPrecioMLMetro] = useState("")
  const [precioMLRollo, setPrecioMLRollo] = useState("")
  const [checkedPulgadas, setCheckedPulgadas] = useState(true)
  const [espesorMM, setEspesorMM] = useState("")
  const [espesorIn, setEspesorIn] = useState("")

  //venta a hulempak
  const [checkedHPK, setCheckedHPK] = useState(true)
  const [precioPzHPK, setPrecioPzHPK] = useState("")
  const [precioHPKMetro, setPrecioHPKMetro] = useState("")
  const [precioHPKRollo, setPrecioHPKRollo] = useState("")

  const [precioPz, setPrecioPz] = useState("")
  const [precioPzFiliales, setPrecioPzFiliales] = useState("")
  const [precioPzML, setPrecioPzML] = useState("")
  const [details, setDetails] = useState("")
  const [category, setCategory] = useState("")
  const [assignedTags, setAssignedTags] = useState([])
  const [formError, setFormError] = useState(null)

  //creatable tags states
  const [isLoading, setIsLoading] = useState(false)
  const [tagOptions, setTagOptions] = useState(tags)
  const [categories, setCategories] = useState(null)

  //modal
  const [showModal, setShowModal] = useState(false)

  const clearance = Clearance(3, 3)

  //variables
  let product = {}

  useEffect(() => {
    if (productLines) {
      const lineCollection = productLines.map((line) => {
        return { value: line.value, label: line.label }
      })
      setCategories(lineCollection)
    } else {
      console.log("No product lines available: ")
    }
  }, [productLines])

  //leer los usuarios la primera vez que se ejecuta el componente o si cambia la lista
  useEffect(() => {
    //leer los tags la primera vez que se ejecuta el componente o si cambia la lista
    if (tag_documents) {
      const tag_options = tag_documents.map((tag) => {
        return { value: tag, label: tag.tag }
      })
      setTags(tag_options)
      setTagOptions(tag_options)
    }
  }, [tag_documents])

  //creating new tag
  const handleCreate = async (inputValue) => {
    setIsLoading(true)
    let newTag = {
      tag: inputValue,
    }
    const newOption = { value: inputValue, label: inputValue, tag: inputValue }
    let nt = await addTag(newTag)
    let id = nt.id
    const createdOption = { id, ...newOption }
    setAssignedTags([...assignedTags, createdOption])

    console.log("id:", id)
    console.log("new tag response:", nt)
    setIsLoading(false)
  }

  const handleToggleMetros = () => {
    setCheckedMetros(!checkedMetros)
    console.log(checkedMetros)
  }
  const handleTogglePulgadas = () => {
    setCheckedPulgadas(!checkedPulgadas)
    console.log(checkedPulgadas)
  }
  const handleToggleHPK = () => {
    setCheckedHPK(!checkedHPK)
    console.log(checkedHPK)
  }

  const handleClose = () => {
    setShowModal(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    //avoid error for Select components
    setFormError(null)

    if (!category) {
      setFormError("Please select a project category")
      return
    }

    //empty array is a value
    if (assignedTags.length < 1) {
      setFormError("Please assign the product at least one tag")
      return
    }
    //creator of the project
    const createdBy = {
      displayName: userData.displayName,
      photoURL: userData.photoURL,
      id: userData.id,
    }

    // Transforming tag(s) assigned to the project to something less complex
    const assignedTagsList = assignedTags.map((t) => {
      if (t.value.tag === undefined) {
        //newly created tag
        return {
          tag: t.value,
          id: t.id,
        }
      } else {
        //already in db
        return {
          tag: t.value.tag,
          id: t.value.id,
        }
      }
    })

    //object to send to db
    product = {
      name,
      productCode,
      category: category.value,
      assignedTagsList,
      productColor,
      ancho,
      largo,
      peso,
      largoRollo,
      espesorMM,
      espesorIn,
      precioPz,
      precioPzFiliales,
      precioPzML,
      precioPublicoMetro,
      precioPublicoRollo,
      precioFilialesMetro,
      precioFilialesRollo,
      precioPzHPK,
      precioHPKMetro,
      precioHPKRollo,
      precioMLMetro,
      precioMLRollo,
      details,
      comments: [],
      createdBy,
    }

    console.log(product)
    const answer = await addDocument(product)
    console.log("Answer: ", answer)

    if (!response.error) {
      setShowModal(true)
    }
  }

  return (
    <>
      {!clearance && <Error404 />}
      {clearance && (
        <Box sx={{ marginTop: 20 }}>
          <div className="create-form-product">
            <h2 className="page-title">Crear Nuevo Producto</h2>
            <form onSubmit={handleSubmit}>
              <label>
                <span>Nombre del Producto:</span>
                <input
                  required
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </label>
              <label>
                <span>Codigo de Producto:</span>
                <input
                  required
                  type="text"
                  onChange={(e) => setProductCode(e.target.value)}
                  value={productCode}
                />
              </label>
              <label>
                <span>Categoria del producto:</span>
                <Select
                  onChange={(option) => setCategory(option)}
                  options={categories}
                />
              </label>
              <label>
                <span>Tags:</span>
                <CreatableSelect
                  onChange={(option) => {
                    setAssignedTags(option)
                  }}
                  options={tagOptions}
                  isMulti
                  onCreateOption={handleCreate}
                  value={assignedTags}
                />
              </label>
              <label>
                <span>Color:</span>
                <input
                  required
                  type="text"
                  onChange={(e) => setProductColor(e.target.value)}
                  value={productColor}
                />
              </label>

              <label>
                <span>Ancho por unidad (m):</span>
                <input
                  required
                  type="number"
                  onChange={(e) => setAncho(e.target.value)}
                  value={ancho}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </label>
              <label>
                <span>Largo por unidad (m):</span>
                <input
                  required
                  type="number"
                  onChange={(e) => setLargo(e.target.value)}
                  value={largo}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </label>
              <label>
                <span>Peso (0 default):</span>
                <input
                  required
                  type="number"
                  onChange={(e) => setPeso(e.target.value)}
                  value={peso}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </label>
              {/* venta por metro */}
              <label>
                <span>Venta por metro?</span>
                <Switch
                  color="secondary"
                  onChange={handleToggleMetros}
                  defaultChecked
                />
              </label>
              {checkedMetros && (
                <label>
                  <span>Largo por rollo (m):</span>
                  <input
                    required
                    type="number"
                    onChange={(e) => setLargoRollo(e.target.value)}
                    value={largoRollo}
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                </label>
              )}
              <label>
                <span>Espesor (mm):</span>
                <input
                  required
                  type="number"
                  onChange={(e) => setEspesorMM(e.target.value)}
                  value={espesorMM}
                  onWheel={(event) => event.currentTarget.blur()}
                />
              </label>
              <label>
                <span>Espesor en pulgadas?</span>
                <Switch
                  color="secondary"
                  onChange={handleTogglePulgadas}
                  defaultChecked
                />
              </label>
              {checkedPulgadas && (
                <label>
                  <span>Espesor (in):</span>
                  <input
                    required
                    type="text"
                    onChange={(e) => setEspesorIn(e.target.value)}
                    value={espesorIn}
                  />
                </label>
              )}
              <h3>Precio al publico</h3>

              {!checkedMetros && (
                <label>
                  <span>Precio por pieza al publico:</span>
                  <input
                    required
                    type="number"
                    onChange={(e) => setPrecioPz(e.target.value)}
                    value={precioPz}
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                </label>
              )}
              {checkedMetros && (
                <>
                  <label>
                    <span>
                      Precio por metro al publico por metro individual:
                    </span>
                    <input
                      required
                      type="number"
                      onChange={(e) => setPrecioPublicoMetro(e.target.value)}
                      value={precioPublicoMetro}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </label>
                  <label>
                    <span>Precio por metro al publico por rollo completo:</span>
                    <input
                      required
                      type="number"
                      onChange={(e) => setPrecioPublicoRollo(e.target.value)}
                      value={precioPublicoRollo}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </label>
                </>
              )}
              <h3>Precio a Filiales</h3>
              <label>
                <span>Precio especial a HPK?</span>
                <Switch
                  color="secondary"
                  onChange={handleToggleHPK}
                  defaultChecked
                />
              </label>
              {!checkedMetros && checkedHPK && (
                <label>
                  <span>Precio por pieza a Hulempak:</span>
                  <input
                    required
                    type="number"
                    onChange={(e) => setPrecioPzHPK(e.target.value)}
                    value={precioPzHPK}
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                </label>
              )}
              {checkedMetros && checkedHPK && (
                <>
                  <label>
                    <span>
                      Precio por metro a Hulempak por metro individual:
                    </span>
                    <input
                      required
                      type="number"
                      onChange={(e) => setPrecioHPKMetro(e.target.value)}
                      value={precioHPKMetro}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </label>
                  <label>
                    <span>Precio por metro a Hulempak por rollo completo:</span>
                    <input
                      required
                      type="number"
                      onChange={(e) => setPrecioHPKRollo(e.target.value)}
                      value={precioHPKRollo}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </label>
                </>
              )}

              {!checkedMetros && (
                <label>
                  <span>Precio por pieza a filiales:</span>
                  <input
                    required
                    type="number"
                    onChange={(e) => setPrecioPzFiliales(e.target.value)}
                    value={precioPzFiliales}
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                </label>
              )}
              {checkedMetros && (
                <>
                  <label>
                    <span>
                      Precio por metro a Filiales por metro individual:
                    </span>
                    <input
                      required
                      type="number"
                      onChange={(e) => setPrecioFilialesMetro(e.target.value)}
                      value={precioFilialesMetro}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </label>
                  <label>
                    <span>Precio por metro a Filiales por rollo completo:</span>
                    <input
                      required
                      type="number"
                      onChange={(e) => setPrecioFilialesRollo(e.target.value)}
                      value={precioFilialesRollo}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </label>
                </>
              )}
              <h3>Precio a Mercado Libre</h3>
              {!checkedMetros && (
                <label>
                  <span>Precio por pieza a ML:</span>
                  <input
                    required
                    type="number"
                    onChange={(e) => setPrecioPzML(e.target.value)}
                    value={precioPzML}
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                </label>
              )}
              {checkedMetros && (
                <>
                  <label>
                    <span>Precio por metro a ML por metro individual:</span>
                    <input
                      required
                      type="number"
                      onChange={(e) => setPrecioMLMetro(e.target.value)}
                      value={precioMLMetro}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </label>
                  <label>
                    <span>Precio por metro a ML por rollo completo:</span>
                    <input
                      required
                      type="number"
                      onChange={(e) => setPrecioMLRollo(e.target.value)}
                      value={precioMLRollo}
                      onWheel={(event) => event.currentTarget.blur()}
                    />
                  </label>
                </>
              )}
              <label>
                <span>Notas del producto:</span>
                <textarea
                  type="text"
                  onChange={(e) => setDetails(e.target.value)}
                  value={details}
                />
              </label>

              {isLoading && (
                <button className="btn" disabled>
                  Espera...
                </button>
              )}
              {!isLoading && <button className="btn">Add Product</button>}
              {showModal && (
                <SuccessModal
                  title="Producto Agregado!"
                  message="Revisa que se haya subido con éxito en la pantalla de Productos"
                  onClose={handleClose}
                  open={showModal}
                />
              )}
              {formError && <p className="error">{formError}</p>}
            </form>
          </div>
        </Box>
      )}
    </>
  )
}
