import { useState } from "react"
import { useSignup } from "../../hooks/useSignup"

import { Box } from "@mui/material"

// styles
import "./Signup.css"

export default function Signup() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [thumbnail, setThumbnail] = useState(null)
  const [thumbnailError, setThumbnailError] = useState(null)
  const { signup, isPending, error } = useSignup()

  const handleSubmit = (e) => {
    e.preventDefault()
    //order of args must match hook order of inputs
    signup(email, password, displayName, thumbnail)
  }

  const handleFileChange = (e) => {
    setThumbnail(null)
    let selected = e.target.files[0]

    if (!selected) {
      setThumbnailError("Please select a file")
      return
    }

    if (!selected.type.includes("image")) {
      setThumbnailError("Selected file must be an image")
      return
    }

    if (selected.size > 600000) {
      setThumbnailError("Image file size must be less than 600kb")
      return
    }

    setThumbnailError(null)
    setThumbnail(selected)
  }
  return (
    <Box sx={{ marginTop: 20 }}>
      <form onSubmit={handleSubmit} className="auth-form">
        <h2>sign up</h2>
        <label>
          <span>email:</span>
          <input
            required
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </label>
        <label>
          <span>password:</span>
          <input
            required
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </label>
        <label>
          <span>display name:</span>
          <input
            required
            type="text"
            onChange={(e) => setDisplayName(e.target.value)}
            value={displayName}
          />
        </label>
        <label>
          <span>Profile thumbnail:</span>
          <input required type="file" onChange={handleFileChange} />
          {thumbnailError && <div className="error">{thumbnailError}</div>}
        </label>

        {!isPending && <button className="btn">Sign up</button>}
        {isPending && (
          <button disabled className="btn">
            Loading...
          </button>
        )}
        {error && <div className="error">{error}</div>}
      </form>
    </Box>
  )
}
