import React from "react"
import { Grid, Stack } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Link } from "react-router-dom"

export default function ObjectList({ type, objects, sortvar }) {
  const sorting = [...objects].sort((a, b) =>
    a[sortvar].toLowerCase() < b[sortvar].toLowerCase() ? -1 : 1
  )
  const buttonStyle = {
    color: "#fff", // set the text color to red
    backgroundColor: "#ff006e", // set the background color
  }
  return (
    <Grid container spacing={3}>
      {objects.length === 0 && <p>No {type} yet</p>}
      {sorting.map((obj) => (
        <Grid item xs={12} md={4} key={obj.id}>
          <Card variant="outlined" sx={{ minWidth: 275, minHeight: 150 }}>
            <CardContent>
              {obj.category && type !== "shipments" && (
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {obj.category}
                  {type === "shipments" && obj.category.label}
                </Typography>
              )}
              {obj.category && type === "shipments" && (
                <Typography
                  sx={{ fontSize: 16, color: obj.category.color }}
                  color="text.secondary"
                  gutterBottom
                >
                  {obj.category.label}
                </Typography>
              )}
              {obj.stage && (
                <Typography
                  variant="h6"
                  sx={{ fontSize: 16, color: obj.stage.color }}
                  component="div"
                >
                  {obj.stage.label}
                </Typography>
              )}
              {obj.name && (
                <Typography variant="h5" sx={{ fontSize: 18 }} component="div">
                  {obj.name}
                </Typography>
              )}
              {obj.lastMod && type === "shipments" && (
                <Typography
                  sx={{ fontSize: 16 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Última modificación: {obj.lastMod.toDate().toLocaleDateString("es-mx")}
                </Typography>
              )}
              {obj.dueDate && (
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {type === "shipments" && "ETA: "}
                  {type !== "shipments" && "Due date: "}
                  {obj.dueDate.toDate().toDateString()}
                </Typography>
              )}
              {obj.assignedUsersList && (
                <Stack direction="row" spacing={1}>
                  {obj.assignedUsersList.map((user) => (
                    <Avatar key={user.id} src={user.photoURL} />
                  ))}
                </Stack>
              )}
            </CardContent>
            <CardActions>
              <Link to={`/${type}/${obj.id}`} key={obj.id}>
                <Button size="small" style={buttonStyle}>
                  Ver Más
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}
